import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { KeyboardView, Wrapper, Row, Copy } from "app/NativeComponents/common";
import {
  InputBox,
  SearchBar,
  AttentionBox, InlineButton
} from "app/NativeComponents/snippets";
import {
  changeTab,
  tabNavigation,
  addListToListTabs,
  editBulkUploadInfo,
  setUploadListHeaders,
  popAllSidePanels,
  popSidePanel,
  addProgressBar
} from "app/NativeActions";

import MappingObject from "./MappingObject";
import MappingRequirements from "./MappingRequirements";

const IGNORE_VALUE = "IGNORE";

class UploadMapping extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getMappingState();
  }

  getMappingState() {
    let header_edited_map = [];
    let formatted_custom_fields = [];
    if (this.props.upload_list_info) {
      if (this.props.upload_list_info.header) {
        for (let i = 0; i < this.props.upload_list_info.header.length; i++) {
          header_edited_map.push({
            header_title: this.props.upload_list_info.header[i].name,
            label: this.props.upload_list_info.header[i].name,
            header_samples: this.props.upload_list_info.header[i].samples,
            header_index: i,
            header_mapped_value:
              this.props.upload_list_info.header[i].header_mapped_value || "",
            value: ""
          });
        }
      }
      if (this.props.upload_list_info?.custom_fields) {
        for (
          let i = 0;
          i < this.props.upload_list_info.custom_fields.length;
          i++
        ) {
          formatted_custom_fields.push({
            label: this.props.upload_list_info.custom_fields[i].title,
            value: this.props.upload_list_info.custom_fields[i].slug
          });
        }
      }
    }

    return {
      new_list_name: !!this.props.upload_list_info?.list_title
        ? this.props.upload_list_info?.list_title
        : "Imported List",
      search: "",
      loading: false,
      header_edited_map: header_edited_map,
      fields_are_selected: {
        full_address_check: false,
        lat_lng_check: false,
        property_id_check: false,
        apn_check: false
      },
      header_options: [
        {
          label: "N/A",
          value: ""
        },
        {
          label: "Skip Column",
          value: IGNORE_VALUE
        },
        {
          label: "Full Address",
          value: "FULL_ADDRESS"
        },
        {
          label: "Address Line 1",
          value: "ADDRESS"
        },
        {
          label: "Address Line 2",
          value: "ADDRESS_2"
        },
        {
          label: "Address Number",
          value: "ADDRESS_RANGE"
        },
        {
          label: "Address Street",
          value: "ADDRESS_STREET"
        },
        {
          label: "City",
          value: "CITY"
        },
        {
          label: "State",
          value: "STATE"
        },
        {
          label: "Zipcode",
          value: "ZIP"
        },
        { label: "County", value: "COUNTY" },
        {
          label: "Parcel Number",
          value: "PARCEL_NUMBER"
        },
        { label: "Property ID", value: "PROPERTY_ID" },
        {
          label: "Latitude",
          value: "LATITUDE"
        },
        {
          label: "Longitude",
          value: "LONGITUDE"
        },
        ...formatted_custom_fields
      ]
    };
  }

  componentDidMount() {
    this.updateFieldSelectionStatus({ onMount: true });
  }

  updateFieldSelectionStatus(options = {}) {
    const fields_are_selected = {
      full_address_check: this.checkIfAllPropertyAddressFieldsAreSelected(),
      apn_check: this.checkIfAllPropertyParcelFieldsAreSelected(),
      property_id_check: this.checkIfPropertyIdFieldIsSelected(),
      lat_lng_check: this.checkIfAllLatLngFieldsAreSelected()
    };
    let ai_mapping_success = false;
    if (options?.onMount) {
      ai_mapping_success = Object.values(fields_are_selected).some(
        x => x == true
      );
    }
    this.setState({
      fields_are_selected,
      ai_mapping_success
    });
  }

  checkIfAllPropertyAddressFieldsAreSelected() {
    let address_check = false;
    let address_street_check = false;
    let address_range_check = false;

    let city_check = false;
    let state_check = false;
    let zip_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "FULL_ADDRESS":
          return true;

        case "ADDRESS":
          address_check = true;
          break;

        case "ADDRESS_RANGE":
          address_range_check = true;
          break;

        case "ADDRESS_STREET":
          address_street_check = true;
          break;

        case "CITY":
          city_check = true;
          break;

        case "STATE":
          state_check = true;
          break;

        case "ZIP":
          zip_check = true;
          break;

        default:
          break;
      }
    }

    if (address_street_check && address_range_check) {
      address_check = true;
    }

    return address_check && city_check && state_check && zip_check;
  }

  checkIfAllPropertyParcelFieldsAreSelected() {
    let parcel_check = false;
    let county_check = false;
    let state_check = false;
    let zip_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "PARCEL_NUMBER":
          parcel_check = true;
          break;
        case "STATE":
          state_check = true;
          break;
        case "COUNTY":
          county_check = true;
          break;
        case "ZIP":
          zip_check = true;
          break;
        default:
          break;
      }
    }

    // Return true if any of the following conditions are met
    return (
      (parcel_check && county_check && state_check) || // Condition 1
      (parcel_check && zip_check) || // Condition 2
      (parcel_check && county_check && state_check && zip_check) // Condition 3
    );
  }

  checkIfPropertyIdFieldIsSelected() {
    let property_id_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "PROPERTY_ID":
          property_id_check = true;
          break;

        default:
          break;
      }
    }

    return property_id_check;
  }

  checkIfAllLatLngFieldsAreSelected() {
    let lat_check = false;
    let lng_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "LATITUDE":
          lat_check = true;
          break;

        case "LONGITUDE":
          lng_check = true;
          break;

        default:
          break;
      }
    }

    return lat_check && lng_check;
  }


  resetHeaderOptions() {
    this.setState((prevState) => ({
      header_edited_map: prevState.header_edited_map.map((header) => ({
        ...header,
        header_mapped_value: "", // Clear all mapped values in header_edited_map
        value: "", // Clear any additional value field
      })),
    }));
  }


  isMappingCleared() {
    return this.state.header_edited_map.every(
      (header) => !header.header_mapped_value || header.header_mapped_value === "IGNORE" ||header.header_mapped_value === ""
    );
  }

  setHeaderOption(header_index, value) {
    this.setState({
      header_edited_map: this.state.header_edited_map.map((header, i) => {
        if (i === header_index) {
          return {
            ...header,
            value,
            header_mapped_value: value
          };
        }
        if (
          value === header.header_mapped_value &&
          value !== "" &&
          value !== IGNORE_VALUE &&
          (header.header_mapped_value === "FULL_ADDRESS" ||
            header.header_mapped_value === "ADDRESS" ||
            header.header_mapped_value === "ADDRESS_2" ||
            header.header_mapped_value === "ADDRESS_RANGE" ||
            header.header_mapped_value === "ADDRESS_STREET" ||
            header.header_mapped_value === "CITY" ||
            header.header_mapped_value === "STATE" ||
            header.header_mapped_value === "ZIP")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }

        if (
          value === "FULL_ADDRESS" &&
          (header.header_mapped_value === "FULL_ADDRESS" ||
            header.header_mapped_value === "ADDRESS" ||
            header.header_mapped_value === "ADDRESS_2" ||
            header.header_mapped_value === "ADDRESS_RANGE" ||
            header.header_mapped_value === "ADDRESS_STREET" ||
            header.header_mapped_value === "CITY" ||
            header.header_mapped_value === "STATE" ||
            header.header_mapped_value === "ZIP")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }

        if (
          value === "ADDRESS" &&
          (header.header_mapped_value === "ADDRESS_RANGE" ||
            header.header_mapped_value === "ADDRESS_STREET")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }
        if (
          value === "PARCEL_NUMBER" &&
          (header.header_mapped_value === "PARCEL_NUMBER" ||
            header.header_mapped_value === "COUNTY")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }
        if (
          value === "PROPERTY_ID" &&
          header.header_mapped_value === "PROPERTY_ID"
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }
        if (
          header.header_mapped_value === "LONGITUDE" &&
          header.header_mapped_value === "LATITUDE"
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }

        if (
          header.header_mapped_value === "ADDRESS" &&
          (value === "ADDRESS_RANGE" || value === "ADDRESS_STREET")
        ) {
          return {
            ...header,
            header_mapped_value: IGNORE_VALUE
          };
        }

        return header;
      })
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.upload_list_info &&
      this.props.upload_list_info !== prevProps.upload_list_info
    ) {
      this.setState(this.getMappingState());
    }

    if (prevState.header_edited_map !== this.state.header_edited_map) {
      this.updateFieldSelectionStatus();
      if (
        (this.checkIfAllPropertyAddressFieldsAreSelected() ||
          this.checkIfAllPropertyParcelFieldsAreSelected() ||
          this.checkIfPropertyIdFieldIsSelected() ||
          this.checkIfAllLatLngFieldsAreSelected()) &&
        !this.state.address_fields_are_selected
      ) {
        this.setState({
          address_fields_are_selected: true
        });

        this.setState({
          header_edited_map: this.state.header_edited_map.map((header, i) => {
            if (
              header.header_mapped_value === "" ||
              header.header_mapped_value === "N/A" ||
              header.header_mapped_value === null
            ) {
              return {
                ...header,
                header_mapped_value: IGNORE_VALUE
              };
            }
            return header;
          })
        });
      } else if (
        (!this.checkIfAllPropertyAddressFieldsAreSelected() ||
          !this.checkIfAllPropertyParcelFieldsAreSelected() ||
          !this.checkIfPropertyIdFieldIsSelected() ||
          this.checkIfAllLatLngFieldsAreSelected()) &&
        this.state.address_fields_are_selected
      ) {
        this.setState({
          address_fields_are_selected: false
        });
      }
    }
  }

  submitList() {
    let mapping = [];
    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      if (this.state.header_edited_map[i].header_mapped_value !== "") {
        mapping.push(this.state.header_edited_map[i].header_mapped_value);
      } else {
        mapping.push(IGNORE_VALUE);
      }
    }

    this.props.setUploadListHeaders({
      token: this.props.token,
      bulk_job_id: this.props.upload_list_info.id,
      new_list_name: this.state.new_list_name,
      mapping: JSON.stringify(mapping),
      options: JSON.stringify({
        new_list_name: this.state.new_list_name
      }),
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });

        this.props.popSidePanel();

        //make this uploaded list the active one
        if (results.list && results.bulk_job_id) {
          this.props.addProgressBar({
            id: results?.bulk_job_id,
            type: "import",
            progress: results.completed_count,
            completed: results.completed,
            total_count: results.total_count,
            data: {
              list: results.list
            }
          });
        }
      }
    });
  }

  render() {
    return (
      <>
        <InputBox
          input_ref={this._filter_name}
          autoFocus={false}
          name="filter_name"
          disabled={false}
          returnKeyType="done"
          placeholder={
            !!this.state.new_list_name
              ? "Imported list name"
              : "Enter a name for this imported list"
          }
          onFocus={() => {}}
          onChange={value => {
            this.setState({
              new_list_name: value
            });
          }}
          blurOnSubmit={true}
          value={this.state.new_list_name}
          input_type="text"
          require_confirm={true}
          has_changed={
            Object.values(this.state.fields_are_selected).some(
              value => value
            ) && !!this.state.new_list_name
          }
          submit_button_primary={true}
          submit_button_title={"Complete Import"}
          onSubmit={() => this.submitList()}
          loading={this.state.loading}
        />

        <SearchBar
          title="Search Columns"
          value={this.state.search}
          onChange={value => {
            this.setState({
              search: value
            });
          }}
          style={{ margin: 10 }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {!this.checkIfAllPropertyAddressFieldsAreSelected() ||
          !this.checkIfAllPropertyParcelFieldsAreSelected() ||
          !this.checkIfPropertyIdFieldIsSelected() ||
          !this.checkIfAllLatLngFieldsAreSelected() ||
          this.state.ai_mapping_success ? (
            <Wrapper>
              <AttentionBox
                primary={true}
                title={
                  this.state.ai_mapping_success
                    ? "Our system has mapped some fields for you."
                    : "Map columns in your file to import properties."
                }
                description={
                  <>
                    {this.state.ai_mapping_success ? (
                      <Copy style={{ paddingBottom: 10 }}>
                        Please take a moment to check that the mapping is
                        correct.
                      </Copy>
                    ) : null}

                    <a
                      href="https://help.dealmachine.com/en/articles/6109824-how-do-i-upload-import-my-own-list-into-dealmachine"
                      target="_blank"
                    >
                      Learn how to import properties to DealMachine here.
                    </a>
                  </>
                }
              />
              <MappingRequirements
                colors={this.props.colors}
                fields_are_selected={this.state.fields_are_selected}
              />
              {!this.props.upload_list_info?.has_header_row ? (
                <AttentionBox
                  primary={false}
                  title={"It looks like you don't have a header row."}
                  description={
                    "For better results, please include a header row on the file you are attempting to import."
                  }
                />
              ) : null}
            </Wrapper>
          ) : null}
          <Wrapper
            style={{
              paddingRight: 45,
            }}
          >
            <Row
              style={{
                justifyContent: "flex-end",
                paddingBottom: 15
              }}
            >
            <InlineButton
            style={{ alignSelf: "stretch" }}
            disabled={this.isMappingCleared()}
            button_type={"normal"}
            buttonContainerStyle={{ alignSelf: "stretch" }}
            primary={true}
            onPress={() => {this.resetHeaderOptions()}}
          >
            {"Clear Mapping"}
          </InlineButton>
          </Row>
          </Wrapper>
          {this.state.header_edited_map.map((header, i) => {
            if (
              header.header_title
                .toLowerCase()
                .includes(this.state.search.toLowerCase())
            ) {
              return (
                <MappingObject
                  key={"upload_" + i}
                  header_item={header}
                  header_index={i}
                  header_options={this.state.header_options}
                  setHeaderOption={this.setHeaderOption.bind(this)}
                  upload_list_info={this.props.upload_list_info}
                  colors={this.props.colors}
                />
              );
            }
            return null;
          })}
        </KeyboardView>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, user } = auth;
  const { dark_mode, colors } = settings;

  return {
    token,
    user,
    dark_mode,
    colors
  };
};

export default connect(mapStateToProps, {
  changeTab,
  tabNavigation,
  editBulkUploadInfo,
  setUploadListHeaders,
  popAllSidePanels,
  popSidePanel,
  addListToListTabs,
  addProgressBar
})(UploadMapping);
