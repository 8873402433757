import React, { Component } from "react";
import {
  Wrapper,
  Card,
  PrimaryButton,
  Button,
  Copy,
  Bold,
  GoogleOAuth,
  AppleLogin,
  Row
} from "app/NativeComponents/common";
import {
  TextButton,
  CheckBoxInput,
  GhostButton,
  InlineButton,
  IconButton,
  SelectItem
} from "app/NativeComponents/snippets";

import { openUrl, displayIntercom } from "app/NativeActions";

class Buttons extends Component {
  renderTermsOfServiceText() {
    if (this.props.affiliate_partner) {
      return (
        <Wrapper>
          <Copy style={{ textAlign: "left" }}>
            By signing up, you agree to our
          </Copy>
          <Button
            onPress={() => {
              openUrl(
                "https://dealmachine.com/affiliate-partner-terms-of-service"
              );
            }}
          >
            <Copy
              style={{ textAlign: "left", textDecorationLine: "underline" }}
            >
              <Bold>affiliate terms of service.</Bold>
            </Copy>
          </Button>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Copy style={{ textAlign: "left" }}>
          By signing up, you agree to our
        </Copy>
        <Button
          onPress={() => {
            openUrl("https://dealmachine.com/terms-of-service");
          }}
        >
          <Copy style={{ textAlign: "left", textDecorationLine: "underline" }}>
            <Bold>terms of service.</Bold>
          </Copy>
        </Button>
      </Wrapper>
    );
  }

  render() {
    return (
      <>
        <Wrapper>
          <GhostButton
            primary={true}
            button_type="full"
            onPress={() => this.props.register()}
            loading={this.props.loading}
            icon_right={"arrow-right-alt"}
          >
            {this.props.user_info.signup_type == "team_invite"
              ? "Join Team"
              : !!this.props.buttonText
              ? this.props.buttonText
              : "Create Your Account"}
          </GhostButton>
          <Row
            style={{
              padding: 25,
              alignSelf: "stretch"
            }}
          >
            <Wrapper
              style={{
                flex: 1,
                height: 1,
                backgroundColor: this.props.colors.border_color
              }}
            />
            <Copy
              style={{
                width: "10%",
                textAlign: "center",
                marginLeft: 5,
                marginRight: 5
              }}
            >
              Or
            </Copy>
            <Wrapper
              style={{
                flex: 1,
                height: 1,
                backgroundColor: this.props.colors.border_color
              }}
            />
          </Row>
          <GoogleOAuth
            buttonText={"Sign in with Google"}
            onSuccess={this.props.login}
          />
          {this.props.platform !== "android" ? (
            <AppleLogin
              title={"Sign in with Apple"}
              onSucc={this.props.appleLogin}
              redirectPath={"sign-up"}
              style={{
                margin: 5,
                marginRight: 10,
                marginLeft: 10
              }}
            />
          ) : null}
        </Wrapper>

        <SelectItem
          noPress={true}
          select_type={"none"}
          renderLeft={() => {
            return (
              <IconButton
                onPress={() => {
                  this.props.authFieldChanged({
                    prop: "accepted_terms",
                    value: this.props.accepted_terms == 1 ? 0 : 1
                  });
                }}
                icon={
                  this.props.accepted_terms == 1
                    ? "check-box"
                    : "check-box-outline-blank"
                }
              />
            );
          }}
        >
          {this.renderTermsOfServiceText()}
        </SelectItem>
      </>
    );
  }
}

export default Buttons;
