import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Copy,
  Scroll,
  ProfilePic,
  Row,
  Wrapper,
  Icon,
  Bold,
  AlmaOrb,
  Spin
} from "app/NativeComponents/common";

import {
  IconButton,
  SpinWrapper,
  SelectItem,
  InlineButton,
  InputBox
} from "app/NativeComponents/snippets";

import {
  dialerConversationCleanUp,
  dialerConversationPredict,
  updateDialerConversation,
  updateDialerConversationMessage,
  pushSidePanel,
  replaceSidePanel,
  updateActiveCall,
  removeDialerConversationMessage,
  dialerConversationSummarize,
  phoneCall,
  getCleanAIData,
  setDialerConversation,
  updateLead,
  updateVoiceClone,
  formatUsPhone,
  setActiveDialerContact,
  updateCalledNumbers,
  updateCallSession,
  getContactCallHistory,
  playAudio,
  pauseAudio,
  killAudio,
  killAllAudio
} from "app/NativeActions";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";

import CallResultOption from "./CallResultOption";
import CallHistory from "./CallHistory";
import ActionButtons from "./ActionButtons";
import MessageSuggest from "./MessageSuggest";

class CurrentConversation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cleanup_loading: false,
      loading_ids: [],
      suggested_message: "",
      predict_id_loading: null,
      scrollTop: 0,
      cleaned_property: null,
      playing_audio: false,

      generated_voicemail_loading: false,
      generated_voicemail: null,
      generated_voicemail_text: null,
      generated_voicemail_text_loading: null,
      has_voice_clone: false,
      call_history: [],
      edit_note: false,
      edit_note_text: null
    };

    this._message_scroll_view = React.createRef();

    this.nextContactInQueue = this.nextContactInQueue.bind(this);
    this.nextNumberInQueue = this.nextNumberInQueue.bind(this);
    this.nextLeadInQueue = this.nextLeadInQueue.bind(this);

    this.handleNumberChange = this.handleNumberChange.bind(this);

    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.updateCallResults = this.updateCallResults.bind(this);

    this.resetConversation = this.resetConversation.bind(this);
    this.removeMessage = this.removeMessage.bind(this);
    this.suggestMessage = this.suggestMessage.bind(this);

    this.generateAIVoicemailText = this.generateAIVoicemailText.bind(this);
    this.generateAIVoicemailRecording =
      this.generateAIVoicemailRecording.bind(this);

    this.checkIfUserTalked = this.checkIfUserTalked.bind(this);

    this._edit_note_input = React.createRef();
  }

  nextNumberInQueue() {
    killAllAudio();

    if (
      !this.props.active_call?.call_status ||
      this.props.active_call?.call_status === "loading" ||
      this.props.active_call?.call_status === "calling" ||
      this.props.active_call?.call_status === "connected"
    ) {
      this.props.updateCallSession({
        token: this.props.token,
        type: "skip_call_session_item",

        call_id: this.props.active_call?.call_id,
        call_session_id: this.props.current_call_session?.id,
        to_phone_number:
          this.props.active_dialer_contact?.selected_phone?.number,
        skipped_contact_id: this.props.active_dialer_contact?.individual_key,
        skipped_property_id:
          this.props.active_dialer_contact?.associated_lead?.id
      });
    }

    this.props.updateCalledNumbers([
      ...this.props.called_numbers,
      {
        number: this.props.active_dialer_contact?.selected_phone?.number
      }
    ]);

    const new_queued_numbers = this.props.queued_numbers.filter(
      number =>
        number.number !=
        this.props.active_dialer_contact?.selected_phone?.number
    );

    let next_number = null;
    for (let i = 0; i < new_queued_numbers?.length; i++) {
      if (
        new_queued_numbers[i].contact_id ==
          this.props.active_dialer_contact?.individual_key &&
        !next_number
      ) {
        next_number = new_queued_numbers[i];
        break;
      }
    }

    if (!next_number) {
      next_number = new_queued_numbers[0];
    }

    this.props.setActiveDialerContact({
      contact: {
        ...next_number.contact,
        selected_phone: {
          number: next_number.number,
          type: next_number.type,
          do_not_call_flag: next_number.do_not_call_flag,
          owner: next_number.owner
        },
        associated_lead: next_number.property
      },
      queued_numbers: new_queued_numbers
    });
    this.props.handleHangup(true);
  }

  nextContactInQueue() {
    //add all numbers from queued_numbers to called_numbers where the contact id is the same
    const { queued_numbers } = this.props;

    if (
      !this.props.active_call?.call_status ||
      this.props.active_call?.call_status === "loading" ||
      this.props.active_call?.call_status === "calling" ||
      this.props.active_call?.call_status === "connected"
    ) {
      this.props.updateCallSession({
        token: this.props.token,
        type: "skip_call_session_item",
        call_id: this.props.active_call?.call_id,
        to_phone_number:
          this.props.active_dialer_contact?.selected_phone?.number,
        call_session_id: this.props.current_call_session?.id,
        contact_id: this.props.active_dialer_contact?.individual_key,
        skipped_contact_id: this.props.active_dialer_contact?.individual_key,
        skipped_property_id:
          this.props.active_dialer_contact?.associated_lead?.id
      });
    }

    this.props.updateCalledNumbers([
      ...this.props.called_numbers,
      ...this.props.queued_numbers.filter(
        number =>
          number.contact_id == this.props.active_dialer_contact?.individual_key
      )
    ]);

    const queued_numbers_with_removed_contact = queued_numbers.filter(
      number =>
        number.contact_id != this.props.active_dialer_contact?.individual_key
    );

    let next_contact = null;

    if (queued_numbers_with_removed_contact.length > 0) {
      next_contact = queued_numbers_with_removed_contact[0];
    }

    if (queued_numbers_with_removed_contact?.length > 0) {
      this.props.setActiveDialerContact({
        contact: {
          ...next_contact.contact,
          selected_phone: {
            number: next_contact.number,
            type: next_contact.type,
            do_not_call_flag: next_contact.do_not_call_flag,
            owner: next_contact?.owner
          },
          associated_lead: next_contact.property
        },
        queued_numbers: queued_numbers_with_removed_contact
      });
      this.props.handleHangup(true);
    } else {
      //either new lead or end call
      this.props.handleHangup(true);
    }
  }

  nextLeadInQueue() {
    const { queued_numbers } = this.props;
    if (queued_numbers.length > 0) {
      //remove lead from queue where the property_id matches the current lead

      if (
        !this.props.active_call?.call_status ||
        this.props.active_call?.call_status === "loading" ||
        this.props.active_call?.call_status === "calling" ||
        this.props.active_call?.call_status === "connected"
      ) {
        this.props.updateCallSession({
          token: this.props.token,
          type: "skip_call_session_item",
          call_id: this.props.active_call?.call_id,
          to_phone_number:
            this.props.active_dialer_contact?.selected_phone?.number,
          call_session_id: this.props.current_call_session?.id,
          property_id: this.props.active_dialer_contact?.associated_lead?.id,
          skipped_contact_id: this.props.active_dialer_contact?.individual_key,
          skipped_property_id:
            this.props.active_dialer_contact?.associated_lead?.id
        });
      }

      const queued_numbers_with_removed_property = queued_numbers.filter(
        number =>
          number.property_id !=
          this.props.active_dialer_contact?.associated_lead?.id
      );

      let next_lead = null;

      if (queued_numbers_with_removed_property.length > 0) {
        next_lead = queued_numbers_with_removed_property[0];
      }

      if (next_lead) {
        this.props.updateCalledNumbers([
          ...this.props.called_numbers,
          ...this.props.queued_numbers.filter(
            number =>
              number.property_id ==
              this.props.active_dialer_contact?.associated_lead?.id
          )
        ]);

        if (queued_numbers_with_removed_property?.length > 0) {
          this.props.setActiveDialerContact({
            contact: {
              ...next_lead.contact,
              selected_phone: {
                number: next_lead.number,
                type: next_lead.type,
                do_not_call_flag: next_lead.do_not_call_flag,
                owner: next_lead?.owner
              },
              associated_lead: next_lead.property
            },
            queued_numbers: queued_numbers_with_removed_property
          });

          this.props.handleHangup(true);
        } else {
          //either new lead or end call
          this.props.handleHangup(true);
        }
      } else {
        this.props.handleHangup(true);
      }
    } else {
      this.props.handleHangup(true);
    }
  }

  handleNumberChange() {
    this.props.getContactCallHistory({
      token: this.props.token,
      sort_by: "date_created_asc",
      to_phone_number: this.props.active_dialer_contact?.selected_phone?.number,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        this.setState({ call_history: results?.call_history });
      }
    });
  }

  generateAIVoicemailText() {
    if (
      this.props.active_call?.call_id &&
      this.state.generated_voicemail_text_loading !==
        this.props.active_call?.call_id &&
      !!this.props.active_call?.from_phone_number
    ) {
      this.setState(
        {
          generated_voicemail_text: null,
          generated_voicemail: null
        },
        () => {
          let use_callback_number_in_voicemail = false;
          if (this.props.user?.user_settings?.dialer_settings) {
            use_callback_number_in_voicemail =
              this.props.user?.user_settings?.dialer_settings
                .use_callback_number_in_voicemail;
          }
          /*removing for now
          const callback_number = use_callback_number_in_voicemail
            ? this.props.user?.user_settings?.dialer_settings &&
              this.props.user?.user_settings?.dialer_settings?.callback_number
              ? this.props.user?.user_settings?.dialer_settings?.callback_number
              : this.props.user?.team_phone
            : !!this.props.active_call?.from_phone_number
              ? this.props.active_call?.from_phone_number
              : this.props.user?.user_settings?.dialer_settings &&
                  this.props.user?.user_settings?.dialer_settings
                    ?.callback_number
                ? this.props.user?.user_settings?.dialer_settings
                    ?.callback_number
                : this.props.user?.team_phone;

           */

          const callback_number = this.props.active_call?.from_phone_number;
          this.props.updateVoiceClone({
            token: this.props.token,
            type: "create_voicemail_text_from_voice_clone",
            call_id: this.props.active_call?.call_id,
            contact_id: this.props.active_dialer_contact?.individual_key,
            property_id: this.props.active_dialer_contact?.associated_lead
              ? this.props.active_dialer_contact?.associated_lead?.id
              : null,
            callback_number: formatUsPhone(callback_number),
            onLoading: () => {
              this.setState({
                generated_voicemail_text_loading:
                  this.props.active_call?.call_id
              });
            },
            onError: error => {
              this.setState({
                generated_voicemail_text_loading: null
              });
            },
            onSuccess: results => {
              if (results.generated_text) {
                this.setState({
                  generated_voicemail_text_loading: false,
                  generated_voicemail_text:
                    this.props.active_dialer_contact?.individual_key ==
                    results?.generated_text?.contact_id
                      ? results.generated_text?.text
                      : this.state.generated_text?.text,
                  has_voice_clone: results?.generated_text?.has_voice_clone
                });
              } else {
                this.setState({
                  generated_voicemail_text_loading: null
                });
              }
            }
          });
        }
      );
    }
  }
  generateAIVoicemailRecording() {
    if (!!this.state.generated_voicemail_text) {
      this.props.updateVoiceClone({
        token: this.props.token,
        type: "create_voicemail_from_voice_clone",
        generated_text: this.state.generated_voicemail_text,
        call_id: this.props.active_call?.call_id,
        contact_id: this.props.active_dialer_contact?.individual_key,
        property_id: this.props.active_dialer_contact?.associated_lead?.id,
        onLoading: () => {
          this.setState({
            generated_voicemail_loading: true,
            generated_voicemail_text_loading: true
          });
        },
        onError: error => {
          this.setState({
            generated_voicemail_loading: false,
            generated_voicemail_text_loading: false
          });
        },
        onSuccess: results => {
          if (results.recording) {
            this.setState({
              generated_voicemail_loading: false,
              generated_voicemail_text_loading: false,
              generated_voicemail_text: results.recording?.text_prompt,
              generated_voicemail:
                this.props.active_call?.call_id == results.recording?.call_id
                  ? results.recording
                  : this.state.generated_voicemail,
              has_voice_clone: results?.has_voice_clone
            });
          } else {
            this.setState({
              generated_voicemail_loading: false
            });
          }
        }
      });
    }
  }

  scrollToBottom() {
    const { device } = this.props;

    clearInterval(this._scroll_timeout);
    this._scroll_timeout = setTimeout(() => {
      if (
        device === "desktop" &&
        this._message_scroll_view &&
        this._message_scroll_view.current
      ) {
        this._message_scroll_view.current.scrollTop =
          this._message_scroll_view.current.scrollHeight + 20;
      } else if (
        device === "mobile" &&
        this._message_scroll_view &&
        this._message_scroll_view.current &&
        this._message_scroll_view.current.scrollToEnd
      ) {
        this._message_scroll_view.current.scrollToEnd({ animated: true });
      }
    }, 100);
  }

  componentDidMount() {
    this.scrollToBottom();

    this.getCleanData();
    this.handleNumberChange();
  }

  componentWillUnmount() {
    clearInterval(this._scroll_timeout);

    this.removeAudio();
  }

  removeAudio() {
    killAudio({ audio: this._audio, use_track: true });
  }

  getCleanData() {
    this.setState(
      {
        cleaned_property: null
      },
      () => {
        if (this.props.active_dialer_contact?.associated_lead) {
          this.props.getCleanAIData({
            token: this.props.token,
            property_id:
              this.props.active_dialer_contact?.associated_lead?.property_id,
            type: "property",
            onLoading: () => {},
            onError: () => {},
            onSuccess: results => {
              this.setState({
                cleaned_property: results.cleaned_property
                  ? results.cleaned_property
                  : null
              });
            }
          });
        }
      }
    );
  }

  updateCallResults(call_results) {
    if (!this.state.call_results_loading) {
      this.props.updateActiveCall({
        ...this.props.active_call,
        call_results:
          this.props.active_call.call_results === call_results
            ? null
            : call_results
      });

      this.props.phoneCall({
        token: this.props.token,
        type: "update_call_results",
        call_results: call_results,
        call_id: this.props.active_call?.call_id,
        onLoading: () => {
          this.setState({
            call_results_loading: call_results
          });
        },
        onError: error => {
          //handle error
          this.setState({
            call_results_loading: null
          });
        },
        onSuccess: results => {
          this.setState(
            {
              call_results_loading: null
            },
            () => {}
          );
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.current_conversation) !==
      JSON.stringify(prevProps.current_conversation)
    ) {
      if (
        this.props.current_conversation?.length > 0 &&
        this.props.current_conversation[
          this.props.current_conversation.length - 1
        ].type !== "suggestion"
      ) {
        this.setState({
          last_suggestion_id: null,
          suggested_message: null
        });
      } else {
      }

      if (
        this.props.current_conversation?.length > 0 &&
        this.props.current_conversation[
          this.props.current_conversation.length - 1
        ].type !== "inbound"
      ) {
        this.scrollToBottom();
      }
    }

    if (
      JSON.stringify(this.state.call_history) !==
      JSON.stringify(prevState.call_history)
    ) {
      this.scrollToBottom();
    }

    if (
      this.props.active_call?.call_status !==
        prevProps.active_call?.call_status ||
      this.props.active_call?.note !== prevProps.active_call?.note
    ) {
      this.scrollToBottom();
    }

    if (
      this.props.active_call?.call_status !==
        prevProps.active_call?.call_status &&
      this.props.active_call?.call_status === "call_ended" &&
      !this.props.active_dialer_contact?.answered_on_another_device
    ) {
      this.summarizeConversation();
    }

    //get call info when the call is an outside device and it ends
    if (
      this.props.active_call?.call_status !==
        prevProps.active_call?.call_status &&
      this.props.active_call?.call_status === "call_ended" &&
      this.props.active_dialer_contact?.answered_on_another_device &&
      !this.props.active_call.call_results &&
      !this.state.summarize_loading
    ) {
      this.setState({
        summarize_loading: true
      });
    }
    //now we have our call results
    if (
      this.props.active_call.call_results !==
        prevProps.active_call.call_results &&
      this.props.active_call?.call_status === "call_ended" &&
      this.props.active_dialer_contact?.answered_on_another_device &&
      !!this.props.active_call.call_results &&
      this.state.summarize_loading
    ) {
      this.setState({
        summarize_loading: false
      });
    }

    if (
      this.props.active_dialer_contact?.individual_key !=
        prevProps.active_dialer_contact?.individual_key ||
      this.props.active_dialer_contact?.selected_phone?.number !=
        prevProps.active_dialer_contact?.selected_phone?.number ||
      this.props.active_call?.call_id != prevProps.active_call?.call_id
    ) {
      this.setState(
        {
          edit_note: false,
          edit_note_text: null,
          generated_voicemail_text: null,
          generated_voicemail_text_loading: null,
          generated_voicemail: null,
          generated_voicemail_loading: false
        },
        () => {
          this.resetConversation();
          this.removeAudio();
        }
      );
    }

    if (
      this.props.active_dialer_contact?.associated_lead?.property_id !==
      prevProps.active_dialer_contact?.associated_lead?.property_id
    ) {
      this.getCleanData();
    }

    if (
      this.props.active_call?.answered_by !==
        prevProps.active_call?.answered_by &&
      this.props.active_call?.call_status !== "call_ended" &&
      this.props.active_call?.answered_by === "machine" &&
      !!this.state.generated_voicemail_text
    ) {
      this.generateAIVoicemailRecording();
    }

    if (
      (this.props.active_call?.call_id &&
        this.props.active_call?.call_id !== prevProps.active_call?.call_id &&
        this.props.countdown === 0) ||
      (this.props.countdown === 0 &&
        this.props.countdown !== prevProps.countdown &&
        this.props.active_call?.call_id)
    ) {
      this.generateAIVoicemailText();
    }

    if (
      !!this.props.active_dialer_contact?.selected_phone?.number &&
      this.props.active_dialer_contact?.selected_phone?.number !==
        prevProps.active_dialer_contact?.selected_phone?.number
    ) {
      this.handleNumberChange(true);
    }
  }

  resetConversation() {
    this.setState({
      cleanup_loading: false,
      loading_ids: [],
      predict_id_loading: null,
      scrollTop: 0
    });
  }

  removeMessage(message_id, message_type) {
    this.props.removeDialerConversationMessage({
      id: message_id,
      type: "message_type"
    });
  }

  suggestMessage() {
    const last_suggestion_id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    this.setState(
      {
        last_suggestion_id: last_suggestion_id,
        suggested_message_loading: true
      },
      () => {
        this.props.updateDialerConversation({
          type: "suggestion",
          message: "",
          complete: true,
          id: last_suggestion_id
        });
      }
    );
  }

  checkIfUserTalked() {
    let user_talked_amount = 0;

    for (let i = 0; i < this.props.current_conversation.length; i++) {
      if (
        this.props.current_conversation[i].type === "inbound" &&
        !!this.props.current_conversation[i].message
      ) {
        user_talked_amount += this.props.current_conversation[i].message.length;

        if (user_talked_amount > 25) {
          break;
        }
      }
    }
    return user_talked_amount > 25;
  }

  summarizeConversation() {
    let conversation_without_suggestions = [];
    for (let i = 0; i < this.props.current_conversation.length; i++) {
      if (
        this.props.current_conversation[i].type !== "suggestion" &&
        !!this.props.current_conversation[i].message
      ) {
        conversation_without_suggestions.push(
          this.props.current_conversation[i]
        );
      }
    }

    if (conversation_without_suggestions?.length === 0) {
      if (!this.props.active_call?.call_results) {
        this.updateCallResults("no_answer");
      }
    } else {
      this.props.dialerConversationSummarize({
        token: this.props.token,
        call_id: this.props.active_call?.call_id,
        contact_id: this.props.active_dialer_contact.individual_key,
        property_id:
          this.props.active_dialer_contact?.associated_lead?.property_id,
        answered_by: this.props.active_call?.answered_by,
        conversation: JSON.stringify(conversation_without_suggestions),
        onLoading: () => {
          this.setState({
            summarize_loading: true
          });
        },
        onError: () => {
          this.setState({
            summarize_loading: false
          });
        },
        onSuccess: results => {
          if (
            !!results?.summarization?.status &&
            results?.call_id == this.props.active_call?.call_id
          ) {
            this.setState(
              {
                summarize_loading: false
              },
              () => {
                this.props.updateActiveCall({
                  ...this.props.active_call,
                  call_results: results?.summarization?.status,
                  note: results?.summarization?.note
                });

                this.scrollToBottom();
              }
            );
          } else {
            this.setState({
              summarize_loading: false
            });
            this.scrollToBottom();
          }
        }
      });
    }
  }

  render() {
    const { current_conversation, colors, active_dialer_contact, active_call } =
      this.props;

    if (!this.props.show_more && this.props.device !== "mobile") {
      return null;
    }

    let is_corporate = false;
    if (
      active_dialer_contact?.associated_lead &&
      active_dialer_contact?.associated_lead?.property_flags
    ) {
      for (
        let i = 0;
        i < active_dialer_contact.associated_lead?.property_flags?.length;
        i++
      ) {
        if (
          active_dialer_contact.associated_lead?.property_flags[i]?.value ===
          "corporate_owner"
        ) {
          is_corporate = true;
        }
      }
    }

    //if this lead_status_type is acquisition_status, disposition_status, or won_status then we don't display options for how was the call
    const lead_status_type =
      active_dialer_contact?.associated_lead?.deal?.deal_status_type;

    return (
      <>
        <Wrapper
          style={
            this.props.device === "desktop"
              ? {
                  height: this.props.show_more_height,
                  position: "relative",
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30,
                  overflow: "hidden",
                  backgroundColor: colors.dark_phone_inner_color
                }
              : {
                  position: "relative",
                  overflow: "hidden",
                  backgroundColor: colors.dark_phone_inner_color,
                  flex: 1
                }
          }
        >
          <Scroll
            scroll_ref={this._message_scroll_view}
            stickyHeaderIndices={
              active_dialer_contact?.associated_lead ? [0] : null
            }
            style={{
              flex: 1
            }}
            onScroll={e => {
              this.setState({
                scrollTop: this._message_scroll_view?.current?.scrollTop
              });
            }}
          >
            {active_dialer_contact?.associated_lead ? (
              <Wrapper
                style={
                  this.props.device === "desktop"
                    ? {
                        position: "sticky",
                        top: 0,
                        backgroundColor: colors.dark_phone_color,
                        zIndex: 1
                      }
                    : { backgroundColor: colors.dark_phone_color }
                }
              >
                <LeadItem
                  small_mode={true}
                  property={active_dialer_contact?.associated_lead}
                  dialer_mode={true}
                  no_value={true}
                  label={"Calling about:"}
                  noPress={this.props.onboarding}
                  onPress={() => {
                    if (this.props.hideMore) {
                      this.props.hideMore();
                    }
                    this.props.replaceSidePanel({
                      slug: "property",
                      focus_side_panel: true,
                      overlay: true,
                      id: active_dialer_contact?.associated_lead?.property_id,
                      data: {
                        property: active_dialer_contact?.associated_lead,
                        expanded: true
                      }
                    });
                  }}
                  renderBottom={
                    active_dialer_contact?.associated_lead
                      ? () => {
                          return (
                            <Row>
                              {active_dialer_contact.likely_owner ||
                              (is_corporate &&
                                active_dialer_contact?.mailing_address_match) ? (
                                <Wrapper
                                  style={{ marginRight: 10, marginBottom: 5 }}
                                  tooltip={
                                    active_dialer_contact.likely_owner
                                      ? "The individual's address and name match the property's mailing address and owner listed in the county records."
                                      : "The individual's address matches the company's mailing address listed in the county records"
                                  }
                                  tooltipPlacement={"top"}
                                >
                                  <Row>
                                    <Icon
                                      icon={
                                        active_dialer_contact.likely_owner
                                          ? "check"
                                          : "business"
                                      }
                                      size={14}
                                      color={colors.success_color}
                                      style={{ marginRight: 5 }}
                                    />
                                    <Copy
                                      style={{
                                        fontSize: 10,
                                        color: colors.white_text_color
                                      }}
                                    >
                                      <Bold>
                                        {active_dialer_contact.matching_type?.includes(
                                          "tiebreaker"
                                        )
                                          ? "Potential Owner"
                                          : "Likely Owner"}
                                      </Bold>
                                    </Copy>
                                  </Row>
                                </Wrapper>
                              ) : null}

                              {!active_dialer_contact.likely_owner &&
                              active_dialer_contact.in_owner_family ? (
                                <Wrapper
                                  style={{ marginRight: 10, marginBottom: 5 }}
                                  tooltip={
                                    "The person's last name matches the property owner's name listed in the county records."
                                  }
                                  tooltipPlacement={"top"}
                                >
                                  <Row>
                                    <Icon
                                      icon={"group"}
                                      size={14}
                                      color={colors.success_color}
                                      style={{ marginRight: 5 }}
                                    />
                                    <Copy
                                      style={{
                                        fontSize: 10,
                                        color: colors.white_text_color
                                      }}
                                    >
                                      Family
                                    </Copy>
                                  </Row>
                                </Wrapper>
                              ) : null}

                              {active_dialer_contact.resident ? (
                                <Wrapper
                                  tooltip={
                                    "The person resides at the property."
                                  }
                                  tooltipPlacement={"top"}
                                  style={{ marginRight: 10, marginBottom: 5 }}
                                >
                                  <Row>
                                    <Icon
                                      icon={"house"}
                                      size={14}
                                      color={colors.success_color}
                                      style={{ marginRight: 5 }}
                                    />
                                    <Copy
                                      style={{
                                        fontSize: 10,
                                        color: colors.white_text_color
                                      }}
                                    >
                                      Resident
                                    </Copy>
                                  </Row>
                                </Wrapper>
                              ) : null}

                              {active_dialer_contact.likely_renting ? (
                                <Wrapper
                                  tooltip={
                                    "The individual's name doesn't align with the listed property owner's, and they don't appear to be related. Additionally, their living address is different from the listed mailing address, suggesting they are a renter."
                                  }
                                  tooltipPlacement={"top"}
                                  style={{ marginRight: 10, marginBottom: 5 }}
                                >
                                  <Row>
                                    <Icon
                                      icon={"attach-money"}
                                      size={14}
                                      color={colors.success_color}
                                      style={{ marginRight: 5 }}
                                    />
                                    <Copy
                                      style={{
                                        fontSize: 10,
                                        color: colors.white_text_color
                                      }}
                                    >
                                      Likely Renting
                                    </Copy>
                                  </Row>
                                </Wrapper>
                              ) : null}
                            </Row>
                          );
                        }
                      : null
                  }
                />
              </Wrapper>
            ) : null}

            {this.props.active_dialer_contact?.individual_key &&
            this.props.active_dialer_contact?.individual_key !==
              "unknown_contact" ? (
              <CallHistory
                call_history={this.state.call_history}
                pushSidePanel={this.props.pushSidePanel}
                hideMore={this.props.hideMore}
              />
            ) : null}

            {!!current_conversation && current_conversation.length > 0
              ? current_conversation.map((message, i) => {
                  if (!!message.message || message?.type === "suggestion") {
                    let use_callback_number_in_voicemail = false;
                    if (this.props.user?.user_settings?.dialer_settings) {
                      use_callback_number_in_voicemail =
                        this.props.user?.user_settings?.dialer_settings
                          .use_callback_number_in_voicemail;
                    }

                    /*removing for now
                    const callback_number = use_callback_number_in_voicemail
                      ? this.props.user?.user_settings?.dialer_settings &&
                        this.props.user?.user_settings?.dialer_settings
                          ?.callback_number
                        ? this.props.user?.user_settings?.dialer_settings
                            ?.callback_number
                        : this.props.user?.team_phone
                      : !!this.props.active_call?.from_phone_number
                      ? this.props.active_call?.from_phone_number
                      : this.props.user?.user_settings?.dialer_settings &&
                        this.props.user?.user_settings?.dialer_settings
                          ?.callback_number
                      ? this.props.user?.user_settings?.dialer_settings
                          ?.callback_number
                      : this.props.user?.team_phone;

                     */

                    const callback_number =
                      this.props.active_call?.from_phone_number;

                    return (
                      <Row
                        key={"conversation_" + i}
                        style={{
                          justifyContent:
                            message?.type !== "inbound"
                              ? "flex-start"
                              : "flex-end",
                          marginRight:
                            message?.type !== "inbound" ||
                            message?.type === "suggestion"
                              ? 5
                              : 0,
                          marginLeft: message?.type === "inbound" ? 10 : 0
                        }}
                      >
                        {message?.type === "outbound" ? (
                          <ProfilePic
                            size={30}
                            firstname={active_dialer_contact?.given_name}
                            lastname={active_dialer_contact?.surname}
                            email={active_dialer_contact?.email_address_1}
                            image={null}
                            background_color={colors.dark_phone_color}
                            fontColor={colors.white_text_color}
                            style={{ marginRight: 5, marginLeft: 5 }}
                          />
                        ) : message?.type === "suggestion" ? (
                          <Wrapper
                            style={{
                              marginRight: 5,
                              marginLeft: 5,
                              width: 30,
                              height: 30,
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <AlmaOrb
                              active={
                                this.state.last_suggestion_id &&
                                this.state.last_suggestion_id === message?.id &&
                                this.props.active_call?.call_status !==
                                  "call_ended"
                              }
                              size={18}
                            />
                          </Wrapper>
                        ) : null}

                        {message?.type === "suggestion" ? (
                          <MessageSuggest
                            message={message}
                            generate_voicemail={
                              this.props.active_call?.answered_by ===
                                "machine" && !this.checkIfUserTalked()
                            }
                            last_suggestion_id={this.state.last_suggestion_id}
                            contact={active_dialer_contact}
                            current_conversation={current_conversation}
                            cleaned_property={this.state.cleaned_property}
                            removeMessage={this.removeMessage}
                            callback_number={callback_number}
                            onComplete={() => {
                              this.setState({
                                suggested_message_loading: false
                              });
                            }}
                          />
                        ) : (
                          <Wrapper
                            style={{
                              backgroundColor:
                                message?.type === "outbound"
                                  ? colors.background_color
                                  : colors.active_color,
                              borderRadius: 5,
                              borderBottomRightRadius:
                                message?.type !== "inbound" ? 5 : 0,
                              borderBottomLeftRadius:
                                message?.type !== "inbound" ? 0 : 5,
                              margin: 5,
                              marginRight: message?.type !== "inbound" ? 40 : 0,
                              marginLeft: message?.type === "inbound" ? 40 : 0
                            }}
                          >
                            <Wrapper
                              style={{
                                position: "relative",

                                padding: 15,
                                paddingRight:
                                  message?.subtype === "dropped_voicemail"
                                    ? 55
                                    : 15,
                                paddingLeft: 15,
                                paddingTop: 15,
                                borderRadius: 5
                              }}
                            >
                              {message?.subtype === "dropped_voicemail" ? (
                                <Wrapper
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0
                                  }}
                                >
                                  <IconButton
                                    icon={
                                      this.state.playing_audio
                                        ? "pause"
                                        : "play-arrow"
                                    }
                                    tooltip={
                                      this.state.playing_audio
                                        ? "Pause Recording"
                                        : "Play Recording"
                                    }
                                    tooltipPlacement={"top"}
                                    onPress={() => {
                                      //play message.url an mp3 file

                                      if (this.state.playing_audio) {
                                        this.setState(
                                          {
                                            playing_audio:
                                              !this.state.playing_audio
                                          },
                                          () => {
                                            pauseAudio({
                                              audio: this._audio,
                                              use_track: true
                                            });
                                          }
                                        );
                                      } else {
                                        this.setState(
                                          {
                                            playing_audio:
                                              !this.state.playing_audio
                                          },
                                          () => {
                                            playAudio({
                                              url_file: message.url,
                                              onEnd: () => {
                                                this.setState({
                                                  playing_audio: false
                                                });
                                              },
                                              onPrepared: audio => {
                                                this._audio = audio;
                                              }
                                            });
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </Wrapper>
                              ) : null}
                              {message?.subtype === "dropped_voicemail" ? (
                                <Row>
                                  <Wrapper style={{ flex: 1 }}>
                                    <Copy style={{ fontSize: 12 }}>
                                      <Bold>Dropped voicemail:</Bold>
                                    </Copy>
                                  </Wrapper>
                                </Row>
                              ) : null}

                              <Copy
                                style={{
                                  color: colors.text_color
                                }}
                              >
                                {message.message}
                              </Copy>
                            </Wrapper>
                            {message?.subtype === "dropped_voicemail" &&
                            (!this.state.has_voice_clone ||
                              !this.props.user?.user_settings?.dialer_settings
                                ?.ai_voicemail) ? (
                              <SelectItem
                                onPress={() => {
                                  if (this.props.hideMore) {
                                    this.props.hideMore();
                                  }
                                  this.props.pushSidePanel({
                                    slug: "dialer_settings",
                                    overlay: true,
                                    data: {
                                      tab: "voice_clone"
                                    }
                                  });
                                }}
                                select_type={"none"}
                                icon={"keyboard-arrow-right"}
                                description={
                                  "Use your voice and create a voice clone to leave voicemails for you."
                                }
                              >
                                Want to use your own voice?
                              </SelectItem>
                            ) : null}
                          </Wrapper>
                        )}

                        {message?.type === "inbound" ? (
                          <ProfilePic
                            size={30}
                            firstname={this.props.user?.firstname}
                            lastname={this.props.user?.lastname}
                            email={this.props.user?.email}
                            image={this.props.user?.image}
                            background_color={colors.dark_phone_inner_color}
                            fontColor={colors.white_text_color}
                            style={{ marginRight: 5, marginLeft: 5 }}
                          />
                        ) : null}
                      </Row>
                    );
                  }
                  return null;
                })
              : null}

            <>
              {(!active_call?.call_status ||
                this.props.call_canceled ||
                this.props.countdown > 0) &&
              !this.props.active_dialer_contact?.incoming_call &&
              !this.props.active_dialer_contact?.answered_on_another_device ? (
                <>
                  {this.props.device === "mobile" ? (
                    <>
                      {!!this.props.countdown &&
                      this.props.countdown > 0 &&
                      !this.props.active_dialer_contact?.incoming_call &&
                      !this.props.active_dialer_contact
                        ?.answered_on_another_device ? (
                        <Row style={{ justifyContent: "center", padding: 10 }}>
                          <Copy
                            style={{
                              color: colors.white_text_color,
                              textAlign: "center",
                              fontSize: 12,
                              marginLeft: 5
                            }}
                          >
                            <Bold>Calling in...{this.props.countdown}</Bold>
                          </Copy>
                        </Row>
                      ) : (this.props.start_call_loading ||
                          active_call?.call_status === "loading" ||
                          active_call?.call_status === "calling") &&
                        !this.props.call_canceled ? (
                        <Row style={{ justifyContent: "center", padding: 10 }}>
                          {this.props.devcei === "mobile" ? (
                            <Spin
                              color={colors.white_text_color}
                              size={"small"}
                            />
                          ) : null}
                          <Copy
                            style={{
                              color: colors.white_text_color,
                              textAlign: "center",
                              fontSize: 12,
                              marginLeft: 5
                            }}
                          >
                            <Bold>Loading Call</Bold>
                          </Copy>
                        </Row>
                      ) : (
                        <Row style={{ justifyContent: "center", padding: 10 }}>
                          <Copy
                            style={{
                              color: colors.white_text_color,
                              textAlign: "center",
                              fontSize: 12,
                              marginLeft: 5
                            }}
                          >
                            <Bold>Start call to see a live conversation</Bold>
                          </Copy>
                        </Row>
                      )}
                    </>
                  ) : (
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Copy
                        style={{
                          color: colors.white_text_color,
                          textAlign: "center",
                          fontSize: 12,
                          marginLeft: 5
                        }}
                      >
                        <Bold>Start call to see a live conversation</Bold>
                      </Copy>
                    </Row>
                  )}
                </>
              ) : active_call?.call_status === "loading" ||
                active_call?.call_status === "calling" ? (
                <Row style={{ justifyContent: "center", padding: 10 }}>
                  {this.props.device === "mobile" ? (
                    <Spin color={colors.white_text_color} size={"small"} />
                  ) : null}
                  <Copy
                    style={{
                      color: colors.white_text_color,
                      textAlign: "center",
                      fontSize: 12,
                      marginLeft: 5
                    }}
                  >
                    <Bold>Loading Call...</Bold>
                  </Copy>
                </Row>
              ) : active_call?.call_status === "connected" &&
                !this.props.active_dialer_contact
                  ?.answered_on_another_device ? (
                <Row style={{ justifyContent: "center", padding: 10 }}>
                  <Icon
                    icon={"ring-volume"}
                    size={14}
                    color={colors.white_text_color}
                    style={{ marginRight: 5 }}
                  />
                  <Copy
                    style={{
                      color: colors.white_text_color,
                      textAlign: "center",
                      fontSize: 12
                    }}
                  >
                    <Bold>
                      {!!this.props.active_call?.from_phone_number
                        ? "Calling from " +
                          formatUsPhone(
                            this.props.active_call?.from_phone_number
                          )
                        : "Calling..."}
                    </Bold>
                  </Copy>
                </Row>
              ) : active_call?.answered_by === "machine" &&
                active_call?.call_status !== "call_ended" ? (
                <Row
                  style={{
                    justifyContent: "center",
                    padding: 10,
                    paddingRight: 25,
                    paddingLeft: 25
                  }}
                >
                  <Wrapper
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Copy
                      style={{
                        color: colors.orange_color,
                        textAlign: "center",
                        fontSize: 12
                      }}
                    >
                      <Bold>Make sure to leave a voicemail</Bold>
                    </Copy>

                    <Copy
                      style={{
                        color: colors.white_color,
                        textAlign: "center",
                        fontSize: 12
                      }}
                    >
                      If they call you back on this number, it will be forwarded
                      to your callback number for the next 14 days. You're
                      calling from{" "}
                      <Bold>
                        {formatUsPhone(
                          this.props.active_call.from_phone_number
                        )}
                      </Bold>
                      .
                    </Copy>

                    <InlineButton
                      textColor={colors.white_text_color}
                      icon_color={colors.white_text_color}
                      hover_color={colors.hover_white_color}
                      textStyle={{
                        textAlign: "center",
                        fontSize: 12
                      }}
                      innerStyle={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 5,
                        paddingLeft: 5
                      }}
                      onPress={() => {
                        this.props.updateActiveCall({
                          ...this.props.active_call,
                          answered_by: "human"
                        });
                      }}
                    >
                      Nevermind, the contact picked up.
                    </InlineButton>
                  </Wrapper>
                </Row>
              ) : active_call?.call_status === "answered" &&
                current_conversation.length === 0 &&
                !this.props.active_dialer_contact
                  ?.answered_on_another_device ? (
                <Row style={{ justifyContent: "center", padding: 10 }}>
                  <Icon
                    icon={"phone-in-talk"}
                    size={14}
                    color={colors.success_color}
                    style={{ marginRight: 5 }}
                  />
                  <Copy
                    style={{
                      color: colors.success_color,
                      textAlign: "center",
                      fontSize: 12
                    }}
                  >
                    <Bold>Connected</Bold>
                  </Copy>
                </Row>
              ) : null}
            </>

            {this.props.active_call?.call_status === "call_ended" ? (
              <>
                {this.state.summarize_loading ? (
                  <SpinWrapper
                    text={"Saving Conversation"}
                    color={colors.white_text_color}
                  />
                ) : (
                  <>
                    {!!this.props.active_call?.note ? (
                      <Row
                        style={{
                          justifyContent: "flex-start",
                          marginRight: 5
                        }}
                      >
                        <Wrapper
                          style={{
                            marginRight: 5,
                            marginLeft: 5,
                            width: 30,
                            height: 30,
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <AlmaOrb active={false} size={18} />
                        </Wrapper>
                        <Wrapper
                          style={{
                            backgroundColor:
                              !!this.state.edit_note_text &&
                              this.state.edit_note_text !=
                                this.props.active_call?.note &&
                              this.state.edit_note
                                ? colors.orange_color_muted
                                : colors.purple_color_muted,
                            paddingBottom: 15,
                            borderRadius: 5,
                            borderBottomRightRadius: 5,
                            borderBottomLeftRadius: 0,
                            margin: 5,
                            marginRight: 40,
                            marginLeft: 0,
                            position: "relative",
                            flex: this.state.edit_note ? 1 : null
                          }}
                        >
                          {this.state.edit_note ? (
                            <>
                              <Row style={{ justifyContent: "space-between" }}>
                                <Wrapper
                                  style={{
                                    paddingRight: 15,
                                    paddingLeft: 15
                                  }}
                                >
                                  <Copy
                                    style={{
                                      fontSize: 12
                                    }}
                                  >
                                    <Bold>Edit Call Summary:</Bold>
                                  </Copy>
                                </Wrapper>
                                <Row>
                                  <InlineButton
                                    icon={"check"}
                                    disabled={
                                      !this.state.edit_note_text ||
                                      this.state.edit_note_text ==
                                        this.props.active_call?.note
                                    }
                                    onPress={() => {
                                      const new_note =
                                        this.state.edit_note_text;
                                      this.props.phoneCall({
                                        token: this.props.token,
                                        type: "update_call_note",
                                        edited_note: new_note,
                                        call_id:
                                          this.props.active_call?.call_id,
                                        onLoading: () => {
                                          this.setState(
                                            {
                                              edit_note: false,
                                              edit_note_text: null
                                            },
                                            () => {
                                              this.props.updateActiveCall({
                                                ...this.props.active_call,
                                                note: new_note
                                              });
                                            }
                                          );
                                        }
                                      });
                                    }}
                                  >
                                    Save Note
                                  </InlineButton>

                                  <IconButton
                                    icon={"close"}
                                    tooltip={"Cancel"}
                                    tooltipPlacement={"top"}
                                    onPress={() => {
                                      this.setState({
                                        edit_note: false,
                                        edit_note_text: null
                                      });
                                    }}
                                  />
                                </Row>
                              </Row>
                              <InputBox
                                autoFocus={true}
                                numberOfLines={10}
                                name="edit_note"
                                disabled={false}
                                returnKeyType="done"
                                placeholder={
                                  !!this.state.edit_note_text
                                    ? ""
                                    : "Enter your call summary here."
                                }
                                background_color={"transparent"}
                                onFocus={() => {}}
                                onChange={value => {
                                  this.setState({
                                    edit_note_text: value
                                  });
                                }}
                                onSubmitEditing={() => {}}
                                blurOnSubmit={true}
                                value={this.state.edit_note_text}
                                innerStyle={{
                                  padding: 0,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  margin: 0
                                }}
                                type="multiline"
                                autogrow={true}
                              />
                            </>
                          ) : (
                            <>
                              <Row style={{ justifyContent: "space-between" }}>
                                <Wrapper
                                  style={{
                                    paddingRight: 15,
                                    paddingLeft: 15
                                  }}
                                >
                                  <Copy
                                    style={{
                                      fontSize: 12
                                    }}
                                  >
                                    <Bold>Call Summary:</Bold>
                                  </Copy>
                                </Wrapper>
                                <InlineButton
                                  icon={
                                    this.props.device === "mobile"
                                      ? "edit"
                                      : "edit-note"
                                  }
                                  onPress={() => {
                                    this.setState(
                                      {
                                        edit_note: true,
                                        edit_note_text:
                                          this.props.active_call?.note
                                      },
                                      () => {}
                                    );
                                  }}
                                >
                                  Edit Note
                                </InlineButton>
                              </Row>
                              <Wrapper
                                style={{ paddingRight: 15, paddingLeft: 15 }}
                              >
                                <Copy>{this.props.active_call?.note}</Copy>
                              </Wrapper>
                            </>
                          )}
                        </Wrapper>
                      </Row>
                    ) : null}

                    {lead_status_type !== "acquisition_status" &&
                    lead_status_type !== "disposition_status" &&
                    lead_status_type !== "won_status" ? (
                      <>
                        <Row
                          style={{
                            justifyContent: "flex-start"
                          }}
                        >
                          <Wrapper
                            style={{
                              marginRight: 5,
                              marginLeft: 5,
                              width: 30,
                              height: 30,
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <AlmaOrb active={true} size={18} />
                          </Wrapper>

                          <Wrapper
                            style={{
                              backgroundColor: colors.purple_color_muted,
                              padding: 15,
                              paddingRight: 15,
                              paddingLeft: 15,
                              borderRadius: 5,
                              borderBottomRightRadius: 5,
                              borderBottomLeftRadius: 0,
                              margin: 5,
                              marginRight: 40,
                              marginLeft: 0
                            }}
                          >
                            <Copy>How was your call?</Copy>
                          </Wrapper>
                        </Row>

                        <Row
                          style={{
                            justifyContent: "flex-end",
                            marginRight: 5,
                            marginLeft: this.props.device === "mobile" ? 40 : 0
                          }}
                        >
                          <Row
                            style={{
                              justifyContent: "flex-end",
                              flexWrap: "wrap"
                            }}
                          >
                            <CallResultOption
                              title={"No Answer"}
                              tooltip={"No one answered the call."}
                              slug={"no_answer"}
                              onPress={() => {
                                this.updateCallResults("no_answer");
                              }}
                              active_call={active_call}
                              call_results_loading={
                                this.state.call_results_loading
                              }
                            />
                            <CallResultOption
                              title={"Left Voicemail"}
                              slug={"left_voicemail"}
                              tooltip={
                                "No one answered the call and we left a voicemail."
                              }
                              onPress={() => {
                                this.updateCallResults("left_voicemail");
                              }}
                              active_call={active_call}
                              call_results_loading={
                                this.state.call_results_loading
                              }
                            />
                            <CallResultOption
                              title={"Disconnected"}
                              slug={"disconnected"}
                              tooltip={
                                "The call could not be completed because the number is bad or disconnected. No follow-up will be attempted."
                              }
                              onPress={() => {
                                this.updateCallResults("disconnected");
                              }}
                              active_call={active_call}
                              call_results_loading={
                                this.state.call_results_loading
                              }
                            />
                            {this.props.active_call?.answered_by ===
                            "machine" ? (
                              <InlineButton
                                textColor={colors.white_text_color}
                                icon_color={colors.white_text_color}
                                hover_color={colors.hover_white_color}
                                textStyle={{ fontSize: 12 }}
                                innerStyle={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  paddingRight: 5,
                                  paddingLeft: 5
                                }}
                                style={{ margin: 0 }}
                                onPress={() => {
                                  this.props.updateActiveCall({
                                    ...this.props.active_call,
                                    answered_by: "human"
                                  });
                                }}
                              >
                                Show More
                              </InlineButton>
                            ) : null}
                            {this.props.active_call?.answered_by !==
                            "machine" ? (
                              <>
                                <CallResultOption
                                  title={"Wrong Number"}
                                  slug={"wrong_number"}
                                  tooltip={
                                    "The contact told us they were not the contact we we're looking for."
                                  }
                                  onPress={() => {
                                    this.updateCallResults("wrong_number");
                                  }}
                                  active_call={active_call}
                                  call_results_loading={
                                    this.state.call_results_loading
                                  }
                                />
                                <CallResultOption
                                  title={"Wrong Contact"}
                                  slug={"wrong_contact"}
                                  tooltip={
                                    "The contact confirmed their identity but told us they were not associated with the property."
                                  }
                                  onPress={() => {
                                    this.updateCallResults("wrong_contact");
                                  }}
                                  active_call={active_call}
                                  call_results_loading={
                                    this.state.call_results_loading
                                  }
                                />
                                <CallResultOption
                                  title={"Not Interested"}
                                  slug={"not_interested"}
                                  tooltip={
                                    "The contact answered and expressed they were not interested in our services. A follow will be scheduled for much later in case their situation changes."
                                  }
                                  onPress={() => {
                                    this.updateCallResults("not_interested");
                                    this.scrollToBottom();
                                  }}
                                  active_call={active_call}
                                  call_results_loading={
                                    this.state.call_results_loading
                                  }
                                />
                                <CallResultOption
                                  title={"Unqualified"}
                                  slug={"unqualified"}
                                  tooltip={
                                    "The contact answered and did not meet our qualifications to move forward. This lead will be removed from future queues."
                                  }
                                  onPress={() => {
                                    this.updateCallResults("unqualified");
                                    this.scrollToBottom();
                                  }}
                                  active_call={active_call}
                                  call_results_loading={
                                    this.state.call_results_loading
                                  }
                                />
                                <CallResultOption
                                  title={"Warm Lead"}
                                  slug={"warm_lead"}
                                  tooltip={
                                    "The contact answered and the conversation merits a follow up."
                                  }
                                  onPress={() => {
                                    this.updateCallResults("warm_lead");
                                  }}
                                  active_call={active_call}
                                  call_results_loading={
                                    this.state.call_results_loading
                                  }
                                />
                                <CallResultOption
                                  title={"Hot Lead"}
                                  slug={"hot_lead"}
                                  tooltip={
                                    "The contact answered and expressed interest in our services. A follow up is still needed."
                                  }
                                  onPress={() => {
                                    this.updateCallResults("hot_lead");
                                  }}
                                  active_call={active_call}
                                  call_results_loading={
                                    this.state.call_results_loading
                                  }
                                />

                                <CallResultOption
                                  title={"Appointment Set"}
                                  slug={"appointment_set"}
                                  tooltip={
                                    "The contact answered and an appointment was set to talk more. This lead will be removed from future queues."
                                  }
                                  onPress={() => {
                                    this.updateCallResults("appointment_set");
                                  }}
                                  active_call={active_call}
                                  call_results_loading={
                                    this.state.call_results_loading
                                  }
                                />
                              </>
                            ) : null}
                          </Row>

                          <ProfilePic
                            size={30}
                            firstname={this.props.user?.firstname}
                            lastname={this.props.user?.lastname}
                            email={this.props.user?.email}
                            image={this.props.user?.image}
                            background_color={colors.dark_phone_inner_color}
                            fontColor={colors.white_text_color}
                            style={{ marginRight: 5, marginLeft: 5 }}
                          />
                        </Row>

                        {(active_call?.call_results === "not_interested" ||
                          active_call?.call_results === "unqualified") &&
                        this.props.active_dialer_contact?.associated_lead
                          ?.deal &&
                        this.props.active_dialer_contact?.associated_lead?.deal
                          ?.approved == 1 ? (
                          <>
                            <Row
                              style={{
                                justifyContent: "flex-start",
                                marginRight: 5
                              }}
                            >
                              <Wrapper
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  width: 30,
                                  height: 30,
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}
                              >
                                <AlmaOrb active={true} size={18} />
                              </Wrapper>

                              <Wrapper
                                style={{
                                  backgroundColor: colors.purple_color_muted,
                                  padding: 15,
                                  paddingRight: 15,
                                  paddingLeft: 15,
                                  borderRadius: 5,
                                  borderBottomRightRadius: 5,
                                  borderBottomLeftRadius: 0,
                                  margin: 5,
                                  marginRight: 40,
                                  marginLeft: 0
                                }}
                              >
                                <Copy>
                                  You are currently sending mail to this
                                  property's owner. Would you like to stop
                                  sending mail?{" "}
                                </Copy>
                              </Wrapper>
                            </Row>

                            <Row
                              style={{
                                justifyContent: "flex-end",
                                flexWrap: "wrap",
                                marginLeft: 40
                              }}
                            >
                              <InlineButton
                                buttonContainerStyle={{ alignSelf: "stretch" }}
                                style={{
                                  borderWidth: 1,
                                  borderRadius: 15,
                                  borderBottomRightRadius: 0,
                                  maxWidth: 500,
                                  borderColor: colors.active_color,
                                  borderStyle: "solid",
                                  backgroundColor: !active_call?.stop_mail
                                    ? colors.active_color
                                    : "transparent"
                                }}
                                textColor={
                                  !active_call?.stop_mail
                                    ? colors.white_text_color
                                    : colors.active_color
                                }
                                selected={!active_call?.stop_mail}
                                onPress={() => {}}
                                disabled={active_call?.stop_mail}
                              >
                                Keep Sending Mail
                              </InlineButton>

                              <InlineButton
                                buttonContainerStyle={{ alignSelf: "stretch" }}
                                style={{
                                  borderWidth: 1,
                                  borderRadius: 15,
                                  borderBottomRightRadius: 0,
                                  maxWidth: 500,
                                  borderColor: colors.active_color,
                                  borderStyle: "solid",
                                  backgroundColor: active_call?.stop_mail
                                    ? colors.active_color
                                    : "transparent"
                                }}
                                textColor={
                                  active_call?.stop_mail
                                    ? colors.white_text_color
                                    : colors.active_color
                                }
                                selected={active_call?.stop_mail}
                                onPress={() => {
                                  this.props.updateLead({
                                    token: this.props.token,
                                    no_loading: true,
                                    deal_ids:
                                      this.props.active_dialer_contact
                                        ?.associated_lead?.deal?.id,
                                    type: "complete_campaign_from_dialer",
                                    call_results: active_call?.call_results,
                                    onLoading: () => {
                                      this.props.updateActiveCall({
                                        ...this.props.active_call,
                                        stop_mail: true
                                      });
                                      this.scrollToBottom();
                                    },
                                    onError: () => {},
                                    onSuccess: results => {}
                                  });
                                }}
                                disabled={active_call?.stop_mail}
                              >
                                Stop Sending Mail
                              </InlineButton>
                            </Row>
                          </>
                        ) : null}

                        {active_call?.stop_mail ? (
                          <Row
                            style={{
                              justifyContent: "flex-start"
                            }}
                          >
                            <Wrapper
                              style={{
                                marginRight: 5,
                                marginLeft: 5,
                                width: 30,
                                height: 30,
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <AlmaOrb active={true} size={18} />
                            </Wrapper>

                            <Wrapper
                              style={{
                                backgroundColor: colors.purple_color_muted,
                                padding: 15,
                                paddingRight: 15,
                                paddingLeft: 15,
                                borderRadius: 5,
                                borderBottomRightRadius: 5,
                                borderBottomLeftRadius: 0,
                                margin: 5,
                                marginRight: 40,
                                marginLeft: 0
                              }}
                            >
                              <Copy>
                                Ok! We stopped sending mail to this property.
                              </Copy>
                            </Wrapper>
                          </Row>
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}
              </>
            ) : null}
            <Wrapper style={{ height: 25 }} />
          </Scroll>
          <ActionButtons
            {...this.props}
            onboarding={this.props.onboarding}
            demo_call={this.props.demo_call}
            checkIfUserTalked={this.checkIfUserTalked}
            suggested_message_loading={this.state.suggested_message_loading}
            suggested_message={this.state.suggested_message}
            summarize_loading={this.state.summarize_loading}
            last_suggestion_id={this.state.last_suggestion_id}
            suggestMessage={this.suggestMessage}
            generated_voicemail_loading={this.state.generated_voicemail_loading}
            generated_voicemail={this.state.generated_voicemail}
            generated_voicemail_text={this.state.generated_voicemail_text}
            generated_voicemail_text_loading={
              this.state.generated_voicemail_text_loading
            }
            has_voice_clone={this.state.has_voice_clone}
            nextContactInQueue={this.nextContactInQueue}
            nextNumberInQueue={this.nextNumberInQueue}
            nextLeadInQueue={this.nextLeadInQueue}
            countdown={this.props.countdown}
            clearCountdown={this.props.clearCountdown}
            call_canceled={this.props.call_canceled}
            telnyx_client={this.props.telnyx_client}
            resetTelnyxDevice={this.props.resetTelnyxDevice}
          />
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, dialer, native }) => {
  const { token, user } = auth;
  const { device, desktopMobile } = native;
  const { dark_mode, colors } = settings;
  const {
    active_dialer_contact,
    active_call,
    current_conversation,
    queued_numbers,
    called_numbers,
    current_call_session
  } = dialer;

  return {
    token,
    user,
    dark_mode,
    device,
    desktopMobile,
    colors,
    active_dialer_contact,
    active_call,
    current_conversation,
    current_call_session,
    queued_numbers,
    called_numbers
  };
};

export default connect(mapStateToProps, {
  dialerConversationCleanUp,
  updateDialerConversationMessage,
  updateDialerConversation,
  removeDialerConversationMessage,
  dialerConversationPredict,
  pushSidePanel,
  replaceSidePanel,
  updateActiveCall,
  dialerConversationSummarize,
  phoneCall,
  getCleanAIData,
  setDialerConversation,
  updateVoiceClone,
  updateLead,
  setActiveDialerContact,
  updateCalledNumbers,
  updateCallSession,
  getContactCallHistory
})(CurrentConversation);
