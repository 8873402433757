import React, { Component } from "react";
import { connect } from "react-redux";
import {
  formatAddress,
  getMorePropertyInfo,
  numberWithCommas,
  pushSidePanel,
  checkIfUserHasMetadata
} from "app/NativeActions";
import {
  Bold,
  Copy,
  Row,
  Title,
  Wrapper,
  Card,
  Icon
} from "app/NativeComponents/common";
import {
  InlineButton,
  SelectItem,
  SpinWrapper,
  AttentionBox
} from "app/NativeComponents/snippets";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";

class OwnerPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owner_properties: [],
      show_portfolio_properties: false,
      more_owner_info_loading: false,
      other_owner_properties: [],
      portfolio_value: 0,
      total_equity: 0,
      total_mortgage_balance: 0,
      total_properties_owned: 0,
      includes_tiebreak: false,
      total_tiebreak: 0,
      offset: 0,
      height: 0
    };
    this.getMoreOwnerInfo = this.getMoreOwnerInfo.bind(this);
  }

  componentDidMount() {
    this.getMoreOwnerInfo();
  }
  componentWillUnmount() {
    clearInterval(this._interval);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (prevProps?.owner_hash &&
        prevProps?.owner_hash !== this.props?.owner_hash) ||
      (prevProps?.contact_id &&
        prevProps?.contact_id !== this.props?.contact_id)
    ) {
      this.getMoreOwnerInfo();
    }
  }

  getMoreOwnerInfo() {
    const { owner_hash, contact_id } = this.props;

    if (contact_id) {
      this.setState(
        {
          other_owner_properties: [],
          portfolio_value: 0,
          total_equity: 0,
          total_mortgage_balance: 0,
          total_properties_owned: 0,
          more_owner_info_loading: true,
          show_more_owner_info: false,
          includes_tiebreak: false,
          total_tiebreak: 0
        },
        () => {
          this.props.getMorePropertyInfo({
            token: this.props.token,
            type: "portfolio_from_contact_id",
            contact_id,
            onLoading: () => {
              this.setState({ more_owner_info_loading: true });
            },
            onError: () => {
              this.setState({ more_owner_info_loading: false });
            },
            onSuccess: results => {
              this.setState({
                other_owner_properties: results?.other_owner_properties,
                portfolio_value: results?.portfolio_value,
                total_equity: results?.total_equity,
                total_mortgage_balance: results?.total_mortgage_balance,
                total_properties_owned: results?.total_properties_owned,
                more_owner_info_loading: false,
                includes_tiebreak: results?.includes_tiebreak,
                total_tiebreak: results?.total_tiebreak
              });
            }
          });
        }
      );
    } else if (!!owner_hash) {
      this.setState(
        {
          other_owner_properties: [],
          portfolio_value: 0,
          total_equity: 0,
          total_mortgage_balance: 0,
          total_properties_owned: 0,
          more_owner_info_loading: true,
          show_more_owner_info: false,
          includes_tiebreak: false,
          total_tiebreak: 0
        },
        () => {
          this.props.getMorePropertyInfo({
            token: this.props.token,
            type: "owner_properties_info",
            owner_hash: owner_hash,
            onLoading: () => {
              this.setState({ more_owner_info_loading: true });
            },
            onError: () => {
              this.setState({ more_owner_info_loading: false });
            },
            onSuccess: results => {
              this.setState({
                other_owner_properties: results?.other_owner_properties,
                portfolio_value: results?.portfolio_value,
                total_equity: results?.total_equity,
                total_mortgage_balance: results?.total_mortgage_balance,
                total_properties_owned: results?.total_properties_owned,
                more_owner_info_loading: false,
                includes_tiebreak: results?.includes_tiebreak,
                total_tiebreak: results?.total_tiebreak
              });
            }
          });
        }
      );
    }
  }

  render() {
    const { isMobile, device, colors, owner_hash, contact_id } = this.props;
    if (this.state.more_owner_info_loading) {
      return <SpinWrapper text={"Loading Portfolio"} />;
    } else if (
      (owner_hash || contact_id) &&
      !this.state.more_owner_info_loading &&
      this.state.other_owner_properties?.length > 0
    ) {
      return (
        <>
          <Card
            style={{ paddingTop: 10, marginTop: 15 }}
            label={"Real Estate Portfolio"}
            id={"real-estate-portfolio"}
            onLayout={event => {
              const { x, y, width, height } = event?.nativeEvent?.layout;
              this.setState({
                offset: y,
                height: height
              });
            }}
          >
            <Wrapper style={{ marginTop: 10 }}>
              <Row>
                <Wrapper
                  style={{
                    padding: 15,
                    paddingRight: 25,
                    paddingLeft: 25,
                    flex: 1
                  }}
                >
                  <Copy
                    style={{
                      fontSize: 12
                    }}
                  >
                    <Bold>Total properties owned:</Bold>
                  </Copy>
                  <Title style={{ color: colors.actionable_text_color }}>
                    <Bold>
                      {numberWithCommas(this.state.total_properties_owned)}
                    </Bold>
                  </Title>
                </Wrapper>

                <Wrapper
                  style={{
                    padding: 15,
                    paddingRight: 25,
                    paddingLeft: 25,
                    flex: 1
                  }}
                >
                  <Copy
                    style={{
                      fontSize: 12
                    }}
                  >
                    <Bold>Portfolio value:</Bold>
                  </Copy>
                  <Title style={{ color: colors.actionable_text_color }}>
                    <Bold>
                      {!!this.state.portfolio_value
                        ? "$" + numberWithCommas(this.state.portfolio_value)
                        : "--"}
                    </Bold>
                  </Title>
                </Wrapper>
              </Row>
              <Row>
                <Wrapper
                  style={{
                    padding: 15,
                    paddingRight: 25,
                    paddingLeft: 25,
                    flex: 1
                  }}
                >
                  <Copy
                    style={{
                      fontSize: 12
                    }}
                  >
                    <Bold>Total mortgage balance:</Bold>
                  </Copy>
                  <Title style={{ color: colors.actionable_text_color }}>
                    <Bold>
                      {!!this.state.total_mortgage_balance
                        ? "$" +
                          numberWithCommas(this.state.total_mortgage_balance)
                        : "--"}
                    </Bold>
                  </Title>
                </Wrapper>
                <Wrapper
                  style={{
                    padding: 15,
                    paddingRight: 25,
                    paddingLeft: 25,
                    flex: 1
                  }}
                >
                  <Copy
                    style={{
                      fontSize: 12
                    }}
                  >
                    <Bold>Total equity:</Bold>
                  </Copy>
                  <Title style={{ color: colors.actionable_text_color }}>
                    <Bold>
                      {!!this.state.total_equity
                        ? "$" + numberWithCommas(this.state.total_equity)
                        : "--"}
                    </Bold>
                  </Title>
                </Wrapper>
              </Row>
            </Wrapper>

            {this.state.includes_tiebreak ? (
              <AttentionBox
                renderDescription={() => {
                  return (
                    <Row>
                      <Icon
                        icon="warning"
                        icon_color={colors.orange_color}
                        style={{ marginRight: 25 }}
                      />
                      <Wrapper style={{ flex: 1 }}>
                        <Copy>
                          {this.state.total_tiebreak === 1
                            ? "This portfolio includes properties that are potentially linked to this owner. There is 1 property that are potentially matched."
                            : "This portfolio includes properties that are potentially linked to this owner. There are " +
                              numberWithCommas(this.state.total_tiebreak) +
                              " properties that are potentially matched."}
                        </Copy>
                      </Wrapper>
                    </Row>
                  );
                }}
              />
            ) : null}

            {this.state.show_portfolio_properties ? (
              <>
                {this.state.other_owner_properties.map((item, i) => {
                  const formatted_address = formatAddress({
                    address: {
                      address: item.property_address,
                      address2: item.property_address2,
                      address_city: item.property_address_city,
                      address_state: item.property_address_state,
                      address_zip: item.property_address_zip,
                      latitude: item?.location?.latitude,
                      longitude: item?.location?.longitude
                    }
                  });
                  let description = "";

                  if (!!item?.EstimatedValue) {
                    description +=
                      "Est. Value: $" + numberWithCommas(item?.EstimatedValue);
                  } else {
                    description += "Est. Value: --";
                  }

                  if (!!item?.EstimatedEquity) {
                    description +=
                      " | Est. Equity: $" +
                      numberWithCommas(item?.EstimatedEquity);
                  } else {
                    description += " | Est. Equity: --";
                  }

                  return (
                    <SelectItem
                      inBottomSheet={false}
                      key={"lead_" + i}
                      label={""}
                      description={description}
                      select_type="none"
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "property",
                          id: item?.property_id,
                          focus_side_panel:
                            this.props.user?.user_settings
                              ?.property_expanded === "not_expanded"
                              ? false
                              : true,
                          overlay: true,
                          data: {
                            property: item
                          }
                        });
                      }}
                      renderLeft={() => {
                        return (
                          <Wrapper
                            style={{
                              padding: 15,
                              paddingLeft: 25,
                              paddingRight: 0
                            }}
                          >
                            <LeadMainPhoto
                              property={item}
                              height={50}
                              style={{
                                width: 50,
                                margin: 0
                              }}
                            />
                          </Wrapper>
                        );
                      }}
                      icon={"keyboard-arrow-right"}
                      description={description}
                    >
                      {formatted_address.line1 + " " + formatted_address.line2}
                    </SelectItem>
                  );
                })}

                <InlineButton
                  onPress={() => {
                    this.setState(
                      {
                        show_portfolio_properties: false
                      },
                      () => {
                        this._interval = setTimeout(() => {
                          this.props.scrollToSection({
                            id: "real-estate-portfolio",
                            offset: this.state.offset,
                            height: this.state.height
                          });
                        }, 50);
                      }
                    );
                  }}
                  button_type={"full"}
                  icon={"keyboard-arrow-up"}
                >
                  Hide Portfolio
                </InlineButton>
              </>
            ) : (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      show_portfolio_properties: true
                    },
                    () => {
                      this._interval = setTimeout(() => {
                        this.props.scrollToSection({
                          id: "real-estate-portfolio",
                          offset: this.state.offset,
                          height: this.state.height
                        });
                      }, 50);
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-down"}
              >
                View Portfolio ({this.state.total_properties_owned})
              </InlineButton>
            )}
          </Card>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, { getMorePropertyInfo, pushSidePanel })(
  OwnerPortfolio
);
