import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Copy,
  Row,
  Button,
  Bold,
  Spin
} from "app/NativeComponents/common";
import {
  GhostButton,
  UpsellButton,
  IconButton
} from "app/NativeComponents/snippets";
import {
  checkIfUserHasMetadata,
  pushSidePanel,
  setActiveDialerContact
} from "app/NativeActions";
import { store } from "app/store";

class UnlockButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const {
      property,
      phone,
      contact,
      row_height,
      toggle_lead_images,
      user,
      is_current_user_contact
    } = this.props;
    const { loading } = this.state;

    const colors = store.getState().settings.colors;

    return !checkIfUserHasMetadata("paid_plan") &&
      user?.team_clearance_level > 0 &&
      !property?.deal?.updating?.is_updating &&
      !is_current_user_contact ? (
      <Wrapper
        className="deal-preview"
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          width: toggle_lead_images ? "100%" : "auto",
          margin: toggle_lead_images ? 0 : 10,
          height: toggle_lead_images ? "100%" : row_height,
          alignItems: "flex-end",
          justifyContent: "center"
        }}
      >
        <Row>
          <GhostButton
            icon={"call"}
            button_type={"small"}
            loading={loading}
            style={{
              margin: 0,
              marginRight: 5,
              width: 28,
              height: 28
            }}
            backgroundColor={colors.card_color}
            onPress={() => {
              this.props.setActiveDialerContact({
                contact: {
                  ...contact,
                  selected_phone: {
                    number: phone?.phone_number_formatted,
                    type: phone?.type,
                    do_not_call_flag: phone?.do_not_call,
                    owner: phone?.carrier
                  },
                  associated_lead: property,
                  one_off_call: true,
                  demo_call: true
                }
              });
            }}
            primary={false}
            tooltip={"Demo Call"}
            tooltipPlacement={"top"}
            iconStyle={{
              margin: 0
            }}
            innerStyle={{
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10
            }}
          ></GhostButton>
          <GhostButton
            loading={loading}
            onPress={() => {
              this.props.pushSidePanel({
                slug: "purchase_plan_prompt",
                overlay_modal: true,
                locked: true,
                data: {
                  title: "Get Unlimited Phone Numbers With DealMachine Pro.",
                  dismiss_button:
                    "No thanks. Let me continue without phone numbers.",
                  no_free: true,
                  event_slug: "in_app_reveal_contact_info"
                },
                locked: true
              });
            }}
            primary={true}
            style={{
              margin: 0
            }}
            innerStyle={{
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10
            }}
          >
            Reveal
          </GhostButton>
        </Row>
      </Wrapper>
    ) : null;
  }
}

const mapStateToProps = ({ auth, settings, filter, dialer }) => {
  const { token, user } = auth;
  const { dark_mode } = settings;
  const { toggle_lead_images } = filter;
  const { current_call_session } = dialer;
  return {
    token,
    user,
    dark_mode,
    toggle_lead_images,
    current_call_session
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  setActiveDialerContact
})(UnlockButton);
