import React, { Component } from "react";
import { connect } from "react-redux";

import PayloadBody from "./PayloadBody";
import { IAPWrapper } from "app/NativeComponents/snippets";

import { logout, startRedirect, manageSubscription } from "app/NativeActions";
class SelectTrialOnboarding extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/select-a-plan");
    }
  }

  render() {
    if (this.props.platform == "ios" && this.props.signup_funnel == "use_iap") {
      return (
        <IAPWrapper
          colors={this.props.colors}
          PayloadBody={props => {
            return (
              <PayloadBody
                {...props}
                contentful_slug={"select_a_plan_iap"}
                token={this.props.token}
                colors={this.props.colors}
                platform={this.props.platform}
                device={this.props.device}
                signup_funnel={this.props.signup_funnel}
                qualified_lead={this.props.qualified_lead}
                needs_lists={this.props.needs_lists}
                needs_list={this.props.needs_list}
                desktopMobile={this.props.desktopMobile}
                startRedirect={this.props.startRedirect}
                visitor={this.props.visitor}
                manageSubscription={this.props.manageSubscription}
              />
            );
          }}
        />
      );
    }

    return (
      <PayloadBody
        {...this.props}
        token={this.props.token}
        colors={this.props.colors}
        platform={this.props.platform}
        device={this.props.device}
        signup_funnel={this.props.signup_funnel}
        qualified_lead={this.props.qualified_lead}
        needs_lists={this.props.needs_lists}
        needs_list={this.props.needs_list}
        desktopMobile={this.props.desktopMobile}
        startRedirect={this.props.startRedirect}
        visitor={this.props.visitor}
        user={this.props.user}
        manageSubscription={this.props.manageSubscription}
        event_slug={
          this.props.visitor?.marketing_experiments?.dealmachine_free ===
          "dealmachine_free"
            ? "onboarding_select_plan"
            : ""
        }
      />
    );
  }
}

const mapStateToProps = ({ auth, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { device, platform, initialRedirect, desktopMobile } = native;
  const { visitor, signup_funnel, user_info } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    device,
    platform,
    visitor,
    initialRedirect,
    signup_funnel,
    desktopMobile,
    user_info
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  manageSubscription
})(SelectTrialOnboarding);
