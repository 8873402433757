import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Row,
  Wrapper,
  Copy,
  Card
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  InlineButton,
  InputBox,
  NewHeader,
  SelectBox,
  SmallLabel,
  ConfirmLabel,
  InlineTabs,
  UpsellButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  getMailerTemplates,
  getMailerCampaigns,
  updateUser,
  checkIfUserHasSubscription,
  checkIfUserHasMetadata
} from "app/NativeActions";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

class AppSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      default_type:
        props.user?.default_campaign_id > 0 ? "mail_sequence" : "design",
      default_campaign_id: props.user?.default_campaign_id
        ? props.user.default_campaign_id
        : null,
      default_mail_template: props.user?.team_default_template
        ? props.user.team_default_template
        : null,
      default_mail_noimage_template: props.user?.team_default_template_noimage
        ? props.user.team_default_template_noimage
        : null,
      resend_freq_toggle: props.user?.default_resend_freq == 0 ? false : true,
      resend_freq: props.user?.default_resend_freq
        ? props.user.default_resend_freq
        : 21,
      resend_limit_toggle: props.user?.default_resend_limit == 0 ? true : false,
      resend_limit: props.user?.default_resend_limit
        ? props.user.default_resend_limit
        : 0,
      mail_sequence_focus: false,
      template_focus: false,
      template_noimage_focus: false,
      saving: false
    };

    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getTemplates();
    this.getCampaigns();
  }

  getTemplates() {
    this.props.getMailerTemplates({
      token: this.props.token,
      load_all: true
    });
  }

  getCampaigns() {
    this.props.getMailerCampaigns({
      token: this.props.token,
      load_all: true,
      exclude_repeating_mail: true
    });
  }

  handleBack() {
    this.props.popSidePanel();
  }

  checkIfNeedsToSave() {
    if (
      (this.state.default_type == "design" &&
        this.state.default_mail_template &&
        (this.state.resend_freq != this.props.user?.default_resend_freq ||
          this.state.resend_limit != this.props.user?.default_resend_limit ||
          this.state.default_mail_template?.id !=
            this.props.user?.team_default_template?.id ||
          (this.state.default_mail_noimage_template?.id !=
            this.props.user?.team_default_template_noimage?.id &&
            this.state.default_mail_noimage_template))) ||
      (this.state.default_type == "mail_sequence" &&
        this.state.default_campaign_id &&
        this.state.default_campaign_id != this.props.user?.default_campaign_id)
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { colors } = this.props;
    const {
      resend_freq_toggle,
      resend_limit_toggle,
      resend_limit,
      resend_freq,
      default_campaign_id,
      default_mail_template,
      default_mail_noimage_template
    } = this.state;
    return (
      <Container>
        <NewHeader
          title="Default Mail Settings"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        {this.checkIfNeedsToSave() ? (
          <ConfirmLabel
            style={{
              alignSelf: "stretch"
            }}
            icon="settings"
            label={"Options Changes"}
            confirmButton={"Save"}
            loading={this.state.saving}
            primary={true}
            disabled={!this.checkIfNeedsToSave()}
            onPress={() => {
              this.setState({ saving: true });

              this.props.updateUser({
                token: this.props.token,
                type: "default_mailing_options",
                payload: {
                  default_campaign_id:
                    this.state.default_type == "design"
                      ? 0
                      : this.state.default_campaign_id,
                  default_template_id: this.state.default_mail_template?.id,
                  default_template_id_noimage:
                    this.state.default_mail_noimage_template?.id,
                  default_resend_freq:
                    this.state.resend_freq > 0 ? this.state.resend_freq : 0,
                  default_resend_limit:
                    this.state.resend_freq > 0 ? this.state.resend_limit : 0
                },
                onLoading: () => {
                  this.setState({
                    saving: true
                  });
                },
                onError: () => {
                  this.setState({
                    saving: false
                  });
                },
                onSuccess: () => {
                  this.setState(
                    {
                      saving: false
                    },
                    () => {
                      this.handleBack();
                    }
                  );
                }
              });
            }}
          />
        ) : null}

        <Row style={{ justifyContent: "space-between", paddingBottom: 25 }}>
          <Wrapper style={{ flex: 1 }}>
            <SmallLabel>
              Select the default way you want to send mail:
            </SmallLabel>
          </Wrapper>
          <InlineTabs
            selected_tab={this.state.default_type}
            tabs={[
              {
                onPress: () => {
                  this.setState({
                    default_type: "design",
                    default_campaign_id: null
                  });
                },
                slug: "design",
                title: "Repeating Mail"
              },
              {
                onPress: () => {
                  this.setState({
                    default_type: "mail_sequence"
                  });
                },
                slug: "mail_sequence",
                title: "Mail Sequence",

                renderComponent: ({ key, tab, selected }) => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    return (
                      <InlineButton
                        icon={"lock"}
                        selected={selected}
                        disabled={tab?.disabled}
                        pressed_in_background_color={
                          selected
                            ? colors.active_color_muted
                            : colors.hover_color
                        }
                        tooltip={tab?.tooltip}
                        tooltipPlacement={tab?.tooltipPlacement}
                        style={selected ? {} : {}}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "purchase_plan_prompt",
                            overlay_modal: true,
                            data: {
                              title: "Get Mail Sequences With DealMachine Pro.",
                              dismiss_button:
                                "No thanks. Let me continue without mail sequences.",
                              no_free: true,
                              event_slug: "in_app_mail_sequences"
                            },
                            locked: true
                          });
                        }}
                      >
                        {tab?.title}
                      </InlineButton>
                    );
                  }

                  return (
                    <UpsellButton
                      key={key}
                      meta_slug="advanced_mail_sequences"
                      onPress={tab.onPress}
                      renderLockedChildren={({ hovering }) => {
                        return (
                          <InlineButton
                            icon={"lock"}
                            selected={selected}
                            noPress={true}
                            pressedIn={selected || hovering}
                            disabled={tab?.disabled}
                            pressed_in_background_color={
                              selected
                                ? colors.active_color_muted
                                : colors.hover_color
                            }
                            tooltip={tab?.tooltip}
                            tooltipPlacement={tab?.tooltipPlacement}
                            style={selected ? {} : {}}
                          >
                            {tab?.title}
                          </InlineButton>
                        );
                      }}
                      renderChildren={({ hovering }) => {
                        return (
                          <InlineButton
                            icon={""}
                            selected={selected}
                            noPress={true}
                            pressedIn={selected || hovering}
                            disabled={tab?.disabled}
                            pressed_in_background_color={
                              selected
                                ? colors.active_color_muted
                                : colors.hover_color
                            }
                            tooltip={tab?.tooltip}
                            tooltipPlacement={tab?.tooltipPlacement}
                            style={selected ? {} : {}}
                          >
                            {tab?.title}
                          </InlineButton>
                        );
                      }}
                    />
                  );
                }
              }
            ]}
          />
        </Row>

        {this.state.default_type == "mail_sequence" ? (
          <>
            <KeyboardView style={{ flex: 1 }}>
              <SelectBox
                select_ref={this._template}
                items={this.props.mailer_campaigns}
                value={default_campaign_id || "--"}
                placeholder="Default mail sequence:"
                show={this.state.mail_sequence_focus}
                onFocus={() => {
                  this.setState({ mail_sequence_focus: true });
                }}
                onBlur={() => {
                  this.setState({ mail_sequence_focus: false });
                }}
                onSelect={item => {
                  this.setState({
                    default_campaign_id: item
                  });
                }}
                onSubmitEditing={() => {}}
              />
            </KeyboardView>
          </>
        ) : (
          <>
            <KeyboardView style={{ flex: 1 }}>
              {default_mail_template ? (
                <Wrapper
                  style={{
                    margin: 5,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <MailerFlipCard
                    mailer={default_mail_template}
                    scale={
                      default_mail_template.template_type == "postcard6x11"
                        ? 0.2
                        : default_mail_template.template_type == "postcard6x9"
                        ? 0.25
                        : 0.3
                    }
                    back_scale={
                      default_mail_template?.template_type == "postcard6x11"
                        ? 0.2
                        : default_mail_template.template_type == "postcard6x9"
                        ? 0.25
                        : 0.3
                    }
                  />
                </Wrapper>
              ) : null}

              <SelectBox
                select_ref={this._template}
                items={this.props.mailer_templates}
                value={default_mail_template?.id || "--"}
                placeholder="Default mail design:"
                show={this.state.template_focus}
                onFocus={() => {
                  this.setState({ template_focus: true });
                }}
                onBlur={() => {
                  this.setState({ template_focus: false });
                }}
                onSelect={item => {
                  let mailer = null;

                  for (let i = 0; i < this.props.mailer_templates.length; i++) {
                    if (this.props.mailer_templates[i].id == item) {
                      mailer = this.props.mailer_templates[i];
                    }
                  }
                  if (mailer) {
                    this.setState({
                      default_mail_template: mailer
                    });
                  }
                }}
                onSubmitEditing={() => {}}
              />

              {resend_freq_toggle ? (
                <>
                  <InputBox
                    style={{ flex: 1 }}
                    ref="resend_freq"
                    name="resend_freq"
                    blurOnSubmit={true}
                    autoCapitalize="none"
                    returnKeyType={"done"}
                    keyboardType={"default"}
                    placeholder="Repeat mail every (in days)"
                    onChange={value => {
                      this.setState({
                        resend_freq: value
                      });
                    }}
                    value={resend_freq.toString()}
                    onSubmitEditing={() => {}}
                    type={"number"}
                  />
                </>
              ) : null}

              {resend_freq_toggle && !resend_limit_toggle ? (
                <>
                  <InputBox
                    style={{ flex: 1 }}
                    ref="resend_limit"
                    name="resend_limit"
                    blurOnSubmit={true}
                    autoCapitalize="none"
                    returnKeyType={"done"}
                    keyboardType={"default"}
                    placeholder="Total number of times to send"
                    onChange={value => {
                      this.setState({
                        resend_limit: value
                      });
                    }}
                    value={resend_limit.toString()}
                    onSubmitEditing={() => {}}
                    type={"number"}
                  />
                </>
              ) : null}

              <Card>
                <SmallLabel>
                  Default template for leads with no available image.
                </SmallLabel>
                <Wrapper
                  style={{ padding: 25, paddingTop: 10, paddingBottom: 10 }}
                >
                  <Copy style={{ fontSize: 12 }}>
                    If a lead has no image, the template below will be used as
                    default mail when you press "Start Mail." This will not work
                    with leads using Mail Sequences.
                  </Copy>
                </Wrapper>
                {default_mail_noimage_template ? (
                  <Wrapper
                    style={{
                      margin: 5,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <MailerFlipCard
                      mailer={default_mail_noimage_template}
                      scale={
                        default_mail_noimage_template.template_type ==
                        "postcard6x11"
                          ? 0.2
                          : default_mail_noimage_template.template_type ==
                            "postcard6x9"
                          ? 0.25
                          : 0.3
                      }
                      back_scale={
                        default_mail_noimage_template?.template_type ==
                        "postcard6x11"
                          ? 0.2
                          : default_mail_noimage_template.template_type ==
                            "postcard6x9"
                          ? 0.25
                          : 0.3
                      }
                    />
                  </Wrapper>
                ) : null}

                <SelectBox
                  select_ref={this._template_noimage}
                  items={this.props.mailer_templates}
                  value={default_mail_noimage_template?.id || "--"}
                  placeholder="Default design for leads with no image:"
                  show={this.state.template_noimage_focus}
                  onFocus={() => {
                    this.setState({ template_noimage_focus: true });
                  }}
                  onBlur={() => {
                    this.setState({ template_noimage_focus: false });
                  }}
                  onSelect={item => {
                    let mailer = null;

                    for (
                      let i = 0;
                      i < this.props.mailer_templates.length;
                      i++
                    ) {
                      if (this.props.mailer_templates[i].id == item) {
                        mailer = this.props.mailer_templates[i];
                      }
                    }
                    if (mailer) {
                      this.setState({
                        default_mail_noimage_template: mailer
                      });
                    }
                  }}
                  onSubmitEditing={() => {}}
                />
              </Card>
            </KeyboardView>

            <BottomNavBar>
              <Row style={{ justifyContent: "space-between" }}>
                <InlineButton
                  select_type="toggle"
                  icon={resend_freq_toggle ? "toggle-on" : "toggle-off"}
                  onPress={() => {
                    this.setState({
                      resend_freq_toggle: resend_freq_toggle ? false : true,
                      resend_freq: resend_freq_toggle ? 0 : 21,
                      resend_limit_toggle: resend_freq_toggle
                        ? false
                        : resend_limit > 0
                        ? false
                        : true
                    });
                  }}
                  iconColor={resend_freq_toggle ? colors.success_color : null}
                >
                  Repeat Mail
                </InlineButton>
                {resend_freq_toggle ? (
                  <InlineButton
                    select_type="toggle"
                    icon={
                      resend_limit_toggle > 0 && resend_freq_toggle
                        ? "toggle-on"
                        : "toggle-off"
                    }
                    onPress={() => {
                      this.setState({
                        resend_limit_toggle: resend_limit_toggle ? false : true,
                        resend_limit: resend_limit_toggle ? 12 : 0
                      });
                    }}
                    iconColor={
                      resend_limit_toggle && resend_freq_toggle
                        ? colors.success_color
                        : null
                    }
                  >
                    Repeat Forever
                  </InlineButton>
                ) : null}
              </Row>
            </BottomNavBar>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing, mailer }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors, photoCameraRoll } = settings;

  const {
    mailer_templates,
    mailer_template_count,
    default_bracket_variables,
    mailer_templates_begin,
    mailer_templates_limit,
    mailer_templates_loading,
    mailer_templates_refreshing,
    mailer_templates_error,
    mailer_templates_loaded_all,
    mailer_campaigns,
    mailer_campaigns_begin,
    mailer_campaigns_limit,
    mailer_campaigns_loading,
    mailer_campaigns_refreshing,
    mailer_campaigns_error,
    mailer_campaigns_loaded_all
  } = mailer;

  return {
    token,
    user,
    device,
    colors,
    mailer_templates,
    mailer_template_count,
    default_bracket_variables,
    mailer_templates_begin,
    mailer_templates_limit,
    mailer_templates_loading,
    mailer_templates_refreshing,
    mailer_templates_error,
    mailer_templates_loaded_all,
    mailer_campaigns,
    mailer_campaigns_begin,
    mailer_campaigns_limit,
    mailer_campaigns_loading,
    mailer_campaigns_refreshing,
    mailer_campaigns_error,
    mailer_campaigns_loaded_all
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getMailerTemplates,
  getMailerCampaigns,
  updateUser
})(AppSettings);
