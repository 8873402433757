import React, { Component } from "react";

import { Button, Copy, Wrapper, Row, Spin } from "app/NativeComponents/common";
import { toTitleCase, numberFormat, renderDate } from "app/NativeActions";
import moment from "moment";
import MainColumnItem from "./MainColumnItem";

import LeadStatusButtonWrapper from "./LeadStatusButtonWrapper";
import ManageListsButtonWrapper from "./ManageListsButtonWrapper";
import ManageTagsButtonWrapper from "./ManageTagsButtonWrapper";
import AssignLeadButtonWrapper from "./AssignLeadButtonWrapper";
import MainOwnerColumnItem from "./MainOwnerColumnItem";
import CampaignStatusColumnItem from "./CampaignStatusColumnItem";
import EmailAddressColumnItem from "./EmailAddressColumnItem";
import PhoneNumberColumnItem from "./PhoneNumberColumnItem";
import ListStackColumnItem from "./ListStackColumnItem";

import ManageDataUpdate from "app/DealMachineCore/reuseable/ManageDataUpdate";

class ColumnButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      row_height: props.toggle_lead_images == "large" ? 100 : 35
    };

    this.renderLoading = this.renderLoading.bind(this);

    this._column_button = React.createRef();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.toggle_lead_images !== this.props.toggle_lead_images) {
      this.setState({
        row_height: this.props.toggle_lead_images == "large" ? 100 : 35
      });
    }
  }

  renderLoading(property) {
    const { section, colors } = this.props;
    if (
      property &&
      property.deal &&
      property.deal.updating &&
      property.deal.updating.is_updating
    ) {
      return (
        <Wrapper
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            alignItems: "flex-start",
            justifyContent: "center",
            backgroundColor: colors.white_color_opacity
          }}
        >
          {section.main_column ? (
            <Row>
              <Copy
                style={{
                  marginLeft: 5,
                  color: colors.light_text_color,
                  size: 12
                }}
              ></Copy>
            </Row>
          ) : null}
        </Wrapper>
      );
    }
  }

  renderInnerContent(property) {
    const { section, active_property, colors } = this.props;
    const { row_height } = this.props;

    if (section.main_column) {
      return (
        <MainColumnItem
          {...this.props}
          toggle_lead_images={this.props.toggle_lead_images}
          pressProperty={this.props.pressProperty}
          property={property}
          colors={colors}
          section={section}
          row_height={row_height}
          active_property={active_property}
        />
      );
    }

    if (section.slug.includes("phone_number")) {
      return (
        <PhoneNumberColumnItem
          {...this.props}
          property={property}
          user={this.props.user}
          colors={colors}
          section={section}
          row_height={row_height}
          shouldPhoneNumberBeHighlighted={
            this.props.shouldPhoneNumberBeHighlighted
          }
        />
      );
    }
    let inner_value = "";
    switch (section.slug) {
      default:
        inner_value = section.deal
          ? section.custom_field
            ? property.deal?.custom_fields[section.slug]
              ? property.deal?.custom_fields[section.slug].value
              : "--"
            : !!property.deal[section.slug]
            ? property.deal[section.slug]
            : "--"
          : section.mls && property.mls
          ? property?.mls[section.slug]
          : !!property[section.slug]
          ? property[section.slug]
          : "--";
        break;

      case "equity_percent":
        const market_value = !!property?.EstimatedValue
          ? parseInt(property?.EstimatedValue)
          : !!property?.calculated_total_value
          ? parseInt(property?.calculated_total_value)
          : 0;
        const equity_amount = property.equity_amount;
        let equity_percent = Math.ceil((equity_amount / market_value) * 100);
        if (equity_percent > 100) {
          equity_percent = 100;
        }
        inner_value = equity_percent !== null ? equity_percent : 100;
        break;

      case "market_status":
        inner_value = property?.market_status?.value
          ? property?.market_status?.value
          : "--";
        break;

      case "creator":
        inner_value = property?.deal?.creator
          ? property?.deal?.creator
          : "--";
        break;

      case "lead_status":
      case "deal_status":
        return (
          <LeadStatusButtonWrapper
            property={property}
            colors={colors}
            section={section}
            row_height={row_height}
          />
        );

      case "lead_owner":
        return (
          <AssignLeadButtonWrapper
            property={property}
            colors={colors}
            section={section}
            row_height={row_height}
          />
        );
      case "list_stack":
        return (
          <ListStackColumnItem
            property={property}
            colors={colors}
            section={section}
            row_height={row_height}
          />
        );
      case "lists":
        return (
          <ManageListsButtonWrapper
            property={property}
            colors={colors}
            section={section}
            row_height={row_height}
          />
        );

      case "tags":
        return (
          <ManageTagsButtonWrapper
            property={property}
            colors={colors}
            section={section}
            row_height={row_height}
          />
        );

      case "mailing_options":
      case "campaign":
        return (
          <CampaignStatusColumnItem
            property={property}
            colors={colors}
            section={section}
            row_height={row_height}
          />
        );

      case "owner_address":
      case "owner_name":
      case "owner_firstname":
      case "owner_first_name":
      case "owner_lastname":
      case "owner_last_name":
      case "owner_status":
        return (
          <MainOwnerColumnItem
            property={property}
            colors={colors}
            section={section}
            row_height={row_height}
            getHighlightedText={this.props.getHighlightedText}
          />
        );
      case "email_addresses":
        return (
          <EmailAddressColumnItem
            property={property}
            colors={colors}
            section={section}
            row_height={row_height}
            getHighlightedText={this.props.getHighlightedText}
          />
        );
    }

    let formatted_value = "";
    if (inner_value != "--") {
      switch (section.format) {
        case "name":
        case "company_name":
          formatted_value = toTitleCase(inner_value);
          break;

        case "bool":
        case "boolean":
          formatted_value = inner_value ? "Yes" : "No";
          break;

        case "money":
        case "price":
          if (
            parseInt(inner_value) !== 0 &&
            inner_value &&
            inner_value !== ""
          ) {
            formatted_value = "$" + numberFormat(parseInt(inner_value));
          }
          break;

        case "date":
          if (inner_value && inner_value !== "" && inner_value !== 0) {
            formatted_value = moment(inner_value).format("MMM Do, YYYY");
          }
          break;

        case "percent":
          formatted_value = parseInt(parseFloat(inner_value)) + "%";

          break;

        case "interest_rate":
          if (inner_value && inner_value !== 0 && inner_value !== "0") {
            formatted_value =
              inner_value && inner_value != "0"
                ? (numberFormat(parseFloat(inner_value) * 100), 2) + "%"
                : "--";
          }
          break;

        case "term":
          if (parseInt(inner_value) > 60) {
            formatted_value = inner_value + " Month";
          }
          break;

        case "number":
          formatted_value = numberFormat(parseInt(inner_value));
          break;

        case "acre":
          formatted_value = parseFloat(inner_value).toFixed(2);
          break;

        case "feet":
          formatted_value = numberFormat(parseInt(inner_value)) + " ft";
          break;

        default:
          formatted_value = formatted_value = Array.isArray(inner_value)
            ? inner_value.every(x => typeof x === "object" && x !== null)
              ? inner_value.map(x => x?.label).join(",")
              : inner_value.join(",")
            : typeof inner_value === "object"
            ? inner_value?.label
            : inner_value;
          break;
      }
    } else {
      switch (section.format) {
        default:
          formatted_value = inner_value;
          break;
        case "bool":
        case "boolean":
          formatted_value = "No";
      }
    }
    return (
      <Wrapper
        style={{
          padding: 10,
          height: row_height
        }}
      >
        <Wrapper
          style={{
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "center"
          }}
        >
          <Row>
            <ManageDataUpdate
              property={property}
              slug={section.slug}
              format={section.format}
              icon_style={{ marginLeft: 0 }}
            />
            <Copy
              style={{
                whiteSpace: "nowrap"
              }}
            >
              {formatted_value}
            </Copy>
          </Row>
        </Wrapper>
      </Wrapper>
    );
  }

  render() {
    const { section, list_properties, colors, property, active_property } =
      this.props;
    return (
      <Button
        button_ref={this._column_button}
        onPress={() => {
          this.props.pressProperty(property);
        }}
        style={{
          width: section.width,
          overflow: "hidden",
          position: "relative",

          backgroundColor:
            active_property &&
            active_property.deal &&
            active_property.deal.id == property.deal.id
              ? colors.active_color_muted
              : colors.card_color
        }}
        /*
                onHover={() => {
                  this.props.setHoverRow(property.deal?.id);
                }}
                onHoverOut={() => {
                  //this.props.setHoverRow(null)
                }}
                */
      >
        {this.renderInnerContent(property)}
        {this.renderLoading(property)}
      </Button>
    );
  }
}

export default ColumnButton;
