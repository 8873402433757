import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper, Copy } from "app/NativeComponents/common";

import { List, SelectItem, SpinWrapper } from "app/NativeComponents/snippets";
import { getDialerStats, renderDate, pushSidePanel } from "app/NativeActions";

import CallItem from "app/DealMachineCore/components/CallSession/CallItem";
import moment from "moment/moment";
class OutgoingActivity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calls: [],
      calls_loading: false,
      calls_refreshing: false,
      calls_loaded_all: false,
      calls_limit: 25,
      calls_begin: 0
    };
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  getData({ load_type = "load" }) {
    if (!this.props.activity_loading && !this.state.calls_refreshing) {
      this.setState(
        {
          calls_begin:
            load_type === "load" || load_type === "refresh"
              ? 0
              : this.state.calls_begin + this.state.calls_limit,
          calls: load_type === "refresh" ? [] : this.state.calls,
          calls_loading: true,
          calls_refreshing: load_type === "refresh"
        },
        () => {
          this.props.getDialerStats({
            token: this.props.token,
            filter_user: this.props.filter_user
              ? this.props.filter_user?.id
              : null,
            filter_date: this.props.filter_date,
            filter_start_date: this.props.filter_start_date,
            filter_end_date: this.props.filter_end_date,
            limit: this.state.calls_limit,
            begin: this.state.calls_begin,
            type: "get_dialer_incoming_activity",
            utc_offset: moment().utcOffset(),
            timezone: moment.tz.guess(),
            onLoading: () => {
              this.setState({ calls_loading: true }, () => {
                this.props.updateMainState({
                  prop: "activity_loading",
                  value: true
                });
              });
            },
            onError: () => {
              this.setState(
                {
                  calls_loading: false,
                  calls_refreshing: false
                },
                () => {
                  this.props.updateMainState({
                    prop: "activity_loading",
                    value: false
                  });
                }
              );
            },
            onSuccess: results => {
              if (results?.call_history) {
                this.setState(
                  {
                    calls_loading: false,
                    calls_refreshing: false,
                    calls:
                      load_type === "load_more"
                        ? [...this.state.calls, ...results.call_history]
                        : results.call_history,
                    calls_loaded_all:
                      results.call_history?.length < this.state.calls_limit
                  },
                  () => {
                    this.props.updateMainState({
                      prop: "activity_loading",
                      value: false
                    });
                  }
                );
              }
            }
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.filter_user?.id != prevProps.filter_user?.id ||
      this.props.filter_date != prevProps.filter_date ||
      this.props.filter_start_date != prevProps.filter_start_date ||
      this.props.filter_end_date != prevProps.filter_end_date
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  componentWillUnmount() {}

  render() {
    const { colors } = this.props;
    const { calls, calls_refreshing, calls_loaded_all } = this.state;

    const { activity_loading } = this.props;

    return (
      <>
        {(activity_loading && calls?.length === 0) || calls_refreshing ? (
          <SpinWrapper text={"Loading Activity"} />
        ) : null}

        {calls?.length === 0 && !activity_loading && !calls_refreshing ? (
          <Wrapper style={{ padding: 25 }}>
            <Copy style={{ textAlign: "center" }}>No activity found.</Copy>
          </Wrapper>
        ) : (
          <List
            rowNumber={1}
            items={calls}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              return (
                <CallItem
                  key={"call_" + index}
                  call={item}
                  pushSidePanel={this.props.pushSidePanel}
                />
              );
            }}
            canRefresh={false}
            onRefresh={() => {}}
            is_refreshing={false}
            canLoadMore={
              !calls_loaded_all && !activity_loading && calls?.length > 0
            }
            isLoadingMore={activity_loading && calls?.length > 0}
            onLoadMore={() => {
              if (!calls_loaded_all && activity_loading !== true) {
                this.getData({ load_type: "load_more" });
              }
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const { current_call_session } = dialer;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,
    current_call_session
  };
};

export default connect(mapStateToProps, {
  getDialerStats,
  pushSidePanel
})(OutgoingActivity);
