import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Bold,
  Wrapper,
  Row,
  Copy,
  Card,
  AlmaOrb,
  ProgressBar
} from "app/NativeComponents/common";
import { Header1, Header3 } from "app/NativeComponents/common";

import {
  logout,
  loadOnboardingText,
  startRedirect,
  updateUser,
  getMarketingOnboardingData,
  checkIfUserHasMetadata,
  manageSubscription,
  pushSidePanel,
  popSidePanel,
  updateMailerTemplate,
  updateLead
} from "app/NativeActions";
import {
  InlineButton,
  GhostButton,
  SpinWrapper,
  OnboardingWrapper
} from "app/NativeComponents/snippets";

import Alma from "app/DealMachineCore/components/Property/Alma";

class AlmaStep extends Component {
  constructor(props) {
    super(props);

    const almaFeature = props?.all_feature_metadata?.find(
      feature => feature.slug === "alma"
    );

    let stripe_price_id_month = almaFeature?.upsell_stripe_price_id_monthly;
    let stripe_price_id_year = almaFeature?.upsell_stripe_price_id_annually;

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      loading_property: false,
      lead: null,
      property_data_for_openai: null,
      onboarding_prompt: null,
      send_onboarding_prompt: false,
      loaded_system_messages: false,
      stripe_price_id_month,
      stripe_price_id_year,
      plan_interval: props?.source_of_truth?.subscription?.interval
        ? props?.source_of_truth?.subscription?.interval
        : "month",
      onboarding_complete: false,
      next_loading: false
    };

    this.loadData = this.loadData.bind(this);
    this.getProperty = this.getProperty.bind(this);

    this._scroll_ref = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/meet-alma");
    }
    this.loadData("alma_step");

    this.getProperty();
  }

  componentWillUnmount() {
    clearInterval(this.scroll_interval);
  }

  scrollToBottom() {
    if (this.props.device === "mobile") {
      if (this._scroll_ref && this._scroll_ref.current) {
        this._scroll_ref.current.scrollToEnd({ animated: true });
      }
    }
  }

  getProperty() {
    this.props.getMarketingOnboardingData({
      token: this.props.token,
      type: "alma_step",
      onLoading: () => {
        this.setState({ loading_property: true });
      },
      onError: () => {
        this.setState({ loading_property: true });
      },
      onSuccess: results => {
        this.setState({
          loading_property: false,
          lead: results?.lead,
          property_data_for_openai: results?.property_data,
          onboarding_prompt: results?.onboarding_prompt
        });
      }
    });
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const {
      contentful_content,
      contentful_loading,
      lead,
      property_data_for_openai
    } = this.state;
    const { colors, isMobile, user } = this.props;

    return (
      <Container title={"Meet Alma, your new best friend."}>
        <OnboardingWrapper
          scroll_ref={this._scroll_ref}
          renderInner={() => {
            return (
              <Row>
                <InlineButton
                  icon={"arrow-back"}
                  loading={this.state.back_loading}
                  onPress={() => {
                    this.props.updateUser({
                      token: this.props.token,
                      type: "onboarding_stepthrough_step",
                      payload: {
                        onboarding_stepthrough_current_step: "add_property_step"
                      },
                      onLoading: () => {
                        this.setState({
                          back_loading: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          back_loading: false
                        });
                      },
                      onSuccess: () => {
                        this.setState({
                          back_loading: false
                        });
                      },
                      no_loading: true
                    });
                  }}
                >
                  Go Back
                </InlineButton>
              </Row>
            );
          }}
        >
          {this.state.contentful_content ? (
            <>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 25,
                  paddingTop: 0
                }}
              >
                <Header1
                  style={{
                    textAlign: "center"
                  }}
                >
                  👊 <Bold>Nice find!</Bold>
                </Header1>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  Now that we have our lead, let's see if it's a good fit.
                </Header3>
              </Wrapper>

              {this.props.visitor?.marketing_experiments?.dealmachine_free ===
              "dealmachine_free" ? (
                <Wrapper
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: 25
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    Tutorial step 3 of 5.
                  </Copy>
                  <ProgressBar
                    style={{ marginTop: 10 }}
                    color={colors.active_color}
                    backgroundColor={colors.white_color}
                    width={200}
                    progress={3 / 5}
                  />
                </Wrapper>
              ) : (
                <Wrapper
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: 25
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    Tutorial step 3 of 4.
                  </Copy>
                  <ProgressBar
                    style={{ marginTop: 10 }}
                    color={colors.active_color}
                    backgroundColor={colors.white_color}
                    width={200}
                    progress={3 / 4}
                  />
                </Wrapper>
              )}

              <Wrapper
                style={
                  this.props.device === "mobile" || this.props.desktopMobile
                    ? {}
                    : {
                        alignSelf: "stretch",
                        flexDirection: "row",
                        alignItems: "center"
                      }
                }
              >
                <Wrapper
                  style={
                    this.props.device === "mobile" || this.props.desktopMobile
                      ? {}
                      : {
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center"
                        }
                  }
                >
                  {lead && lead?.id && !this.state.loading_property ? (
                    <>
                      {this.props.device == "mobile" ||
                      this.props.desktopMobile ? (
                        <></>
                      ) : (
                        <Wrapper
                          style={{
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <Card
                            style={{
                              borderRadius: 30,
                              height: 700,
                              width: 400,
                              boxShadow: "0 24px 64px rgba(38,33,74,.1)",
                              overflow: "hidden"
                            }}
                          >
                            <Alma
                              property={lead}
                              property_data_for_openai={
                                this.state.property_data_for_openai
                              }
                              onboarding={true}
                              onboarding_prompt={
                                this.state.send_onboarding_prompt
                                  ? this.state.onboarding_prompt
                                  : false
                              }
                              show_alma_panel={"show"}
                              updateMainState={() => {}}
                              onboardingComplete={() => {
                                this.setState({ onboarding_complete: true });
                              }}
                              onSystemMessageLoad={() => {
                                this.setState({ loaded_system_messages: true });
                              }}
                            />
                          </Card>
                        </Wrapper>
                      )}
                    </>
                  ) : null}
                </Wrapper>

                <Wrapper
                  style={
                    this.props.device === "desktop" && !this.props.desktopMobile
                      ? { width: 450 }
                      : {}
                  }
                >
                  <Card style={{ borderWidth: 0 }}>
                    <Wrapper style={{ padding: 25, paddingBottom: 15 }}>
                      {!this.state.onboarding_complete ||
                      this.props.device == "mobile" ||
                      this.props.desktopMobile ? (
                        <>
                          <Wrapper
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              paddingBottom: 25
                            }}
                          >
                            <AlmaOrb size={40} active={true} big_orb={true} />
                          </Wrapper>

                          <Header3 style={{ marginBottom: 10 }}>
                            <Bold>Meet Alma, your new best friend.</Bold>
                          </Header3>

                          <Copy style={{ marginBottom: 10 }}>
                            Alma is a real estate AI assistant that will help
                            you along your journey to financial freedom.
                          </Copy>
                          <Copy style={{ marginBottom: 10 }}>
                            Ask Alma anything about a property. Seriously,
                            anything.
                          </Copy>
                          <Copy style={{ marginBottom: 10 }}>
                            For now, let's ask Alma if this property is a good
                            idea for an investment.
                          </Copy>
                        </>
                      ) : this.state.onboarding_complete &&
                        !this.props.device !== "mobile" &&
                        !this.props.desktopMobile ? (
                        <>
                          <Wrapper
                            style={{
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <AlmaOrb size={40} active={true} big_orb={true} />
                          </Wrapper>

                          <Header3 style={{ marginBottom: 10 }}>
                            <Bold>Pretty cool, right?</Bold>
                          </Header3>

                          <Copy style={{ marginBottom: 10 }}>
                            Alma will guide you every step of the way and is
                            included with all Pro plans and above.
                          </Copy>

                          <Copy style={{ marginBottom: 10 }}>
                            Even if Alma suggested this might not be the best
                            investment, let's move forward for the sake of the
                            demo!
                          </Copy>
                        </>
                      ) : null}
                    </Wrapper>

                    {(this.props.device == "mobile" ||
                      this.props.desktopMobile) &&
                    lead &&
                    lead?.id &&
                    !this.state.loading_property ? (
                      <Wrapper
                        style={{
                          marginBottom:
                            this.state.onboarding_complete ||
                            !this.state.send_onboarding_prompt
                              ? 0
                              : 100
                        }}
                      >
                        <Alma
                          property={lead}
                          property_data_for_openai={
                            this.state.property_data_for_openai
                          }
                          onboarding={true}
                          onboarding_prompt={
                            this.state.send_onboarding_prompt
                              ? this.state.onboarding_prompt
                              : false
                          }
                          show_alma_panel={"show"}
                          updateMainState={() => {}}
                          onboardingComplete={() => {
                            this.setState({ onboarding_complete: true }, () => {
                              this.scroll_interval = setTimeout(() => {
                                this.scrollToBottom();
                              }, 200);
                            });
                          }}
                          onSystemMessageLoad={() => {
                            this.setState({ loaded_system_messages: true });
                          }}
                          onScrollToBottom={this.scrollToBottom}
                        />
                      </Wrapper>
                    ) : null}

                    {this.state.onboarding_complete &&
                    (this.props.device === "mobile" ||
                      this.props.desktopMobile) ? (
                      <>
                        <Wrapper style={{ padding: 25, paddingBottom: 15 }}>
                          <Header3 style={{ marginBottom: 10 }}>
                            <Bold>Pretty cool, right?</Bold>
                          </Header3>
                          <Copy style={{ marginBottom: 10 }}>
                            Alma will guide you every step of the way and is
                            included with all Pro plans and above.
                          </Copy>

                          <Copy style={{ marginBottom: 10 }}>
                            Even if Alma suggested this might not be the best
                            investment, let's move forward for the sake of the
                            demo!
                          </Copy>
                        </Wrapper>
                      </>
                    ) : null}

                    <Wrapper>
                      {!this.state.send_onboarding_prompt ? (
                        <>
                          <GhostButton
                            button_type={"full"}
                            disabled={!this.state.loaded_system_messages}
                            onPress={() => {
                              this.setState({
                                send_onboarding_prompt: true
                              });
                            }}
                            style={{
                              marginLeft: 20,
                              marginRight: 20,
                              marginBottom: 20
                            }}
                          >
                            Analyze This Property
                          </GhostButton>
                          {/*
                          <InlineButton
                            button_type={"full"}
                            loading={this.state.next_loading}
                            style={{
                              marginLeft: 20,
                              marginRight: 20,
                              marginBottom: 20
                            }}
                            onPress={() => {
                              this.props.updateUser({
                                token: this.props.token,
                                type: "onboarding_stepthrough_step",

                                payload: {
                                  onboarding_stepthrough_current_step:
                                    "design_mail_step"
                                },
                                onLoading: () => {
                                  this.setState({
                                    next_loading: true
                                  });
                                },
                                onError: () => {},
                                onSuccess: () => {},
                                no_loading: true
                              });
                            }}
                          >
                            Skip For Now
                          </InlineButton>
                          */}
                        </>
                      ) : this.state.onboarding_complete ? (
                        <>
                          {this.props.source_of_truth?.subscription
                            ?.billing_source !== "iap" &&
                          !checkIfUserHasMetadata("alma") ? (
                            <>
                              <Wrapper
                                className="sparkle-effect"
                                style={{
                                  margin: 10,
                                  marginRight: 5,
                                  marginLeft: 5,
                                  borderRadius: 30
                                }}
                              >
                                <InlineButton
                                  style={{
                                    borderRadius: 30,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: colors.active_color_muted,
                                    marginLeft: 20,
                                    marginRight: 20
                                  }}
                                  button_type={"full"}
                                  loading={this.state.upgrade_loading}
                                  marketing_slug={
                                    "pressed_alma_step_upgrade_button"
                                  }
                                  onPress={() => {
                                    if (
                                      this.props.visitor?.marketing_experiments
                                        ?.dealmachine_free ===
                                        "dealmachine_free" &&
                                      this.props.device === "desktop"
                                    ) {
                                      this.props.pushSidePanel({
                                        slug: "purchase_plan_prompt",
                                        overlay_modal: true,
                                        locked: true,
                                        data: {
                                          title:
                                            "Close More Deals With DealMachine Pro.",
                                          dismiss_button:
                                            "No thanks. Let's continue without Alma.",
                                          event_slug: "onboarding_alma_step",
                                          no_free: true
                                        },
                                        locked: true
                                      });
                                    } else {
                                      this.props.manageSubscription({
                                        token: this.props.token,
                                        type: "add_to_subscription",
                                        event_slug: "onboarding_alma_step",
                                        stripe_price_id:
                                          this.state.plan_interval == "year"
                                            ? this.state.stripe_price_id_year
                                            : this.state.stripe_price_id_month,
                                        no_messaging: true,
                                        onLoading: () => {
                                          this.setState({
                                            upgrade_loading: true
                                          });
                                        },
                                        onError: () => {
                                          this.setState({
                                            upgrade_loading: false
                                          });
                                        },
                                        onSuccess: () => {
                                          this.props.updateUser({
                                            token: this.props.token,
                                            type: "onboarding_stepthrough_step",
                                            payload: {
                                              onboarding_stepthrough_current_step:
                                                "design_mail_step"
                                            },
                                            onLoading: () => {},
                                            onError: () => {},
                                            onSuccess: () => {},
                                            no_loading: true
                                          });
                                        }
                                      });
                                    }
                                  }}
                                >
                                  {this.props.visitor?.marketing_experiments
                                    ?.dealmachine_free === "dealmachine_free" &&
                                  this.props.device === "desktop"
                                    ? "I love Alma! Let's buy a Pro plan."
                                    : "I love Alma! Upgrade me to Pro."}
                                </InlineButton>
                              </Wrapper>

                              <InlineButton
                                button_type={"full"}
                                loading={this.state.next_loading}
                                onPress={() => {
                                  this.props.updateUser({
                                    token: this.props.token,
                                    type: "onboarding_stepthrough_step",

                                    payload: {
                                      onboarding_stepthrough_current_step:
                                        "design_mail_step"
                                    },
                                    onLoading: () => {
                                      this.setState({
                                        next_loading: true
                                      });
                                    },
                                    onError: () => {},
                                    onSuccess: () => {},
                                    no_loading: true
                                  });
                                }}
                              >
                                {this.props.visitor?.marketing_experiments
                                  ?.dealmachine_free === "dealmachine_free" &&
                                this.props.device === "desktop"
                                  ? "No thanks. Let's continue without Alma."
                                  : "No thanks. I don't want Alma as part of my trial."}
                              </InlineButton>
                            </>
                          ) : (
                            <>
                              <GhostButton
                                button_type={"full"}
                                loading={this.state.next_loading}
                                onPress={() => {
                                  this.props.updateUser({
                                    token: this.props.token,
                                    type: "onboarding_stepthrough_step",
                                    payload: {
                                      onboarding_stepthrough_current_step:
                                        "design_mail_step"
                                    },
                                    onLoading: () => {},
                                    onError: () => {},
                                    onSuccess: () => {
                                      this.setState({
                                        next_loading: true
                                      });
                                    },
                                    no_loading: true
                                  });
                                }}
                                primary={true}
                                style={{
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginBottom: 20
                                }}
                              >
                                Continue
                              </GhostButton>
                            </>
                          )}
                        </>
                      ) : null}

                      {/*
                      <Wrapper style={{ padding: 25, paddingTop: 15 }}>
                        <Copy
                          style={{
                            fontSize: 12,
                            color: colors.light_text_color
                          }}
                        >

                        </Copy>
                      </Wrapper>
                      */}
                    </Wrapper>
                  </Card>
                </Wrapper>
              </Wrapper>
            </>
          ) : null}

          <Wrapper style={{ height: 40 }} />
        </OnboardingWrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth, all_feature_metadata } = billing;
  const { isMobile, device, initialRedirect, desktopMobile } = native;
  const { visitor, signup_funnel } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    desktopMobile,
    device,
    initialRedirect,
    source_of_truth,
    all_feature_metadata,
    signup_funnel,
    visitor
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  updateUser,
  getMarketingOnboardingData,
  manageSubscription,
  pushSidePanel,
  popSidePanel,
  updateMailerTemplate,
  updateLead
})(AlmaStep);
