import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from "app/NativeComponents/common";
import UpsellOnboarding from "./UpsellOnboarding";
import AddPropertyStep from "./AddPropertyStep";
import BuildListStep from "./BuildListStep";
import SelectLeadStep from "./SelectLeadStep";

import AlmaStep from "./AlmaStep";
import DesignMailStep from "./DesignMailStep";
import FinalStepStageOne from "./FinalStepStageOne";
import ValidatePhoneStep from "./ValidatePhoneStep";
import DialerStep from "./DialerStep";

import NavigationService from "app/Router/NavigationService";
import {
  logout,
  startRedirect,
  updateUser,
  isMobilePhoneCheck,
  logMarketingEvent,
  trackTrialEvent
} from "app/NativeActions";
import NativeMobilePrompt from "../../WebComponents/NativeMobilePrompt";
import AcceptLocationPermissions from "../../MobileComponents/AcceptLocationPermissions";

class TrialSuccessOnboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.device === "mobile") {
      NavigationService.setTopLevelNavigator(this.props.navigation);
    }

    const { user } = this.props;
    if (
      (!user?.onboarding_stepthrough_step ||
        user?.onboarding_stepthrough_step === "select_plan_option_step") &&
      user.finished_onboarding == 1
    ) {
      trackTrialEvent();

      //increase to the next step so we don't log the purchase again
      this.props.updateUser({
        token: this.props.token,
        type: "onboarding_stepthrough_step",
        payload: {
          onboarding_stepthrough_current_step: "add_property_step"
        },
        onLoading: () => {},
        onError: () => {},
        onSuccess: () => {},
        no_loading: true
      });
    }
  }

  render() {
    const { user } = this.props;
    if (this.props.init && user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    if (
      !!user?.onboarding_stepthrough_step &&
      !this.props.devicePermissions?.is_location_on &&
      !this.props.bypass_location &&
      this.props.platform == "ios"
    ) {
      return <AcceptLocationPermissions />;
    }

    switch (user?.onboarding_stepthrough_step) {
      case "final_step_stage_one":
        /*
        if (
          user?.phone_verified != 1 &&
          !user?.email.includes("@synthetics.dtdg.co")
        ) {
          return <ValidatePhoneStep />;
        }
        */

        return <FinalStepStageOne />;

      case "upsell_onboarding_step":
        return <UpsellOnboarding />;
      case "design_mail_step":
        return <DesignMailStep />;
      case "alma_step":
        return <AlmaStep />;

      case "dialer_step":
        if (this.props.device === "desktop") {
          return <DialerStep />;
        }
        return <FinalStepStageOne />;

      case "select_lead_step":
        return <SelectLeadStep />;

      case "add_property_step":
      case "build_list_step":
        return <BuildListStep />;

      default:
        return <Spinner />;
    }
  }
}

const mapStateToProps = ({ auth, billing, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors, bypass_location } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect, bypass_mobile_redirect } = native;
  const { visitor } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    bypass_location,
    isMobile,
    device,
    initialRedirect,
    source_of_truth,
    bypass_mobile_redirect,
    visitor
  };
};

export default connect(mapStateToProps, { logout, startRedirect, updateUser })(
  TrialSuccessOnboarding
);
