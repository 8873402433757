import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { Card, Wrapper, Copy } from "app/NativeComponents/common";
import { GhostButton } from "app/NativeComponents/snippets";

import { renderPrice } from "app/NativeActions";

function PaymentMethodForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    if (error) {
      console.error(error);
      props.showErrorMessage(error.message, "Error");
    } else {
      // Send paymentMethod.id to your server (PHP) to attach to the customer

      props.stripeCardAction({
        token: props.token,
        paymentMethodId: paymentMethod.id,
        error: error,
        type: "add_stripe_payment_method",
        onLoading: () => {
          props.triggerLoading(true);
        },
        onError: error => {
          props.triggerLoading(false);
          props.showErrorMessage(error, "Error");
        },
        onSuccess: () => {
          props.showSuccess(
            "Your card has been saved to your account.",
            "Success!"
          );
          props.popSidePanel();
        }
      });
    }
  };

  const { colors, deal_credits } = props;

  //round up to the neeest 100
  const rounded_cents =
    Math.ceil(deal_credits?.deal_credits_remaining?.cents / 100) * 100;

  return (
    <form onSubmit={handleSubmit} style={{ margin: 0 }}>
      <Card>
        <CardElement
          options={{
            hidePostalCode: false
          }}
        />
      </Card>
      <Wrapper style={{ flex: 1 }}>
        <GhostButton
          button_type="full"
          formButton={true}
          style={{ alignSelf: "stretch" }}
        >
          Add Card
        </GhostButton>
      </Wrapper>
      <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
        <Copy
          style={{
            fontSize: 12,
            color: colors.light_text_color,
            textAlign: "center"
          }}
        >
          {!!deal_credits?.deal_credits_remaining?.cents
            ? "You will not be charged until you run out of your free " +
              renderPrice(rounded_cents) +
              " in marketing credits."
            : "You will not be charged until you run out of your free marketing credits."}
        </Copy>
      </Wrapper>
    </form>
  );
}

export default PaymentMethodForm;
