import React, { Component } from "react";
import { connect } from "react-redux";
import { formatUsPhone, popSidePanel } from "app/NativeActions";
import {
  Container,
  KeyboardView,
  Wrapper,
  Copy,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  GhostButton,
  InlineButton,
  List,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";
import {
  getCallTrackingNumbers,
  pushSidePanel,
  checkIfUserHasMetadata
} from "app/NativeActions";
import CallTrackingNumberItem from "./CallTrackingNumberItem";
class CallTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      call_tracking_numbers_loading: false,
      call_tracking_numbers: []
    };

    this.getCallTrackingNumbers = this.getCallTrackingNumbers.bind(this);

    this.handleNewNumber = this.handleNewNumber.bind(this);
    this.handleEditExistingNumber = this.handleEditExistingNumber.bind(this);
    this.handleRemoveExistingNumber =
      this.handleRemoveExistingNumber.bind(this);
  }

  componentDidMount() {
    this.getCallTrackingNumbers();
  }

  getCallTrackingNumbers() {
    this.props.getCallTrackingNumbers({
      token: this.props.token,
      type: "get_call_tracking_numbers",
      onLoading: () => {
        this.setState({ call_tracking_numbers_loading: true });
      },
      onError: error => {
        this.setState({ call_tracking_numbers_loading: false });
      },
      onSuccess: results => {
        this.setState({
          call_tracking_numbers_loading: false,
          call_tracking_numbers: results?.call_tracking_numbers
        });
      }
    });
  }
  handleNewNumber(call_tracking_number) {
    this.setState({
      call_tracking_numbers: [
        ...this.state.call_tracking_numbers,
        call_tracking_number
      ]
    });

    this.getCallTrackingNumbers();

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (panel_data?.onCallTrackNumbersChanged) {
      panel_data.onCallTrackNumbersChanged();
    }
  }

  handleEditExistingNumber(call_tracking_number) {
    const { call_tracking_numbers } = this.state;

    const index = call_tracking_numbers.findIndex(
      number => number.id === call_tracking_number?.id
    );

    if (index !== -1) {
      call_tracking_numbers[index] = call_tracking_number;
      this.setState({ call_tracking_numbers });
    }

    this.getCallTrackingNumbers();
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (panel_data?.onCallTrackNumbersChanged) {
      panel_data.onCallTrackNumbersChanged();
    }
  }
  handleRemoveExistingNumber(call_tracking_number_id) {
    const { call_tracking_numbers } = this.state;
    const index = call_tracking_numbers.findIndex(
      number => number.id === call_tracking_number_id
    );

    if (index !== -1) {
      call_tracking_numbers.splice(index, 1);
      this.setState({ call_tracking_numbers });
    }
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (panel_data?.onCallTrackNumbersChanged) {
      panel_data.onCallTrackNumbersChanged();
    }
  }

  componentDidUpdate(prevProps) {}

  render() {
    const { colors } = this.props;
    const { call_tracking_numbers_loading, call_tracking_numbers } = this.state;

    return (
      <Container>
        <NewHeader
          title={"Call Tracking"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />
        <KeyboardView>
          {call_tracking_numbers_loading ? (
            <SpinWrapper text={"Loading"} />
          ) : (
            <>
              {call_tracking_numbers?.length === 0 ? (
                <>
                  <Wrapper
                    style={{
                      padding: 25,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Copy style={{ textAlign: "center", marginBottom: 10 }}>
                      You haven't added any call tracking numbers yet. Add your
                      first number to start tracking your calls.
                    </Copy>
                    <GhostButton
                      primary={true}
                      onPress={() => {
                        if (!checkIfUserHasMetadata("paid_plan")) {
                          this.props.pushSidePanel({
                            slug: "purchase_plan_prompt",
                            overlay_modal: true,
                            locked: true,
                            data: {
                              title:
                                "Get Call Tracking Numbers With DealMachine Pro.",
                              dismiss_button:
                                "No thanks. Let me continue without call tracking numbers.",
                              no_free: true,
                              event_slug: "in_app_call_tracking"
                            },
                            locked: true
                          });
                        } else {
                          this.props.pushSidePanel({
                            slug: "create_call_tracking_number",
                            overlay: true,
                            data: {
                              handleNewNumber: this.handleNewNumber
                            }
                          });
                        }
                      }}
                    >
                      Get Call Tracking Number
                    </GhostButton>
                  </Wrapper>
                </>
              ) : (
                <>
                  <Row>
                    <Wrapper style={{ flex: 1 }}>
                      <SmallLabel>My call tracking numbers:</SmallLabel>
                    </Wrapper>
                    <InlineButton
                      primary={true}
                      icon={"add"}
                      onPress={() => {
                        if (!checkIfUserHasMetadata("paid_plan")) {
                          this.props.pushSidePanel({
                            slug: "purchase_plan_prompt",
                            overlay_modal: true,
                            locked: true,
                            data: {
                              title:
                                "Get Call Tracking Numbers With DealMachine Pro.",
                              dismiss_button:
                                "No thanks. Let me continue without call tracking numbers.",
                              no_free: true,
                              event_slug: "in_app_call_tracking"
                            },
                            locked: true
                          });
                        } else {
                          this.props.pushSidePanel({
                            slug: "create_call_tracking_number",
                            overlay: true,
                            data: {
                              handleNewNumber: this.handleNewNumber
                            }
                          });
                        }
                      }}
                    >
                      Add
                    </InlineButton>
                  </Row>

                  <List
                    rowNumber={1}
                    items={call_tracking_numbers}
                    infiniteScroll={false}
                    itemStructure={({ item, index }) => {
                      return (
                        <CallTrackingNumberItem
                          key={"call_tracking_number_" + index}
                          item={item}
                          handleEditExistingNumber={
                            this.handleEditExistingNumber
                          }
                          handleRemoveExistingNumber={
                            this.handleRemoveExistingNumber
                          }
                        />
                      );
                    }}
                    onRefresh={() => {}}
                    is_refreshing={false}
                    canLoadMore={false}
                    isLoadingMore={false}
                    onLoadMore={() => {}}
                  />
                </>
              )}
            </>
          )}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;

  return {
    token,
    user,
    isMobile,
    colors,
    device
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  getCallTrackingNumbers,
  pushSidePanel
})(CallTracking);
