import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Copy,
  KeyboardView,
  ProfilePic,
  Row,
  Wrapper,
  Icon,
  Bold,
  AlmaOrb,
  Spin
} from "app/NativeComponents/common";

import {
  InlineButton,
  IconButton,
  SelectItem,
  SpinWrapper,
  BottomNavBar,
  PopoverMenu,
  SmallLabel,
  Tag
} from "app/NativeComponents/snippets";

import {
  updateActiveCall,
  updateUserSettings,
  setActiveDialerContact,
  formatUsPhone,
  formatAddress,
  updateVoiceClone,
  getQueuedNumbers,
  getCallSession,
  pushSidePanel
} from "app/NativeActions";

class ActionButtons extends Component {
  constructor(props) {
    super(props);

    const default_dialer_settings = {
      callback_number: formatUsPhone(props.user?.team_phone),
      ...props.default_dialer_settings
    };

    this.state = {
      show_settings_menu: false,
      default_dialer_settings: default_dialer_settings,
      scrub_dnc: props.user?.user_settings?.dialer_settings
        ? props.user?.user_settings?.dialer_settings?.scrub_dnc
        : true,
      ai_voicemail: props.user?.user_settings?.dialer_settings
        ? props.user?.user_settings?.dialer_settings?.ai_voicemail
        : false,
      triggered_training: false,
      scrub_dnc_loading: false,
      drop_voicemail_loading: false,
      dropped_voicemail: false,
      show_audio_input_devices: false,
      selected_input_device: props.selected_input_device,
      selected_output_device: props.selected_output_device
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  checkIfNextNumberButton() {
    const { active_dialer_contact, queued_numbers } = this.props;

    let next_number = null;

    for (let i = 0; i < queued_numbers?.length; i++) {
      if (
        queued_numbers[i]?.contact_id ==
          active_dialer_contact?.individual_key &&
        formatUsPhone(queued_numbers[i]?.number) !=
          formatUsPhone(active_dialer_contact?.selected_phone?.number) &&
        !next_number
      ) {
        next_number = queued_numbers[i];
      }
    }

    return next_number;
  }

  renderSelectAudioInputDeviceMenu() {
    const { colors } = this.props;

    if (
      this.props.device === "desktop" &&
      this.props.audio_input_devices &&
      this.props.selected_input_device
    ) {
      let menu_items = [];

      for (let i = 0; i < this.props.audio_input_devices.length; i++) {
        menu_items.push({
          title: this.props.audio_input_devices[i].label,
          selected:
            this.props.audio_input_devices[i].id ==
            this.state.selected_input_device?.id,

          onPress: () => {
            this.setState(
              {
                selected_input_device: this.props.audio_input_devices[i]
              },
              () => {
                this.props.selectNewAudioInputDevice(
                  this.props.audio_input_devices[i].id
                );
              }
            );
          }
        });
      }

      return (
        <>
          <SmallLabel>Select Input Device:</SmallLabel>
          <Row style={{ flexWrap: "wrap", paddingRight: 15, paddingLeft: 15 }}>
            {menu_items.map((item, index) => {
              return (
                <Tag
                  key={"selected_option_" + index}
                  selected={item.selected}
                  onPress={item.onPress}
                  icon_left={
                    item.selected
                      ? "radio-button-checked"
                      : "radio-button-unchecked"
                  }
                >
                  {item.title}
                </Tag>
              );
            })}
          </Row>
        </>
      );
    }
  }

  renderSelectAudioOutputDeviceMenu() {
    const { colors } = this.props;

    if (
      this.props.device === "desktop" &&
      this.props.audio_output_devices &&
      this.props.selected_output_device
    ) {
      let menu_items = [];

      for (let i = 0; i < this.props.audio_output_devices.length; i++) {
        menu_items.push({
          title: this.props.audio_output_devices[i].label,
          selected:
            this.props.audio_output_devices[i].id ==
            this.state.selected_output_device?.id,

          onPress: () => {
            this.setState(
              {
                selected_output_device: this.props.audio_output_devices[i]
              },
              () => {
                this.props.selectNewAudioOutputDevice(
                  this.props.audio_output_devices[i].id
                );
              }
            );
          }
        });
      }

      return (
        <>
          <SmallLabel>Select Output Device:</SmallLabel>
          <Row style={{ flexWrap: "wrap", paddingRight: 15, paddingLeft: 15 }}>
            {menu_items.map((item, index) => {
              return (
                <Tag
                  key={"selected_option_" + index}
                  selected={item.selected}
                  onPress={item.onPress}
                  icon_left={
                    item.selected
                      ? "radio-button-checked"
                      : "radio-button-unchecked"
                  }
                >
                  {item.title}
                </Tag>
              );
            })}
          </Row>
        </>
      );
    }
  }

  renderNextPhoneNumberButton() {
    const next_number = this.checkIfNextNumberButton();
    if (next_number) {
      return {
        title: "Call Next Number",
        icon: "keyboard-arrow-right",
        type: "normal",
        subtext: formatUsPhone(next_number?.number),
        description:
          "Call the next phone number listed for " +
          next_number?.contact?.full_name,
        onPress: this.props.nextNumberInQueue
      };
    }

    return null;
  }

  checkIfNextContactButton() {
    const { active_dialer_contact, queued_numbers } = this.props;

    let next_contact = null;

    if (active_dialer_contact?.associated_lead) {
      for (let i = 0; i < queued_numbers?.length; i++) {
        if (
          queued_numbers[i].contact_id !=
            active_dialer_contact?.individual_key &&
          queued_numbers[i].property_id ==
            active_dialer_contact?.associated_lead?.id &&
          !next_contact
        ) {
          next_contact = queued_numbers[i];
        }
      }
    }

    return next_contact;
  }

  renderNextContactButton() {
    const next_contact = this.checkIfNextContactButton();
    if (next_contact) {
      const formatted_address = formatAddress({
        address: {
          address: next_contact?.property.property_address,
          address2: next_contact?.property.property_address2,
          address_city: next_contact?.property.property_address_city,
          address_state: next_contact?.property.property_address_state,
          address_zip: next_contact?.property.property_address_zip,
          latitude: next_contact?.property?.latitude,
          longitude: next_contact?.property?.longitude
        }
      });

      return {
        title: "Call Next Contact",
        icon: "keyboard-arrow-right",
        type: "normal",
        subtext: next_contact?.contact?.full_name,
        description:
          "Call the next contact associated with " + formatted_address.line1,
        onPress: this.props.nextContactInQueue
      };
    }

    return null;
  }

  checkIfNextLeadButton() {
    const { active_dialer_contact, queued_numbers } = this.props;

    let next_lead = null;

    if (queued_numbers?.length > 0) {
      for (let i = 0; i < queued_numbers?.length; i++) {
        if (
          ((!active_dialer_contact?.associated_lead &&
            queued_numbers[i].property_id) ||
            queued_numbers[i].property_id !=
              active_dialer_contact?.associated_lead?.id) &&
          !next_lead
        ) {
          next_lead = queued_numbers[i];
        }
      }
    }

    return next_lead;
  }

  renderNextLeadButton() {
    const next_lead = this.checkIfNextLeadButton();
    if (next_lead) {
      const formatted_address = formatAddress({
        address: {
          address: next_lead?.property?.property_address,
          address2: next_lead?.property?.property_address2,
          address_city: next_lead?.property?.property_address_city,
          address_state: next_lead?.property?.property_address_state,
          address_zip: next_lead?.property?.property_address_zip,
          latitude: next_lead?.property?.latitude,
          longitude: next_lead?.property?.longitude
        }
      });

      return {
        title: "Call Next Lead",
        icon: "keyboard-arrow-right",
        type: "normal",
        subtext: next_lead?.contact?.full_name,
        description:
          "Call the next contact associated with with next lead in the queue: " +
          formatted_address.line1,
        onPress: this.props.nextLeadInQueue
      };
    }
  }

  renderSettingsMenu() {
    const { colors } = this.props;
    return (
      <PopoverMenu
        show={this.state.show_settings_menu}
        no_swipe={false}
        no_cancel={false}
        onShow={s => {
          this.setState({
            show_settings_menu: s
          });
        }}
        popoverSheetTop={400}
        popover_width={350}
        popoverPlacement={"bottom"}
        popover_title={"Dialer Quick Settings"}
        hideWithOutsideClick={false}
        includeCloseButton={true}
        renderComponent={options => {
          return (
            <IconButton
              icon={"settings"}
              noPress={true}
              pressedIn={options.hovering || options.pressed_in}
              icon_color={colors.white_text_color}
              hover_color={colors.hover_white_color}
            />
          );
        }}
        renderMenu={() => {
          return (
            <Wrapper style={{ alignSelf: "stretch" }}>
              {!this.props.onboarding ? (
                <SelectItem
                  onPress={() => {
                    this.setState(
                      {
                        show_settings_menu: false
                      },
                      () => {
                        if (this.props.hideMore) {
                          this.props.hideMore();
                        }
                        this.props.pushSidePanel({
                          slug: "dialer_settings",
                          overlay: true
                        });
                      }
                    );
                  }}
                  select_type="none"
                  icon={"keyboard-arrow-right"}
                  description={
                    "View and edit your dialer settings to customize your experience."
                  }
                >
                  Dialer Settings
                </SelectItem>
              ) : null}

              <BottomNavBar>
                {this.props.device === "desktop"
                  ? this.renderSelectAudioInputDeviceMenu()
                  : null}
                {this.props.device === "desktop"
                  ? this.renderSelectAudioOutputDeviceMenu()
                  : null}
              </BottomNavBar>
            </Wrapper>
          );
        }}
      />
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.active_call?.call_id !== prevProps.active_call?.call_id) {
      this.setState({
        dropped_voicemail: false,
        triggered_training: false
      });
    }
  }

  render() {
    const { colors } = this.props;

    if (!this.props.show_more) {
      return null;
    }

    const next_number = this.renderNextPhoneNumberButton();
    const next_contact = this.renderNextContactButton();
    const next_lead = this.renderNextLeadButton();

    const dialer_provider = this.props.source_of_truth?.dialer_provider;

    //warm lead, hot lead, not interested go to the next lead
    //wrong contact goes to the next contact

    let skip_to_next_lead = false;
    let skip_to_next_contact = false;

    const call_results = this.props.active_call?.call_results;
    const lead_status_type =
      this.props.active_dialer_contact?.associated_lead?.deal?.deal_status_type;
    if (
      call_results === "warm_lead" ||
      call_results === "hot_lead" ||
      call_results === "not_interested" ||
      call_results === "unqualified" ||
      call_results === "appointment_set" ||
      call_results === "after_appointment" ||
      lead_status_type === "not_interested_status" ||
      lead_status_type === "unqualified_status" ||
      lead_status_type === "inactive_status" ||
      lead_status_type === "warm_lead_status" ||
      lead_status_type === "hot_lead_status" ||
      lead_status_type === "acquisition_status" ||
      lead_status_type === "disposition_status" ||
      lead_status_type === "won_status"
    ) {
      skip_to_next_lead = true;
    }

    if (call_results === "wrong_contact") {
      skip_to_next_contact = true;
    }

    let dialer_goal = "";
    switch (this.props.user?.industry) {
      default:
        dialer_goal =
          "Your goal is to inquire about purchasing the property from the contact.";
        break;
      case "Real Estate Agent":
        dialer_goal =
          "Your goal is to inquire about listing the property for sale on behalf of the contact as their agent.";
        break;
    }

    return (
      <BottomNavBar
        backgroundColor={colors.dark_phone_color}
        borderColor={colors.dark_phone_color}
      >
        {this.props.active_call?.answered_by === "machine" &&
        this.props.active_call?.call_status !== "call_ended" &&
        !this.props.checkIfUserTalked() &&
        ((!!this.props.generated_voicemail_text &&
          this.props.generated_voicemail_loading) ||
          this.props.generated_voicemail) ? (
          <>
            <Row>
              {this.props.generated_voicemail_loading ? (
                <>
                  <Row style={{ flex: 1, padding: 15 }}>
                    <Wrapper
                      style={{
                        marginRight: 15
                      }}
                    >
                      <Spin size={"small"} color={colors.white_text_color} />
                    </Wrapper>
                    <Wrapper style={{ flex: 1 }}>
                      <Copy style={{ color: colors.orange_color }}>
                        <Bold>Generating your voicemail</Bold>
                      </Copy>
                      <Copy style={{ color: colors.white_text_color }}>
                        When your generated voicemail is ready, just press "Drop
                        Voicemail" when your hear the beep.
                      </Copy>
                    </Wrapper>
                  </Row>
                </>
              ) : (
                <>
                  <Wrapper style={{ flex: 1, padding: 15 }}>
                    <Copy style={{ color: colors.orange_color }}>
                      <Bold>Your voicemail is ready to drop.</Bold>
                    </Copy>
                    <Copy style={{ color: colors.white_text_color }}>
                      When you hear the beep, just press "Drop Voicemail."
                    </Copy>
                  </Wrapper>
                </>
              )}

              <InlineButton
                onPress={() => {
                  this.props.updateActiveCall({
                    ...this.props.active_call,
                    dropped_voicemail: true
                  });

                  this.props.updateDialerConversation({
                    type: "inbound",
                    subtype: "dropped_voicemail",
                    message: this.props.generated_voicemail_text,
                    complete: true,
                    url: this.props.generated_voicemail?.recording_url,
                    id:
                      Math.random().toString(36).substring(2, 15) +
                      Math.random().toString(36).substring(2, 15)
                  });

                  this.props.updateVoiceClone({
                    token: this.props.token,
                    type: "drop_voicemail",
                    dialer_provider,
                    call_id: this.props.active_call?.call_id,
                    recording_id: this.props.generated_voicemail?.id,
                    onLoading: () => {
                      this.setState(
                        {
                          drop_voicemail_loading: true
                        },
                        () => {
                          this.props.resetTelnyxDevice();
                        }
                      );
                    },
                    onError: () => {
                      this.setState({
                        drop_voicemail_loading: false
                      });
                    },
                    onSuccess: results => {
                      this.setState(
                        {
                          drop_voicemail_loading: false
                        },
                        () => {}
                      );
                    }
                  });
                }}
                icon={"voicemail"}
                textColor={colors.white_text_color}
                icon_color={colors.white_text_color}
                hover_color={colors.hover_white_color}
                loading={this.state.drop_voicemail_loading}
                disabled={!this.props.generated_voicemail}
              >
                Drop Voicemail
              </InlineButton>
            </Row>
          </>
        ) : null}
        {this.props.active_call?.call_status === "call_ended" ? (
          <Row>
            <Wrapper style={{ flex: 1 }}>
              {this.props.onboarding || this.props.demo_call ? (
                <Wrapper
                  className="sparkle-effect"
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <InlineButton
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "purchase_plan_prompt",
                        overlay_modal: true,
                        locked: true,
                        data: {
                          title:
                            "Get An AI-Powered Dialer With DealMachine Pro.",
                          dismiss_button:
                            "No thanks. Let me continue without an AI-Powered Dialer.",
                          no_free: true,
                          event_slug: "in_app_dialer_widget"
                        },
                        locked: true
                      });
                    }}
                    button_type={"full"}
                    textColor={colors.white_text_color}
                    icon_color={colors.white_text_color}
                  >
                    Get An AI-Powered Dialer With DealMachine Pro
                  </InlineButton>
                </Wrapper>
              ) : (
                <>
                  {next_number &&
                  !skip_to_next_lead &&
                  !skip_to_next_contact ? (
                    <>
                      <SelectItem
                        select_type={"icon"}
                        button_type={"full"}
                        loading={false}
                        onPress={() => {
                          this.props.nextNumberInQueue();
                        }}
                        selected_text_color={colors.active_color}
                        text_color={colors.active_color}
                        descriptionStyle={{
                          color: colors.white_text_color,
                          fontSize: 12
                        }}
                        icon={"keyboard-arrow-right"}
                        select_icon={"call"}
                        selected={true}
                        innerStyle={{ padding: 15 }}
                        disabled={this.props.summarize_loading}
                        tooltip={
                          this.props.summarize_loading
                            ? "Please wait while the conversation saves."
                            : ""
                        }
                        renderRight={
                          this.props.queued_numbers?.length > 1 &&
                          ((next_number && next_contact) ||
                            (next_number && next_lead) ||
                            (next_lead && next_contact))
                            ? () => {
                                return (
                                  <PopoverMenu
                                    popover_width={350}
                                    disabled={this.props.summarize_loading}
                                    renderComponent={options => {
                                      return (
                                        <IconButton
                                          icon={"more-vert"}
                                          disabled={
                                            this.props.summarize_loading
                                          }
                                          noPress={true}
                                          pressedIn={
                                            options.hovering ||
                                            options.pressed_in
                                          }
                                          icon_color={colors.white_text_color}
                                        />
                                      );
                                    }}
                                    menu_items={[
                                      next_number,
                                      next_contact,
                                      next_lead
                                    ]}
                                  />
                                );
                              }
                            : null
                        }
                        description={next_number.description}
                        subtext={next_number.subtext}
                      >
                        {this.props.active_dialer_contact?.incoming_call
                          ? "Continue Session"
                          : next_number.title}
                      </SelectItem>
                    </>
                  ) : next_contact && !skip_to_next_lead ? (
                    <>
                      <SelectItem
                        select_type={"icon"}
                        button_type={"full"}
                        loading={false}
                        onPress={() => {
                          this.props.nextContactInQueue();
                        }}
                        selected_text_color={colors.active_color}
                        text_color={colors.active_color}
                        descriptionStyle={{
                          color: colors.white_text_color,
                          fontSize: 12
                        }}
                        disabled={this.props.summarize_loading}
                        tooltip={
                          this.props.summarize_loading
                            ? "Please wait while the conversation saves."
                            : ""
                        }
                        tooltipPlacement={"top"}
                        icon={"keyboard-arrow-right"}
                        select_icon={"call"}
                        selected={true}
                        innerStyle={{ padding: 15 }}
                        renderRight={
                          this.props.queued_numbers?.length > 1 &&
                          ((next_number && next_contact) ||
                            (next_number && next_lead) ||
                            (next_lead && next_contact))
                            ? () => {
                                return (
                                  <PopoverMenu
                                    popover_width={350}
                                    disabled={this.props.summarize_loading}
                                    renderComponent={options => {
                                      return (
                                        <IconButton
                                          icon={"more-vert"}
                                          disabled={
                                            this.props.summarize_loading
                                          }
                                          noPress={true}
                                          pressedIn={
                                            options.hovering ||
                                            options.pressed_in
                                          }
                                          icon_color={colors.white_text_color}
                                        />
                                      );
                                    }}
                                    menu_items={[
                                      next_number,
                                      next_contact,
                                      next_lead
                                    ]}
                                  />
                                );
                              }
                            : null
                        }
                        description={next_contact.description}
                        subtext={next_contact.subtext}
                      >
                        {this.props.active_dialer_contact?.incoming_call
                          ? "Continue Session"
                          : next_contact.title}
                      </SelectItem>
                    </>
                  ) : next_lead ? (
                    <SelectItem
                      select_type={"icon"}
                      button_type={"full"}
                      loading={false}
                      onPress={() => {
                        this.props.nextLeadInQueue();
                      }}
                      selected_text_color={colors.active_color}
                      text_color={colors.active_color}
                      descriptionStyle={{
                        color: colors.white_text_color,
                        fontSize: 12
                      }}
                      icon={"keyboard-arrow-right"}
                      select_icon={"call"}
                      selected={true}
                      innerStyle={{ padding: 15 }}
                      renderRight={
                        this.props.queued_numbers?.length > 1 &&
                        ((next_number && next_contact) ||
                          (next_number && next_lead) ||
                          (next_lead && next_contact))
                          ? () => {
                              return (
                                <PopoverMenu
                                  popover_width={350}
                                  renderComponent={options => {
                                    return (
                                      <IconButton
                                        icon={"more-vert"}
                                        noPress={true}
                                        pressedIn={
                                          options.hovering || options.pressed_in
                                        }
                                        icon_color={colors.white_text_color}
                                      />
                                    );
                                  }}
                                  menu_items={[
                                    next_number,
                                    next_contact,
                                    next_lead
                                  ]}
                                />
                              );
                            }
                          : null
                      }
                      description={next_lead.description}
                      subtext={next_lead.subtext}
                    >
                      {this.props.active_dialer_contact?.incoming_call
                        ? "Continue Session"
                        : next_lead.title}
                    </SelectItem>
                  ) : (
                    <>
                      <Row>
                        {next_lead || next_contact || next_number ? (
                          <Wrapper style={{ width: 55 }} />
                        ) : null}
                        <Wrapper style={{ flex: 1 }}>
                          <InlineButton
                            onPress={() => {
                              if (
                                this.props.current_call_session &&
                                !this.props.active_dialer_contact
                                  ?.incoming_call &&
                                !this.props.active_dialer_contact?.one_off_call
                              ) {
                                this.props.completeSession(false);
                              } else {
                                this.props.closeSession();
                              }
                            }}
                            button_type={"full"}
                            textColor={colors.white_text_color}
                            icon_color={colors.white_text_color}
                            loading={this.props.complete_session_loading}
                          >
                            {this.props.current_call_session &&
                            !this.props.active_dialer_contact?.incoming_call &&
                            !this.props.active_dialer_contact?.one_off_call
                              ? "Complete Session"
                              : "Close Dialer"}
                          </InlineButton>
                        </Wrapper>
                        {next_lead || next_contact || next_number ? (
                          <PopoverMenu
                            popover_width={350}
                            renderComponent={options => {
                              return (
                                <IconButton
                                  icon={"more-vert"}
                                  noPress={true}
                                  pressedIn={
                                    options.hovering || options.pressed_in
                                  }
                                  icon_color={colors.white_text_color}
                                />
                              );
                            }}
                            menu_items={[next_number, next_contact, next_lead]}
                          />
                        ) : null}
                      </Row>
                    </>
                  )}
                </>
              )}
            </Wrapper>
          </Row>
        ) : (
          <Row>
            {this.renderSettingsMenu()}
            {!this.props.active_dialer_contact?.incoming_call &&
            this.props.active_call?.answered_by !== "machine" &&
            this.props.active_call.call_status !== "call_ended" &&
            this.props.active_call.call_status !== "answered" &&
            this.props.active_call.call_status !== "connected" &&
            !this.props.active_dialer_contact?.demo_call ? (
              <Wrapper
                style={{
                  width:
                    this.props.queued_numbers?.length > 1
                      ? 55
                      : this.props.current_call_session
                      ? 23
                      : 10
                }}
              />
            ) : null}

            <Wrapper style={{ flex: 1 }}>
              <InlineButton
                button_type={"full"}
                loading={
                  this.props.suggested_message_loading &&
                  !this.props.last_suggestion_id
                }
                onPress={this.props.suggestMessage}
                disabled={
                  this.props.suggested_message_loading ||
                  !!this.props.suggested_message ||
                  this.props.active_call.dropped_voicemail
                }
                textColor={colors.white_text_color}
                noCopy={true}
              >
                <Row>
                  {!this.props.suggested_message_loading ||
                  this.props.last_suggestion_id ? (
                    <Wrapper
                      style={{
                        height: 30,
                        width: 25,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <AlmaOrb active={true} size={18} />
                    </Wrapper>
                  ) : (
                    <Wrapper style={{ height: 30 }} />
                  )}
                  <Copy
                    style={{
                      color: colors.white_text_color,
                      marginLeft: 5
                    }}
                  >
                    {this.props.active_call?.answered_by === "machine" &&
                    !this.props.checkIfUserTalked()
                      ? "Generate me a voicemail script"
                      : "What should I say?"}
                  </Copy>
                </Row>
              </InlineButton>
            </Wrapper>

            {!this.props.onboarding &&
            !this.props.demo_call &&
            this.props.queued_numbers?.length > 1 &&
            !this.props.active_dialer_contact?.incoming_call &&
            this.props.active_call?.answered_by !== "machine" &&
            this.props.active_call.call_status !== "call_ended" &&
            this.props.active_call.call_status !== "answered" &&
            this.props.active_call.call_status !== "connected" ? (
              <Row>
                <IconButton
                  icon={"skip-next"}
                  icon_color={colors.white_text_color}
                  hover_color={colors.hover_white_color}
                  disabled={
                    (this.props.active_call.call_status === "loading" &&
                      this.props.countdown === 0 &&
                      !this.props.call_canceled) ||
                    this.props.summarize_loading
                  }
                  onPress={
                    skip_to_next_lead && next_lead
                      ? this.props.nextLeadInQueue
                      : skip_to_next_contact && next_contact
                      ? this.props.nextContactInQueue
                      : next_number
                      ? this.props.nextNumberInQueue
                      : next_contact
                      ? this.props.nextContactInQueue
                      : this.props.nextLeadInQueue
                  }
                  tooltip={
                    this.props.summarize_loading
                      ? "Please wait while the conversation saves."
                      : skip_to_next_lead && next_lead
                      ? "Call Next Lead: " + next_lead.subtext
                      : skip_to_next_contact && next_contact
                      ? "Call Next Contact: " + next_contact.subtext
                      : next_number
                      ? "Call Next Number: " + next_number.subtext
                      : next_contact
                      ? "Call Next Contact: " + next_contact.subtext
                      : "Call Next Lead: " + next_lead.subtext
                  }
                  tooltipPlacement={"top"}
                />
                {this.props.active_call?.answered_by !== "machine" &&
                this.props.active_call.call_status !== "call_ended" &&
                this.props.active_call.call_status !== "answered" &&
                this.props.active_call.call_status !== "connected" ? (
                  <PopoverMenu
                    disabled={
                      (this.props.active_call.call_status === "loading" &&
                        this.props.countdown === 0 &&
                        !this.props.call_canceled) ||
                      this.props.summarize_loading
                    }
                    renderComponent={options => {
                      return (
                        <IconButton
                          icon={"more-vert"}
                          disabled={
                            (this.props.active_call.call_status === "loading" &&
                              this.props.countdown === 0 &&
                              !this.props.call_canceled) ||
                            this.props.summarize_loading
                          }
                          tooltip={
                            this.props.summarize_loading
                              ? "Please wait while the conversation saves."
                              : ""
                          }
                          tooltipPlacement={"top"}
                          noPress={true}
                          pressedIn={options.hovering || options.pressed_in}
                          icon_color={colors.white_text_color}
                          hover_color={colors.hover_white_color}
                        />
                      );
                    }}
                    popover_width={350}
                    menu_items={[next_number, next_contact, next_lead]}
                  />
                ) : null}
              </Row>
            ) : !this.props.onboarding &&
              !this.props.demo_call &&
              this.props.queued_numbers?.length <= 1 &&
              !this.props.active_dialer_contact?.incoming_call &&
              !this.props.active_dialer_contact?.one_off_call &&
              this.props.active_call?.answered_by !== "machine" &&
              this.props.active_call.call_status !== "call_ended" &&
              this.props.active_call.call_status !== "answered" &&
              this.props.active_call.call_status !== "connected" ? (
              <InlineButton
                icon={this.props.current_call_session ? "stop" : ""}
                textColor={colors.white_text_color}
                icon_color={colors.white_text_color}
                hover_color={colors.hover_white_color}
                onPress={() => {
                  if (this.props.current_call_session) {
                    this.props.completeSession(true);
                  } else {
                    this.props.closeSession();
                  }
                }}
                tooltip={
                  this.props.current_call_session
                    ? "End Session"
                    : "Close Dialer"
                }
                tooltipPlacement={"top"}
              >
                {this.props.current_call_session ? "End" : "Close"}
              </InlineButton>
            ) : (
              <Wrapper style={{ width: 55 }} />
            )}
          </Row>
        )}
      </BottomNavBar>
    );
  }
}

const mapStateToProps = ({ auth, settings, dialer, native, billing }) => {
  const { token, user } = auth;
  const { device, desktopMobile } = native;
  const { dark_mode, colors } = settings;
  const {
    active_dialer_contact,
    active_call,
    current_conversation,
    current_call_session,
    queued_numbers,
    default_dialer_settings
  } = dialer;
  const { source_of_truth } = billing;

  return {
    token,
    user,
    dark_mode,
    device,
    desktopMobile,
    colors,
    active_dialer_contact,
    active_call,
    current_conversation,
    current_call_session,
    queued_numbers,
    default_dialer_settings,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  updateActiveCall,
  setActiveDialerContact,
  updateUserSettings,
  updateVoiceClone,
  getQueuedNumbers,
  getCallSession,
  pushSidePanel
})(ActionButtons);
