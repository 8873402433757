import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Bold,
  Wrapper,
  Copy,
  ContentfulRichText,
  ProgressBar,
  Card,
  Row,
  Button
} from "app/NativeComponents/common";
import { Header1, Header3, QRCodeWrapper } from "app/NativeComponents/common";

import {
  logout,
  loadOnboardingText,
  startRedirect,
  updateUser,
  tabNavigation,
  getMarketingOnboardingData,
  updateUserSettings,
  pushSidePanel,
  checkIfUserHasMetadata
} from "app/NativeActions";

import {
  GhostButton,
  InlineButton,
  List,
  OnboardingWrapper,
  SpinWrapper,
  AttentionBox
} from "app/NativeComponents/snippets";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";
import StatBlock from "./StatBlock";
class SelectLeadStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      leads: [],
      leads_loading: false,
      selecting_lead: false,
      loading_id: null,
      lead_count: 0,
      phone_number_count: 0,
      email_count: 0,
      contact_count: 0,
      selected_lead: null
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/select-a-lead");
    }

    this.props.getMarketingOnboardingData({
      token: this.props.token,
      type: "get_leads_from_list",
      onLoading: () => {
        this.setState({ leads_loading: true });
      },
      onError: () => {
        this.setState({ leads_loading: true });
      },
      onSuccess: results => {
        this.setState({
          leads_loading: false,
          leads: results?.leads,
          lead_count: results?.lead_count,
          phone_number_count: results?.phone_number_count,
          email_count: results?.email_count,
          contact_count: results?.contact_count
        });
      }
    });
  }

  render() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, isMobile } = this.props;

    return (
      <Container title={"Select a lead"}>
        <OnboardingWrapper>
          <>
            {this.props.visitor?.marketing_experiments?.dealmachine_free ===
              "dealmachine_free" && this.props.device === "desktop" ? (
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 25
                }}
              >
                <Header1
                  style={{
                    textAlign: "center"
                  }}
                >
                  🤩 <Bold>Behold. Unlimited phone numbers.</Bold>
                </Header1>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  Upgrade to pro and never pay for skip tracing again.
                </Header3>
              </Wrapper>
            ) : (
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 25
                }}
              >
                <Header1
                  style={{
                    textAlign: "center"
                  }}
                >
                  🤩 <Bold>That's a nice list you've got there.</Bold>
                </Header1>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  Let's analyze one of your leads. Select a lead to continue.
                </Header3>
              </Wrapper>
            )}

            {this.props.visitor?.marketing_experiments?.dealmachine_free ===
            "dealmachine_free" ? (
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: 25
                }}
              >
                <Copy style={{ textAlign: "center" }}>
                  Tutorial step 2 of 5.
                </Copy>
                <ProgressBar
                  style={{ marginTop: 10 }}
                  color={colors.active_color}
                  backgroundColor={colors.white_color}
                  width={200}
                  progress={2 / 5}
                />
              </Wrapper>
            ) : (
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: 25
                }}
              >
                <Copy style={{ textAlign: "center" }}>
                  Tutorial step 2 of 4.
                </Copy>
                <ProgressBar
                  style={{ marginTop: 10 }}
                  color={colors.active_color}
                  backgroundColor={colors.white_color}
                  width={200}
                  progress={2 / 4}
                />
              </Wrapper>
            )}

            <Wrapper
              style={
                this.props.device === "mobile" || this.props.desktopMobile
                  ? {
                      flexDirection: "column-reverse"
                    }
                  : {
                      alignSelf: "stretch",
                      flexDirection: "row",
                      alignItems: "center"
                    }
              }
            >
              <Wrapper
                style={
                  this.props.device === "mobile" || this.props.desktopMobile
                    ? {}
                    : {
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center"
                      }
                }
              >
                <Wrapper
                  style={{
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Card
                    style={{
                      borderRadius: 30,
                      height: 750,
                      margin: this.props.device === "desktop" ? "0px auto" : "",
                      width:
                        this.props.device === "mobile" ||
                        this.props.desktopMobile
                          ? "100%"
                          : 500,
                      boxShadow: "0 24px 64px rgba(38,33,74,.1)",
                      overflow: "hidden"
                    }}
                  >
                    {this.state.leads_loading ? (
                      <Wrapper
                        style={{
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <SpinWrapper text={"Loading Properties"} />
                      </Wrapper>
                    ) : (
                      <>
                        <Wrapper
                          style={{
                            padding: 15,
                            borderBottomWidth: 1,
                            borderBottomColor: colors.border_color,
                            borderBottomStyle: "solid"
                          }}
                        >
                          <Copy>
                            <Bold>
                              My List ({this.state.leads.length} properties):{" "}
                            </Bold>
                          </Copy>
                          <Copy
                            style={{
                              fontSize: 12,
                              color: colors.light_text_color
                            }}
                          >
                            Select a property below to continue.
                          </Copy>
                        </Wrapper>
                        <List
                          rowNumber={1}
                          style={{ flex: 1, alignSelf: "stretch" }}
                          infiniteScroll={false}
                          items={this.state.leads}
                          itemStructure={({ item, index }) => {
                            return (
                              <Button
                                onPress={() => {
                                  this.setState({
                                    selected_lead: item
                                  });
                                }}
                                style={
                                  item?.id === this.state.selected_lead?.id
                                    ? {
                                        overflow: "hidden",
                                        borderWidth: 2,
                                        borderColor:
                                          colors.actionable_text_color,
                                        borderStyle: "solid",
                                        borderRadius: 5,
                                        backgroundColor:
                                          colors.active_color_muted,
                                        margin: 5
                                      }
                                    : {
                                        overflow: "hidden",
                                        borderWidth: 2,
                                        borderColor: "transparent",
                                        borderStyle: "solid",
                                        borderRadius: 5,
                                        margin: 5
                                      }
                                }
                              >
                                <LeadItem
                                  key={"item_" + index}
                                  image_toggle={
                                    this.props.device === "mobile" ||
                                    this.props.desktopMobile
                                      ? "none"
                                      : ""
                                  }
                                  include_add_button={false}
                                  include_check_icon={false}
                                  property={item}
                                  loading={
                                    this.state.loading_id === item?.id &&
                                    this.state.selecting_lead
                                  }
                                  contact_preview={true}
                                  noPress={true}
                                  onPress={() => {}}
                                />
                              </Button>
                            );
                          }}
                          canRefresh={false}
                          canLoadMore={false}
                        />
                      </>
                    )}
                  </Card>
                </Wrapper>
              </Wrapper>

              <Wrapper
                style={
                  this.props.device === "desktop" && !this.props.desktopMobile
                    ? { width: 650 }
                    : {}
                }
              >
                <Wrapper
                  style={{
                    padding: 25,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Header3
                    style={{
                      textAlign: "center",
                      margin: 0,
                      marginBottom: 25
                    }}
                  >
                    That's a nice list you got there.
                  </Header3>
                  <Wrapper style={{ marginBottom: 10 }}>
                    <Copy style={{ textAlign: "center" }}>
                      {"With DealMachine Pro, every property you add includes "}
                      <Bold>
                        phone numbers, email addresses and other contact info
                      </Bold>
                      {" automatically if we have it."}
                    </Copy>
                  </Wrapper>
                  <Wrapper style={{ marginBottom: 10, textAlign: "center" }}>
                    <Copy style={{ textAlign: "center" }}>
                      We average a <Bold>95% match rate</Bold> for all single
                      family properties across the United States.
                    </Copy>
                  </Wrapper>
                  {!this.state.leads_loading && this.state.leads.length > 0 ? (
                    <>
                      <Row style={{ alignSelf: "stretch", flex: 1 }}>
                        <StatBlock
                          title={"Phone Numbers"}
                          number={this.state.phone_number_count}
                        />
                        <StatBlock
                          title={"Email Addresses"}
                          number={this.state.email_count}
                        />
                        <StatBlock
                          title={"Contacts"}
                          number={this.state.contact_count}
                        />
                      </Row>
                      <Wrapper
                        style={{ marginBottom: 20, textAlign: "center" }}
                      >
                        <Copy style={{ textAlign: "center" }}>
                          With this list of{" "}
                          <Bold>{this.state.lead_count} properties</Bold> we've
                          added{" "}
                          <Bold>
                            {this.state.phone_number_count} phone numbers
                          </Bold>
                          ,{" "}
                          <Bold>{this.state.email_count} email addresses</Bold>{" "}
                          and <Bold>{this.state.contact_count} contacts</Bold>{" "}
                          to your account. You just need Pro to unlock them.
                        </Copy>
                      </Wrapper>
                    </>
                  ) : null}

                  {!checkIfUserHasMetadata("people_data") ? (
                    <Wrapper
                      className="sparkle-effect"
                      style={{
                        margin: 10,
                        marginRight: 5,
                        marginLeft: 5,
                        borderRadius: 30
                      }}
                    >
                      <InlineButton
                        style={{
                          borderRadius: 30,
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: colors.active_color_muted
                        }}
                        loading={this.state.upgrading}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "purchase_plan_prompt",
                            overlay_modal: true,
                            locked: true,
                            event_slug: "onboarding_select_lead_step",
                            data: {
                              title: "Close More Deals With DealMachine Pro.",
                              dismiss_button:
                                "No thanks. Let's continue without phone numbers, emails & contacts.",
                              no_free: true,
                              event_slug: "onboarding_select_lead_step"
                            },
                            locked: true
                          });
                        }}
                        icon_right="keyboard-arrow-right"
                        selected={true}
                        right_icon="keyboard-arrow-right"
                      >
                        I've heard enough! I want to buy a Pro plan!
                      </InlineButton>
                    </Wrapper>
                  ) : null}

                  <Wrapper
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      textAlign: "center"
                    }}
                  >
                    <Copy>
                      <Bold>OR</Bold>
                    </Copy>
                  </Wrapper>

                  <Copy style={{ textAlign: "center" }}>
                    To continue the demo,{" "}
                    <Bold>select one of the properties</Bold> in your list. Next
                    we'll analyze the property and determine if it's a good
                    property to invest in.
                  </Copy>
                </Wrapper>
                <Wrapper style={{ flex: 1 }}>
                  <InlineButton
                    button_type="full"
                    loading={this.state.skipping}
                    onPress={() => {
                      const lead_id = this.state.selected_lead
                        ? this.state.selected_lead?.deal?.id
                        : this.state.leads[0]?.deal?.id;

                      this.props.updateUserSettings({
                        token: this.props.token,
                        type: "selected_onboarding_lead",
                        value: lead_id,
                        onLoading: () => {
                          this.setState({
                            skipping: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            skipping: false
                          });
                        },
                        onSuccess: () => {
                          this.props.updateUser({
                            token: this.props.token,
                            type: "onboarding_stepthrough_step",
                            payload: {
                              onboarding_stepthrough_current_step: "alma_step"
                            },
                            onLoading: () => {},
                            onError: () => {},
                            onSuccess: () => {
                              this.setState({
                                skipping: false
                              });
                            },
                            no_loading: true
                          });
                        }
                      });
                    }}
                    icon_right="keyboard-arrow-right"
                  >
                    {this.state.selected_lead
                      ? "Continue with this selected property"
                      : "Skip and select a property for me"}
                  </InlineButton>
                </Wrapper>
              </Wrapper>
            </Wrapper>
          </>
        </OnboardingWrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect, desktopMobile } = native;
  const { visitor } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth,
    desktopMobile,
    visitor
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,

  tabNavigation,
  getMarketingOnboardingData,
  updateUserSettings,
  updateUser,
  pushSidePanel
})(SelectLeadStep);
