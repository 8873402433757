import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Bold,
  Wrapper,
  Row,
  Card,
  Copy,
  StatusBarStyle,
  ProgressBar
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  GhostButton,
  InlineButton,
  OnboardingWrapper,
  SelectMultiple,
  SmallLabel
} from "app/NativeComponents/snippets";
import { Header1, Header3 } from "app/NativeComponents/common";
import MainMap from "app/DealMachineCore/components/MainMap";

import { getGreatCircleBearing } from "geolib";
import {
  checkIfHasGoogleStreetPic,
  setStreetViewCoords,
  toggleVirtualDriveStart,
  getMarketingOnboardingData,
  showErrorMessage,
  isLocationInUSA,
  getHelperDescription,
  numberWithCommas
} from "app/NativeActions";

import {
  logout,
  loadOnboardingText,
  startRedirect,
  updateUser,
  updateMainMapLocation,
  updateMapFilters,
  startBuildingListv2
} from "app/NativeActions";
import MapInner from "app/NativeComponents/components/NativeMainMap/MapInner";
class AddPropertyStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      checked_pic: false,
      failedGoogleStreetPic: false,
      street_view_data: null,
      quick_filter: "",
      build_list_count: 0,
      build_list_count_loading: true,
      zips: [],
      loading_zips: false,
      selected_zip: null,
      start_building: false,
      onboading_step_loading: false,
      list_building_step: "choose_filter"
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/build-a-list");
    }
    this.loadData("build_list_step");

    //get list of zip codes

    this.props.getMarketingOnboardingData({
      token: this.props.token,
      type: "get_zips_near_me",
      onLoading: () => {
        this.setState({ loading_zips: true });
      },
      onError: () => {
        this.setState({ loading_zips: true });
      },
      onSuccess: results => {
        if (results?.locations && results?.locations.length > 0) {
          this.setState({
            loading_zips: false,
            zips: results?.locations
          });
        } else {
          this.setState({ loading_zips: false });
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selected_zip !== prevState.selected_zip) {
      this.props.updateMapFilters({
        search_locations: !!this.state.selected_zip
          ? [
              {
                type: "zip",
                value: this.state.selected_zip.zip,
                label: this.state.selected_zip.zip,
                value2: null,
                latitude: this.state.selected_zip?.latitude,
                longitude: this.state.selected_zip?.longitude,
                coordinates: this.state.selected_zip?.coordinates
              }
            ]
          : null
      });
    }
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  checkGoogleForStreetPicData({ address, coordinates }) {
    checkIfHasGoogleStreetPic({
      address,
      coordinates,
      onSuccess: results => {
        this.setState({
          checked_pic: true,
          failedGoogleStreetPic: results.status == "OK" ? false : true,
          street_view_data: results
        });
      }
    });
  }

  render() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, isMobile } = this.props;

    return (
      <Container title={"👋 Welcome to DealMachine!"}>
        <OnboardingWrapper>
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25
              }}
            >
              <Header1
                style={{
                  textAlign: "center",
                  color: colors.text_color
                }}
              >
                <Bold>👋 Welcome to DealMachine!</Bold>
              </Header1>
              <Header3
                style={{
                  textAlign: "center",
                  color: colors.text_color
                }}
              >
                Complete this quick tutorial and we'll take you to your account.
              </Header3>
            </Wrapper>

            {this.props.visitor?.marketing_experiments?.dealmachine_free ===
            "dealmachine_free" ? (
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: 25
                }}
              >
                <Copy style={{ textAlign: "center" }}>
                  Tutorial step 1 of 5.
                </Copy>
                <ProgressBar
                  style={{ marginTop: 10 }}
                  color={colors.active_color}
                  backgroundColor={colors.white_color}
                  width={200}
                  progress={1 / 5}
                />
              </Wrapper>
            ) : (
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: 25
                }}
              >
                <Copy style={{ textAlign: "center" }}>
                  Tutorial step 1 of 4.
                </Copy>
                <ProgressBar
                  style={{ marginTop: 10 }}
                  color={colors.active_color}
                  backgroundColor={colors.white_color}
                  width={200}
                  progress={1 / 4}
                />
              </Wrapper>
            )}

            <Row
              style={
                this.props.desktopMobile || this.props.device === "mobile"
                  ? { alignSelf: "stretch", flexDirection: "column" }
                  : { alignSelf: "stretch" }
              }
            >
              <Wrapper style={{ flex: 1 }}>
                <Card
                  style={
                    this.props.desktopMobile || this.props.device === "mobile"
                      ? {
                          overflow: "hidden",
                          height: 180,
                          width: 300,
                          margin:
                            this.props.device === "desktop" ? "0px auto" : 0,
                          borderRadius: 5
                        }
                      : { overflow: "hidden", height: 500 }
                  }
                >
                  {this.props.device === "mobile" ? (
                    <>
                      <MapInner
                        onboarding_map={true}
                        onboarding_list_builder={true}
                        onOnboardingMapLoading={loading => {
                          this.setState({
                            build_list_count_loading: loading
                          });
                        }}
                        onboarding_filter={this.state.quick_filter}
                        onOnboardingMapChange={info => {
                          this.setState({
                            build_list_count: info?.properties_on_map_count,
                            build_list_count_loading: false
                          });
                        }}
                      />
                    </>
                  ) : this.props.user && this.props.user?.user_settings ? (
                    <MainMap
                      onboarding_map={true}
                      onOnboardingMapLoading={loading => {
                        this.setState({
                          build_list_count_loading: loading
                        });
                      }}
                      onboarding_filter={this.state.quick_filter}
                      onOnboardingMapChange={info => {
                        this.setState({
                          build_list_count: info?.properties_on_map_count,
                          build_list_count_loading: false
                        });
                      }}
                    />
                  ) : null}
                </Card>
              </Wrapper>

              <Wrapper
                style={
                  this.props.desktopMobile || this.props.device === "mobile"
                    ? {}
                    : { width: 450, color: colors.text_color }
                }
              >
                <Wrapper style={{ padding: 25, paddingBottom: 15 }}>
                  {!this.state.quick_filter ? (
                    <>
                      <Header3
                        style={{ marginBottom: 10, color: colors.text_color }}
                      >
                        <Bold>Build a list to get started.</Bold>
                      </Header3>
                      <Copy style={{ marginBottom: 10 }}>
                        Building a list adds properties as "leads" to your
                        account. Select your criteria using our quick filters or
                        build a custom criteria set using our in depth filters.
                        For now, we've selected a few of our quick filter
                        options for you to choose from.
                      </Copy>
                    </>
                  ) : (
                    <SmallLabel style={{ paddingLeft: 0, paddingRight: 0 }}>
                      Select a filter:
                    </SmallLabel>
                  )}

                  <Row style={{ marginBottom: 10, flexWrap: "wrap" }}>
                    <InlineButton
                      icon={
                        this.state.quick_filter === "off_market"
                          ? "radio-button-checked"
                          : "radio-button-unchecked"
                      }
                      onPress={() =>
                        this.setState({ quick_filter: "off_market" })
                      }
                      selected={this.state.quick_filter === "off_market"}
                      style={
                        this.state.quick_filter === "off_market"
                          ? {
                              borderColor: colors.active_color,
                              borderWidth: 1,
                              borderStyle: "solid",
                              marginLeft: 0
                            }
                          : { marginLeft: 0 }
                      }
                      tooltip={getHelperDescription("off_market")}
                      tooltipPlacement={"top"}
                    >
                      Off Market
                    </InlineButton>

                    <InlineButton
                      icon={
                        this.state.quick_filter === "vacant_home"
                          ? "radio-button-checked"
                          : "radio-button-unchecked"
                      }
                      onPress={() =>
                        this.setState({ quick_filter: "vacant_home" })
                      }
                      selected={this.state.quick_filter === "vacant_home"}
                      style={
                        this.state.quick_filter === "vacant_home"
                          ? {
                              borderColor: colors.active_color,
                              borderWidth: 1,
                              borderStyle: "solid",
                              marginLeft: 0
                            }
                          : { marginLeft: 0 }
                      }
                      tooltip={getHelperDescription("vacant_home")}
                      tooltipPlacement={"top"}
                    >
                      Vacant Home
                    </InlineButton>

                    <InlineButton
                      icon={
                        this.state.quick_filter === "absentee_owner"
                          ? "radio-button-checked"
                          : "radio-button-unchecked"
                      }
                      onPress={() =>
                        this.setState({ quick_filter: "absentee_owner" })
                      }
                      selected={this.state.quick_filter === "absentee_owner"}
                      style={
                        this.state.quick_filter === "absentee_owner"
                          ? {
                              borderColor: colors.active_color,
                              borderWidth: 1,
                              borderStyle: "solid",
                              marginLeft: 0
                            }
                          : { marginLeft: 0 }
                      }
                      tooltip={getHelperDescription("absentee_owner")}
                      tooltipPlacement={"top"}
                    >
                      Absentee Owner
                    </InlineButton>

                    <SelectMultiple
                      style={
                        this.props.device === "desktop"
                          ? {
                              height: 50
                            }
                          : {}
                      }
                      title={"Quick Filters"}
                      innerStyle={{
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                      show={this.state.show_quick_filters}
                      onShow={s => {
                        this.setState({
                          show_quick_filters: s
                        });
                      }}
                      options={[
                        {
                          label: "MLS Active",
                          value: "mls_active",
                          helper: getHelperDescription("mls_active"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "MLS Pending",
                          value: "mls_pending",
                          helper: getHelperDescription("mls_pending"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "MLS Contingent",
                          value: "mls_contingent",
                          helper: getHelperDescription("mls_contingent"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Recently Sold",
                          value: "recently_sold",
                          helper: getHelperDescription("recently_sold")
                        },
                        {
                          label: "Expired Listings",
                          value: "expired_listing",
                          helper: getHelperDescription("expired_listing"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Out of State Owners",
                          value: "out_of_state_owner",
                          helper: getHelperDescription("out_of_state_owner")
                        },

                        {
                          label: "Corporate Owners",
                          value: "corporate_owner",
                          helper: getHelperDescription("corporate_owner")
                        },

                        {
                          label: "Adjustable Loans",
                          value: "adjustable_loan",
                          helper: getHelperDescription("adjustable_loan"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Bank Owned",
                          value: "bank_owned",
                          helper: getHelperDescription("bank_owned"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Cash Buyers",
                          value: "cash_buyer",
                          helper: getHelperDescription("cash_buyer")
                        },
                        {
                          label: "Free and Clear",
                          value: "free_and_clear",
                          helper: getHelperDescription("free_and_clear")
                        },
                        {
                          label: "High Equity",
                          value: "high_equity",
                          helper: getHelperDescription("high_equity"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Low Equity",
                          value: "low_equity",
                          helper: getHelperDescription("low_equity"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Tax Delinquent",
                          value: "tax_delinquent",
                          helper: getHelperDescription("tax_delinquent"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Preforeclosures",
                          value: "preforeclosure",
                          helper: getHelperDescription("preforeclosure"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Foreclosures",
                          value: "foreclosure",
                          helper: getHelperDescription("foreclosure"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Probates",
                          value: "pre_probate",
                          helper: getHelperDescription("pre_probate"),
                          description: "Not available in this tutorial.",
                          locked: true
                        },
                        {
                          label: "Senior Owners",
                          value: "senior_owner",
                          helper: getHelperDescription("senior_owner")
                        },
                        {
                          label: "Tired Landlords",
                          value: "tired_landlord",
                          helper: getHelperDescription("tired_landlord")
                        },

                        {
                          label: "Zombie Properties",
                          value: "zombie_property",
                          helper: getHelperDescription("zombie_property"),
                          description: "Not available in this tutorial.",
                          locked: true
                        }
                      ]}
                      selected_options={
                        !!this.state.quick_filter
                          ? [
                              {
                                label: this.state.quick_filter
                                  .split("_")
                                  .map(
                                    word =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" "),
                                value: this.state.quick_filter
                              }
                            ]
                          : []
                      }
                      disabled={false}
                      placeholder={"Quick Filters"}
                      search_title="Search Filters"
                      icon_right={"keyboard-arrow-down"}
                      select_type={"radio"}
                      include_search={false}
                      include_tags={false}
                      renderComponent={options => {
                        return (
                          <InlineButton
                            disabled={false}
                            selected={false}
                            icon={
                              !!this.state.quick_filter &&
                              this.state.quick_filter !== "absentee_owner" &&
                              this.state.quick_filter !== "vacant_home" &&
                              this.state.quick_filter !== "off_market"
                                ? "radio-button-checked"
                                : "keyboard-arrow-down"
                            }
                            pressedIn={options?.hovering || options?.pressedIn}
                            noPress={true}
                            style={
                              !!this.state.quick_filter &&
                              this.state.quick_filter !== "absentee_owner" &&
                              this.state.quick_filter !== "vacant_home" &&
                              this.state.quick_filter !== "off_market"
                                ? {
                                    borderColor: colors.active_color,
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    marginLeft: 0
                                  }
                                : { marginLeft: 0 }
                            }
                          >
                            {/*uppercase this.state.quick_filter and change _ to a space*/}

                            {!!this.state.quick_filter &&
                            this.state.quick_filter !== "absentee_owner" &&
                            this.state.quick_filter !== "vacant_home" &&
                            this.state.quick_filter !== "off_market"
                              ? this.state.quick_filter
                                  .split("_")
                                  .map(
                                    word =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")
                              : "All Filters"}
                          </InlineButton>
                        );
                      }}
                      includeCloseButton={false}
                      onSelectedOptionsChange={options => {
                        this.setState({
                          quick_filter:
                            options?.length > 0 ? options[0].value : "",
                          show_quick_filters: false
                        });
                      }}
                    />
                  </Row>

                  {!!this.state.quick_filter ? (
                    <>
                      {!this.state.selected_zip ? (
                        <Wrapper style={{ marginBottom: 10 }}>
                          <Copy>
                            Now choose a location. We've found some zip codes
                            around your location you can choose from.
                          </Copy>
                        </Wrapper>
                      ) : (
                        <SmallLabel style={{ paddingLeft: 0, paddingRight: 0 }}>
                          Select a location:
                        </SmallLabel>
                      )}

                      <Row style={{ marginBottom: 10, flexWrap: "wrap" }}>
                        {!this.state.zips_loading &&
                          this.state.zips?.length > 0 &&
                          this.state.zips.map((zip, i) => {
                            return (
                              <InlineButton
                                key={i}
                                icon={
                                  this.state.selected_zip?.zip === zip?.zip
                                    ? "radio-button-checked"
                                    : "radio-button-unchecked"
                                }
                                onPress={() =>
                                  this.setState({ selected_zip: zip })
                                }
                                selected={
                                  this.state.selected_zip?.zip === zip?.zip
                                }
                                style={
                                  this.state.selected_zip?.zip === zip?.zip
                                    ? {
                                        borderColor: colors.active_color,
                                        borderWidth: 1,
                                        borderStyle: "solid",
                                        marginLeft: 0
                                      }
                                    : { marginLeft: 0 }
                                }
                              >
                                {zip?.zip}
                              </InlineButton>
                            );
                          })}
                      </Row>
                    </>
                  ) : null}
                  {!!this.state.quick_filter && this.state.selected_zip ? (
                    <>
                      {this.state.build_list_count > 0 ? (
                        <>
                          {this.state.build_list_count > 100 ? (
                            <Copy>
                              Your list will contain{" "}
                              <Bold>
                                {numberWithCommas(this.state.build_list_count)}
                              </Bold>{" "}
                              leads. Since this is only the tutorial we're going
                              to limit your list to only <Bold>100 leads</Bold>.
                              Press the button below to continue.
                            </Copy>
                          ) : (
                            <Copy>
                              Your list will contain{" "}
                              <Bold>
                                {numberWithCommas(this.state.build_list_count)}
                              </Bold>{" "}
                              leads. Let's build this list. Press the button
                              below to continue.
                            </Copy>
                          )}
                        </>
                      ) : this.state.build_list_count === 0 &&
                        !this.state.build_list_count_loading ? (
                        <Copy>
                          Your list will contain <Bold>0 leads</Bold>. Select
                          another location or quick filter to find an area with
                          properties matching your criteria.
                        </Copy>
                      ) : (
                        <Copy>Loading list count...</Copy>
                      )}
                      <Wrapper style={{ paddingTop: 10 }}>
                        <GhostButton
                          disabled={
                            this.state.build_list_count_loading ||
                            this.state.zips_loading
                          }
                          loading={
                            this.state.build_list_count_loading ||
                            this.state.zips_loading ||
                            this.state.onboading_step_loading
                          }
                          onPress={() => {
                            this.props.startBuildingListv2({
                              token: this.props.token,
                              title: "My List",
                              location_type: "onboarding",
                              zip: this.state.selected_zip?.zip,
                              estimated_count: this.state.build_list_count,
                              property_flags: this.state.quick_filter,
                              property_types: "single_family",
                              search_locations:
                                this.props.map_filters?.search_locations,

                              onLoading: () => {
                                this.setState({
                                  start_building: true,
                                  onboading_step_loading: true
                                });
                              },
                              onError: error => {
                                this.setState({
                                  start_building: false,
                                  onboading_step_loading: false
                                });
                              },
                              onSuccess: results => {
                                this.setState(
                                  {
                                    start_building: false
                                  },
                                  () => {
                                    this.props.updateUser({
                                      token: this.props.token,
                                      type: "onboarding_stepthrough_step",
                                      payload: {
                                        onboarding_stepthrough_current_step:
                                          "select_lead_step"
                                      },
                                      onLoading: () => {},
                                      onError: () => {},
                                      onSuccess: () => {
                                        this.props.updateMapFilters({
                                          search_locations: []
                                        });
                                        this.setState({
                                          onboading_step_loading: false
                                        });
                                      },
                                      no_loading: true
                                    });
                                  }
                                );
                              }
                            });
                          }}
                          button_type="full"
                        >
                          Build List
                        </GhostButton>
                      </Wrapper>

                      <Wrapper style={{ paddingTop: 15 }}>
                        <Copy
                          style={{
                            fontSize: 12,
                            color: colors.light_text_color
                          }}
                        >
                          Building this list will add leads to your account but
                          will not count towards your "List Builder" limit. You
                          can remove these leads later if you'd like.
                        </Copy>
                      </Wrapper>
                    </>
                  ) : null}
                </Wrapper>
              </Wrapper>
            </Row>

            {/* removed after test confirmed
            <Wrapper
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 25
                }}
              >
                <InlineButton
                  loading={this.state.skip_loading}
                  onPress={() => {
                    this.props.getMarketingOnboardingData({
                      token: this.props.token,
                      type: "skip_and_add_lead",
                      onLoading: () => {
                        this.setState({ skip_loading: true });
                      },
                      onError: () => {
                        this.setState({ skip_loading: false });
                      },
                      onSuccess: results => {
                        if (results?.lead) {
                          this.props.updateUser({
                            token: this.props.token,
                            type: "onboarding_stepthrough_step",
                            payload: {
                              onboarding_stepthrough_current_step: "alma_step"
                            },
                            onLoading: () => {},
                            onError: () => {
                              this.setState({
                                skip_loading: false
                              });
                            },
                            onSuccess: () => {},
                            no_loading: true
                          });
                        } else {
                          this.setState({ skip_loading: false });
                          this.props.showErrorMessage(
                            "Something went wrong.",
                            "Error"
                          );
                        }
                      }
                    });
                  }}
                  style={{ textAlign: "center" }}
                  marketing_slug={"skipped_driving_on_map_onboarding"}
                  icon_right={"arrow-right-alt"}
                >
                  {"Skip and add a lead for me."}
                </InlineButton>
              </Wrapper>
            */}
          </>
        </OnboardingWrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  billing,
  settings,
  native,
  property_map,
  marketing
}) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect, desktopMobile } = native;
  const { main_map_location, map_filters } = property_map;
  const { visitor } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    desktopMobile,
    device,
    initialRedirect,
    source_of_truth,
    main_map_location,
    map_filters,
    visitor
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  updateUser,
  setStreetViewCoords,
  toggleVirtualDriveStart,
  updateMainMapLocation,
  getMarketingOnboardingData,
  showErrorMessage,
  isLocationInUSA,
  updateMapFilters,
  startBuildingListv2
})(AddPropertyStep);
