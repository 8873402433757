import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Page,
  Row,
  Copy,
  Wrapper,
  ProgressBar
} from "app/NativeComponents/common";

import {
  logout,
  startRedirect,
  updateUser,
  submitMarketingForm
} from "app/NativeActions";

import HowManyDealsQuestion from "./HowManyDealsQuestion";
import HowExperiencedAreYou from "./HowExperiencedAreYou";
import HowDidYouHearAboutDealMachine from "./HowDidYouHearAboutDealMachine";
import HowDoYouPlanOnUsingDealMachine from "./HowDoYouPlanOnUsingDealMachine";

import { InlineButton, OnboardingWrapper } from "app/NativeComponents/snippets";
import WhatIsYourIndustry from "./WhatIsYourIndustry";

class QualifyingQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 1,
      total_steps: 4,
      next_step_loading: false
    };

    this.nextStep = this.nextStep.bind(this);
    this.lastStep = this.lastStep.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
  }

  componentDidMount() {}

  submitAnswer({ property_slug, property_value }) {
    //send each answer over to hubspot

    //qualify lead so we can default to annual
    if (property_slug === "how_many_deals_have_you_done") {
      if (
        property_value === "I've done more than 10 deals" ||
        property_value === "I've done 1-10 deals"
      ) {
        this.props.onQualifyLead();
      }
    } else if (property_slug === "how_experienced_are_you") {
      if (property_value === "I'm an experienced professional") {
        this.props.onQualifyLead();
      }
    }

    if (
      property_slug ===
      "how_do_you_plan_on_using_dealmachine_for_your_rei_business"
    ) {
      if (property_value.toLowerCase().includes("cold calling")) {
        this.props.onNeedsDialer();
      }
    }

    this.props.submitMarketingForm({
      token: this.props.token,
      type: "collect_qualifying_data",
      property_slug,
      property_value
    });
  }

  lastStep() {
    const { progress } = this.state;

    this.setState({
      progress: progress > 1 ? progress - 1 : 1
    });
  }

  nextStep() {
    const { total_steps, progress } = this.state;
    if (progress === total_steps) {
      const next_step =
        this.props.visitor?.marketing_experiments?.dealmachine_free ===
          "dealmachine_free" && this.props.device === "desktop"
          ? "build_list_step"
          : "select_plan_option_step";

      this.props.updateUser({
        token: this.props.token,
        type: "onboarding_stepthrough_step",
        payload: {
          onboarding_stepthrough_current_step: next_step
        },
        onLoading: () => {
          this.setState({
            next_step_loading: true
          });
        },
        onError: () => {
          this.setState({
            next_step_loading: false
          });
        },
        onSuccess: () => {},
        no_loading: true
      });
    } else {
      this.setState({
        progress: progress + 1
      });
    }
  }

  renderQuestion() {
    const { progress } = this.state;

    switch (progress) {
      case 4:
        return (
          <HowDidYouHearAboutDealMachine
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            submitAnswer={this.submitAnswer}
            next_step_loading={this.state.next_step_loading}
          />
        );
      case 3:
        if (
          this.props.user?.industry === "Real Estate Investing" ||
          this.props.user?.industry === "Real Estate Wholesaling"
        ) {
          return (
            <HowManyDealsQuestion
              nextStep={this.nextStep}
              lastStep={this.lastStep}
              submitAnswer={this.submitAnswer}
              next_step_loading={this.state.next_step_loading}
            />
          );
        }

        return (
          <HowExperiencedAreYou
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            submitAnswer={this.submitAnswer}
            next_step_loading={this.state.next_step_loading}
          />
        );

      case 2:
        return (
          <HowDoYouPlanOnUsingDealMachine
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            submitAnswer={this.submitAnswer}
            next_step_loading={this.state.next_step_loading}
          />
        );

      default:
        return (
          <WhatIsYourIndustry
            nextStep={this.nextStep}
            submitAnswer={this.submitAnswer}
            next_step_loading={this.state.next_step_loading}
          />
        );
    }
  }

  render() {
    const { colors } = this.props;

    return (
      <Page
        metaTitle={"Just a few questions - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper
          renderInner={() => {
            return (
              <>
                {this.state.progress > 1 ? (
                  <Row>
                    <InlineButton icon={"arrow-back"} onPress={this.lastStep}>
                      Go Back
                    </InlineButton>
                  </Row>
                ) : null}
                <Wrapper
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: 25
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    Question {this.state.progress} of {this.state.total_steps}.
                  </Copy>
                  <ProgressBar
                    style={{ marginTop: 10 }}
                    color={colors.active_color}
                    backgroundColor={colors.white_color}
                    width={200}
                    progress={
                      parseInt(this.state.progress) / this.state.total_steps
                    }
                  />
                </Wrapper>
              </>
            );
          }}
        >
          {this.renderQuestion()}
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect } = native;
  const { visitor } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth,
    visitor
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  updateUser,
  submitMarketingForm
})(QualifyingQuestions);
