import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Page,
  Wrapper,
  InternalImage,
  Spinner,
  Copy,
  Title,
  Row,
  Bold,
  Icon
} from "app/NativeComponents/common";
import {
  getUser,
  getSourceOfTruth,
  appRedirect,
  loadSpecialCopy
} from "app/NativeActions";
import CreateAnAccount from "app/DealMachineCore/components/CreateAnAccount";
import { Header1, Header3 } from "app/NativeComponents/website/common";
import moment from "moment";
class SignUpContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      normal_copy: false,
      contentful_content: null,
      remaining_time: null
    };

    this.startCountdown = this.startCountdown.bind(this);
  }

  componentDidMount() {
    if (!!this.props.user_info?.special) {
      this.getSpecialCopy();
    } else {
      this.setState({
        normal_copy: true
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this._countdown_timer);
  }

  startCountdown(countdownDate) {
    //take the date and the current time and return the difference in days, hours, minutes, seconds then save it as the state remaining_time and trigger a set timout in 1 sec for this function
    const now = moment();
    const then = moment(countdownDate);
    const countdown = moment(then - now);
    const days = countdown.format("D");
    const hours = countdown.format("HH");
    const minutes = countdown.format("mm");
    const seconds = countdown.format("ss");
    const remaining_time = `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`;
    this.setState({
      remaining_time
    });
    clearInterval(this._countdown_timer);
    this._countdown_timer = setTimeout(() => {
      this.startCountdown(countdownDate);
    }, 1000);
  }

  getSpecialCopy() {
    loadSpecialCopy(this.props.user_info?.special)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            const contentful_content = data.items[0]
              ? data.items[0].fields
                ? data.items[0].fields
                : null
              : null;

            this.setState({
              contentful_content
            });

            if (contentful_content?.countdownDate) {
              this.startCountdown(contentful_content?.countdownDate);
            }
          } else {
            this.setState({
              normal_copy: true
            });
          }
        } else {
          this.setState({
            normal_copy: true
          });
        }
      })
      .catch(err => {
        this.setState({
          normal_copy: true
        });
      });
  }

  render() {
    const { colors } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }
    return (
      <Page
        metaTitle={"Create an account - DealMachine for Real Estate"}
        responsive_design={true}
        theme_color={"#444BC6"}
      >
        <div id={"signup-container"}>
          <div id={"signup-container-inner"}>
            <div id={"signup-container-card"}>
              <div style={{ alignItems: "center", justifyContent: "center" }}>
                <Row style={{ padding: 20, paddingTop: 0 }}>
                  <a
                    className={"deal-button"}
                    href={"https://dealmachine.com/"}
                  >
                    <InternalImage
                      style={{
                        width: 40,
                        objectFit: "contain",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginRight: 15
                      }}
                      contain
                      image={"/assets/images/Icon-blue.png"}
                    />
                  </a>
                  <div>
                    <Title>
                      {this.props.visitor?.marketing_experiments
                        ?.dealmachine_free === "dealmachine_free" &&
                      this.props.device === "desktop"
                        ? "Create your free acount."
                        : this.props.user_info?.signup_type == "team_invite"
                        ? "Join " + this.props.user_info?.team_name + "'s Team"
                        : !this.state.normal_copy
                        ? this.state.contentful_content?.pageTitle
                        : "Try For Free"}
                    </Title>
                    <Copy style={{ fontSize: 12 }}>
                      {this.props.visitor?.marketing_experiments
                        ?.dealmachine_free === "dealmachine_free" &&
                      this.props.device === "desktop"
                        ? "No credit card required."
                        : "You'll be up & running in 2 minutes."}
                    </Copy>
                  </div>
                </Row>

                <CreateAnAccount
                  buttonText={
                    !this.state.normal_copy
                      ? this.state.contentful_content?.buttonText
                      : null
                  }
                />
              </div>
            </div>
          </div>

          <div
            id={"signup-container-content"}
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 25
            }}
          >
            <div id={"signup-container-content-inner"}>
              {this.state.normal_copy ? (
                <>
                  <Header1
                    style={{
                      color: colors.white_text_color,
                      textAlign: "center"
                    }}
                  >
                    <Bold>Real Estate At Your Fingertips.</Bold>
                  </Header1>
                  <Header3
                    style={{
                      color: colors.white_text_color,
                      textAlign: "center"
                    }}
                  >
                    Unlock your full potential and build generational wealth
                    with the platform that gives you the power of real estate.
                    Join over 150,000 people on the path to financial freedom.
                  </Header3>
                </>
              ) : (
                <>
                  <Header1
                    style={{
                      color: colors.white_text_color,
                      textAlign: "center"
                    }}
                  >
                    <Bold>{this.state.contentful_content?.title}</Bold>
                  </Header1>
                  <Header3
                    style={{
                      color: colors.white_text_color,
                      textAlign: "center"
                    }}
                  >
                    {this.state.contentful_content?.description}
                  </Header3>

                  {this.state.contentful_content?.countdownDate &&
                  this.state.remaining_time ? (
                    <>
                      <Header3
                        style={{
                          color: colors.white_text_color,
                          textAlign: "center"
                        }}
                      >
                        Only{" "}
                        <Bold style={{ color: colors.orange_color_muted }}>
                          {this.state.remaining_time}
                        </Bold>{" "}
                        remaining for this special.
                      </Header3>
                    </>
                  ) : null}
                </>
              )}
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Row style={{ paddingBottom: 10 }}>
                  <Icon
                    icon={"star"}
                    color={colors.orange_color_muted}
                    size={26}
                  />
                  <Icon
                    icon={"star"}
                    color={colors.orange_color_muted}
                    size={26}
                  />
                  <Icon
                    icon={"star"}
                    color={colors.orange_color_muted}
                    size={26}
                  />
                  <Icon
                    icon={"star"}
                    color={colors.orange_color_muted}
                    size={26}
                  />
                  <Icon
                    icon={"star"}
                    color={colors.orange_color_muted}
                    size={26}
                  />
                </Row>
                <Copy
                  style={{
                    color: colors.white_text_color,
                    textAlign: "center"
                  }}
                >
                  4.8 out of 5 stars in over 5,000 reviews
                </Copy>
              </Wrapper>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;
  const { user_info, visitor } = marketing;
  const { colors } = settings;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    user_info,
    visitor
  };
};

export default connect(mapStateToProps, {
  getUser,
  getSourceOfTruth,
  appRedirect
})(SignUpContainer);
