import React, { Component } from "react";
import { connect } from "react-redux";

import { checkIfUserHasMetadata } from "app/NativeActions";
import TelnyxDeviceHandler from "./TelnyxDeviceHandler";
import TwilioDeviceHandler from "./TwilioDeviceHandler";

class NativePhone extends Component {
  constructor(props) {
    super(props);

    this._device = null;
  }

  render() {
    const phone_plan =
      checkIfUserHasMetadata("dialer") || checkIfUserHasMetadata("dialer_demo");
    const dialer_provider = this.props.source_of_truth?.dialer_provider;

    if (
      (phone_plan &&
        this.props.user &&
        this.props.init &&
        !!this.props.user?.user_ws_key &&
        this.props.source_of_truth &&
        ((this.props.user?.finished_onboarding == 1 &&
          this.props?.user?.onboarding_stepthrough_completed == 1) ||
          this.props.user?.team_owner != 1)) ||
      this.props.onboarding
    ) {
      if (dialer_provider === "twilio") {
        return (
          <>
            <TwilioDeviceHandler onboarding={this.props.onboarding} />
          </>
        );
      } else {
        return (
          <>
            <TelnyxDeviceHandler onboarding={this.props.onboarding} />
          </>
        );
      }
    }

    return null;
  }
}

const mapStateToProps = ({ auth, settings, dialer, billing }) => {
  const { token, user, init } = auth;
  const { dark_mode, colors } = settings;
  const { active_dialer_contact } = dialer;
  const { source_of_truth } = billing;

  return {
    token,
    user,
    init,
    dark_mode,
    colors,
    active_dialer_contact,
    source_of_truth
  };
};

export default connect(mapStateToProps, {})(NativePhone);
