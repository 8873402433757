import React, { Component } from "react";
import { connect } from "react-redux";
import {
  GhostButton,
  SelectItem,
  UpsellButton
} from "app/NativeComponents/snippets";

import {
  loadListTask,
  pushSidePanel,
  checkIfUserHasMetadata
} from "app/NativeActions";

class DriveListButton extends Component {
  render() {
    const {
      action_button,
      is_list,
      is_actionable,
      button_search,
      title,
      active_list_tab
    } = this.props;
    if (action_button) {
      if (!checkIfUserHasMetadata("paid_plan")) {
        return (
          <GhostButton
            icon="lock"
            style={{ marginRight: 0 }}
            disabled={!is_actionable || !is_list}
            tooltip={
              !is_actionable || !is_list
                ? "Select a list to enable this option."
                : ""
            }
            onPress={() => {
              this.props.pushSidePanel({
                slug: "purchase_plan_prompt",
                overlay_modal: true,
                data: {
                  title: "Drive Lists With DealMachine Pro.",
                  dismiss_button:
                    "No thanks. Let me continue without driving lists.",
                  no_free: true,
                  event_slug: "in_app_drive_leads"
                },
                locked: true
              });
            }}
            tooltipPlacement="top"
          >
            Drive This List
          </GhostButton>
        );
      }

      return (
        <UpsellButton
          type="plan"
          slug="driving"
          tier={1}
          contentful_slug="unlock_feature"
          meta_slug="route_planning"
          onLockPress={null}
          toggleMenu={this.props.toggleMenu}
          onPress={() => {
            this.props.clearAll();
            if (is_actionable && is_list) {
              this.props.loadListTask({
                token: this.props.token,
                list_id: active_list_tab.id
              });
              this.props.pushSidePanel({ slug: "create_task", overlay: true });
            }
          }}
          renderLockedChildren={({ hovering }) => {
            return (
              <GhostButton
                icon="lock"
                style={{ marginRight: 0 }}
                disabled={!is_actionable || !is_list}
                pressedIn={hovering}
                noPress={true}
                tooltip={
                  !is_actionable || !is_list
                    ? "Select a list to enable this option."
                    : ""
                }
                tooltipPlacement="top"
              >
                Drive This List
              </GhostButton>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <GhostButton
                style={{ marginRight: 0 }}
                disabled={!is_actionable || !is_list}
                noPress={true}
                pressedIn={hovering}
                tooltip={
                  !is_actionable || !is_list
                    ? "Select a list to enable this option."
                    : ""
                }
                tooltipPlacement="top"
              >
                Drive This List
              </GhostButton>
            );
          }}
        />
      );
    }

    if (
      (!button_search ||
        title.toLowerCase().includes(button_search.toLowerCase())) &&
      is_list
    ) {
      return (
        <UpsellButton
          type="plan"
          slug="driving"
          tier={1}
          contentful_slug="unlock_feature"
          onLockPress={null}
          meta_slug="route_planning"
          toggleMenu={this.props.toggleMenu}
          onPress={() => {
            this.props.clearAll();
            this.props.loadListTask({
              token: this.props.token,
              list_id: active_list_tab.id
            });
            this.props.pushSidePanel({ slug: "create_task", overlay: true });
            if (this.props.toggleMenu) {
              this.props.toggleMenu(false);
            }
          }}
          renderLockedChildren={({ hovering }) => {
            return (
              <SelectItem
                select_type="none"
                icon="lock"
                noPress={true}
                pressedIn={hovering}
              >
                Drive This List
              </SelectItem>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <SelectItem
                select_type="none"
                icon="keyboard-arrow-right"
                noPress={true}
                pressedIn={hovering}
              >
                Drive This List
              </SelectItem>
            );
          }}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, list }) => {
  const { token, user } = auth;
  const { active_list_tab } = list;

  return {
    token,
    user,
    active_list_tab
  };
};

export default connect(mapStateToProps, { loadListTask, pushSidePanel })(
  DriveListButton
);
