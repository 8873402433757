import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Bold,
  Wrapper,
  Row,
  Copy,
  Card,
  AlmaOrb,
  ProgressBar
} from "app/NativeComponents/common";
import { Header1, Header3 } from "app/NativeComponents/common";

import {
  logout,
  loadOnboardingText,
  startRedirect,
  updateUser,
  getMarketingOnboardingData,
  checkIfUserHasMetadata,
  manageSubscription,
  pushSidePanel,
  popSidePanel,
  updateMailerTemplate,
  updateLead,
  setActiveDialerContact,
  phoneCall
} from "app/NativeActions";
import {
  InlineButton,
  GhostButton,
  SpinWrapper,
  OnboardingWrapper
} from "app/NativeComponents/snippets";

import NativeDialer from "app/NativeComponents/components/NativeDialer";

class DialerStep extends Component {
  constructor(props) {
    super(props);

    const almaFeature = props?.all_feature_metadata?.find(
      feature => feature.slug === "dialer"
    );

    let stripe_price_id_month = almaFeature?.upsell_stripe_price_id_monthly;
    let stripe_price_id_year = almaFeature?.upsell_stripe_price_id_annually;

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      loading_property: false,
      lead: null,
      stripe_price_id_month,
      stripe_price_id_year,
      plan_interval: props?.source_of_truth?.subscription?.interval
        ? props?.source_of_truth?.subscription?.interval
        : "month",
      onboarding_complete: false,
      next_loading: false,
      active_contact: null
    };

    this.loadData = this.loadData.bind(this);

    this.getProperty = this.getProperty.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/ai-powered-dialer");
    }
    this.loadData("dialer_step");

    this.getProperty();
  }

  getProperty() {
    this.props.getMarketingOnboardingData({
      token: this.props.token,
      type: "dialer_step",
      onLoading: () => {
        this.setState({ loading_property: true });
      },
      onError: () => {
        this.setState({ loading_property: true });
      },
      onSuccess: results => {
        this.setState(
          {
            loading_property: false,
            lead: results?.lead,
            active_contact: results?.active_contact
          },
          () => {
            if (this.state.active_contact) {
              this.props.phoneCall({
                token: this.props.token,
                type: "get_phone_number_for_practice_bot",
                contact_id: results?.active_contact?.individual_key,
                property_id: results?.lead?.id
              });

              this.props.setActiveDialerContact({
                contact: {
                  ...this.state.active_contact,
                  selected_phone: {
                    number: this.state.active_contact.phone_1,
                    type: this.state.active_contact.phone_1_type,
                    do_not_call_flag:
                      this.state.active_contact.phone_1_do_not_call_flag,
                    owner: this.state.active_contact.phone_1_owner
                  },
                  associated_lead: this.state.lead
                }
              });
            }
          }
        );
      }
    });
  }

  componentWillUnmount() {}

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const {
      contentful_content,
      contentful_loading,
      lead,
      property_data_for_openai
    } = this.state;
    const { colors, isMobile, user } = this.props;

    return (
      <Container title={"Call Owners with an AI-Powered Dialer."}>
        <OnboardingWrapper
          scroll_ref={this._scroll_ref}
          renderInner={() => {
            return (
              <Row>
                <InlineButton
                  icon={"arrow-back"}
                  loading={this.state.back_loading}
                  onPress={() => {
                    this.props.updateUser({
                      token: this.props.token,
                      type: "onboarding_stepthrough_step",
                      payload: {
                        onboarding_stepthrough_current_step: "design_mail_step"
                      },
                      onLoading: () => {
                        this.setState({
                          back_loading: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          back_loading: false
                        });
                      },
                      onSuccess: () => {
                        this.props.setActiveDialerContact({
                          contact: null,
                          queued_numbers: []
                        });
                        this.setState({
                          back_loading: false
                        });
                      },
                      no_loading: true
                    });
                  }}
                >
                  Go Back
                </InlineButton>
              </Row>
            );
          }}
        >
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: 25,
                paddingTop: 0
              }}
            >
              <Header1
                style={{
                  textAlign: "center"
                }}
              >
                📞 <Bold>Cold-calling made easy.</Bold>
              </Header1>
              <Header3
                style={{
                  textAlign: "center"
                }}
              >
                Mail is great, but it's not the only way to get in touch with
                owners.
              </Header3>
            </Wrapper>

            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25
              }}
            >
              <Copy style={{ textAlign: "center" }}>Tutorial step 5 of 5.</Copy>
              <ProgressBar
                style={{ marginTop: 10 }}
                color={colors.active_color}
                backgroundColor={colors.white_color}
                width={200}
                progress={5 / 5}
              />
            </Wrapper>

            <Wrapper
              style={
                this.props.device === "mobile" || this.props.desktopMobile
                  ? {
                      flexDirection: "column-reverse"
                    }
                  : {
                      alignSelf: "stretch",
                      flexDirection: "row",
                      alignItems: "center"
                    }
              }
            >
              <Wrapper
                style={
                  this.props.device === "mobile" || this.props.desktopMobile
                    ? {}
                    : {
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center"
                      }
                }
              >
                <>
                  <Wrapper
                    style={{
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Wrapper
                      style={{
                        height: 550,
                        width:
                          this.props.device === "mobile" ||
                          this.props.desktopMobile
                            ? "100%"
                            : 500,
                        marginBottom: 40
                      }}
                    >
                      <NativeDialer
                        onboarding={true}
                        onboarding_contact={null}
                      />
                    </Wrapper>
                  </Wrapper>
                </>
              </Wrapper>

              <Wrapper
                style={
                  this.props.device === "desktop" && !this.props.desktopMobile
                    ? { width: 450 }
                    : {}
                }
              >
                <Wrapper style={{ padding: 25, paddingBottom: 15 }}>
                  <>
                    <Header3 style={{ marginBottom: 10 }}>
                      <Bold>
                        DealMachine Pro comes with an AI-Powered Dialer.
                      </Bold>
                    </Header3>

                    <Copy style={{ marginBottom: 10 }}>
                      What is an AI-Powered Dialer? Well, it's hard to explain.
                      Go ahead and try it out.
                    </Copy>
                    <Copy style={{ marginBottom: 10 }}>
                      We've set you up with an AI Bot representation of this
                      contact.{" "}
                      <Bold>YOU ARE NOT ACTUALLY CALLING THIS PERSON.</Bold>{" "}
                      Take it for a spin and see the power of AI in action.
                    </Copy>
                  </>
                </Wrapper>

                {!checkIfUserHasMetadata("dialer") ? (
                  <>
                    <Wrapper
                      className="sparkle-effect"
                      style={{
                        margin: 10,
                        marginRight: 5,
                        marginLeft: 5,
                        borderRadius: 30
                      }}
                    >
                      <InlineButton
                        style={{
                          borderRadius: 30,
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: colors.active_color_muted,
                          marginLeft: 20,
                          marginRight: 20
                        }}
                        button_type={"full"}
                        loading={this.state.upgrade_loading}
                        marketing_slug={"pressed_alma_step_upgrade_button"}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "purchase_plan_prompt",
                            overlay_modal: true,
                            locked: true,
                            data: {
                              title:
                                "Get an AI-Powered Dialer With DealMachine Pro.",
                              dismiss_button:
                                "No thanks. Let's continue without the dialer.",
                              event_slug: "onboarding_dialer_step",
                              no_free: true
                            },
                            locked: true
                          });
                        }}
                      >
                        I love the dialer! Let's buy a Pro plan.
                      </InlineButton>
                    </Wrapper>

                    <InlineButton
                      button_type={"full"}
                      loading={this.state.next_loading}
                      onPress={() => {
                        this.props.updateUser({
                          token: this.props.token,
                          type: "onboarding_stepthrough_step",

                          payload: {
                            onboarding_stepthrough_current_step:
                              "select_plan_option_step"
                          },
                          onLoading: () => {
                            this.setState({
                              next_loading: true
                            });
                          },
                          onError: () => {},
                          onSuccess: () => {
                            this.props.setActiveDialerContact({
                              contact: null,
                              queued_numbers: []
                            });
                          },
                          no_loading: true
                        });
                      }}
                    >
                      No thanks. Let's continue without the dialer.
                    </InlineButton>
                  </>
                ) : (
                  <InlineButton
                    button_type={"full"}
                    loading={this.state.next_loading}
                    onPress={() => {
                      this.props.updateUser({
                        token: this.props.token,
                        type: "onboarding_stepthrough_step",
                        payload: {
                          onboarding_stepthrough_current_step:
                            "final_step_stage_one"
                        },
                        onLoading: () => {
                          this.setState({
                            next_loading: true
                          });
                        },
                        onError: () => {},
                        onSuccess: () => {
                          this.props.setActiveDialerContact({
                            contact: null,
                            queued_numbers: []
                          });
                        },
                        no_loading: true
                      });
                    }}
                  >
                    Complete tutorial
                  </InlineButton>
                )}
              </Wrapper>
            </Wrapper>
          </>

          <Wrapper style={{ height: 40 }} />
        </OnboardingWrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth, all_feature_metadata } = billing;
  const { isMobile, device, initialRedirect, desktopMobile } = native;
  const { visitor, signup_funnel } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    desktopMobile,
    device,
    initialRedirect,
    source_of_truth,
    all_feature_metadata,
    signup_funnel,
    visitor
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  updateUser,
  getMarketingOnboardingData,
  manageSubscription,
  pushSidePanel,
  popSidePanel,
  updateMailerTemplate,
  updateLead,
  setActiveDialerContact,
  phoneCall
})(DialerStep);
