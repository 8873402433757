import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper, Copy } from "app/NativeComponents/common";

import { List, SelectItem, SpinWrapper } from "app/NativeComponents/snippets";
import {
  getDialerStats,
  renderDate,
  pushSidePanel,
  numberWithCommas
} from "app/NativeActions";
import moment from "moment/moment";

class CallSessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      call_sessions: [],
      call_sessions_loading: false,
      call_sessions_refreshing: false,
      call_sessions_loaded_all: false,
      call_sessions_limit: 25,
      call_sessions_begin: 0
    };
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  getData({ load_type = "load" }) {
    if (
      !this.props.call_sessions_loading &&
      !this.state.call_sessions_refreshing
    ) {
      this.setState(
        {
          call_sessions_begin:
            load_type === "load" || load_type === "refresh"
              ? 0
              : this.state.call_sessions_begin + this.state.call_sessions_limit,
          call_sessions:
            load_type === "refresh" ? [] : this.state.call_sessions,
          call_sessions_loading: true,
          call_sessions_refreshing: load_type === "refresh"
        },
        () => {
          this.props.getDialerStats({
            token: this.props.token,
            filter_user: this.props.filter_user
              ? this.props.filter_user?.id
              : null,
            filter_date: this.props.filter_date,
            filter_start_date: this.props.filter_start_date,
            filter_end_date: this.props.filter_end_date,
            limit: this.state.call_sessions_limit,
            begin: this.state.call_sessions_begin,
            utc_offset: moment().utcOffset(),
            timezone: moment.tz.guess(),
            type: "get_call_sessions",
            onLoading: () => {
              this.setState({ call_sessions_loading: true }, () => {
                this.props.updateMainState({
                  prop: "call_sessions_loading",
                  value: true
                });
              });
            },
            onError: () => {
              this.setState(
                {
                  call_sessions_loading: false,
                  call_sessions_refreshing: false
                },
                () => {
                  this.props.updateMainState({
                    prop: "call_sessions_loading",
                    value: false
                  });
                }
              );
            },
            onSuccess: results => {
              if (results?.call_sessions) {
                this.setState(
                  {
                    call_sessions_loading: false,
                    call_sessions_refreshing: false,
                    call_sessions:
                      load_type === "load_more"
                        ? [
                            ...this.state.call_sessions,
                            ...results.call_sessions
                          ]
                        : results.call_sessions,
                    call_sessions_loaded_all:
                      results.call_sessions?.length <
                      this.state.call_sessions_limit
                  },
                  () => {
                    this.props.updateMainState({
                      prop: "call_sessions_loading",
                      value: false
                    });
                  }
                );
              }
            }
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.filter_user?.id != prevProps.filter_user?.id ||
      this.props.filter_date != prevProps.filter_date ||
      this.props.filter_start_date != prevProps.filter_start_date ||
      this.props.filter_end_date != prevProps.filter_end_date
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  componentWillUnmount() {}

  render() {
    const { colors } = this.props;
    const {
      call_sessions,
      call_sessions_refreshing,
      call_sessions_loaded_all
    } = this.state;

    const { call_sessions_loading } = this.props;

    return (
      <>
        {(call_sessions_loading && call_sessions?.length === 0) ||
        call_sessions_refreshing ? (
          <SpinWrapper text={"Loading Dialer Sessions"} />
        ) : null}

        {call_sessions?.length === 0 &&
        !call_sessions_loading &&
        !call_sessions_refreshing ? (
          <Wrapper style={{ padding: 25 }}>
            <Copy style={{ textAlign: "center" }}>
              No dialer sessions found.
            </Copy>
          </Wrapper>
        ) : (
          <List
            rowNumber={1}
            items={call_sessions}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              return (
                <SelectItem
                  select_type={"profile_pic"}
                  user={{
                    email: item.user_email,
                    firstname: item.user_firstname,
                    lastname: item.user_lastname,
                    image: item.user_image
                  }}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "call_session",
                      overlay: true,
                      data: {
                        call_session: item
                      }
                    });
                  }}
                  subtext={renderDate(item.date_created)}
                  description={
                    numberWithCommas(item.total_calls) + " calls made "
                  }
                  icon={"keyboard-arrow-right"}
                >
                  Dialer Session by{" "}
                  {item.user_firstname + " " + item.user_lastname}
                </SelectItem>
              );
            }}
            canRefresh={false}
            onRefresh={() => {}}
            is_refreshing={false}
            canLoadMore={
              !call_sessions_loaded_all &&
              !call_sessions_loading &&
              call_sessions?.length > 0
            }
            isLoadingMore={call_sessions_loading && call_sessions?.length > 0}
            onLoadMore={() => {
              if (!call_sessions_loaded_all && call_sessions_loading !== true) {
                this.getData({ load_type: "load_more" });
              }
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const { current_call_session } = dialer;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,
    current_call_session
  };
};

export default connect(mapStateToProps, {
  getDialerStats,
  pushSidePanel
})(CallSessions);
