import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper } from "app/NativeComponents/common";
import {
  PopoverMenu,
  List,
  SearchBar,
  SelectItem
} from "app/NativeComponents/snippets";

import { checkIfUserHasMetadata } from "app/NativeActions";

class AllPrompts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: false
    };

    this._scroll_view = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {}

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].display_content
          ?.toLowerCase()
          .indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  scrollToBottom() {
    const { device } = this.props;

    if (device == "desktop" && this._scroll_view && this._scroll_view.current) {
      this._scroll_view.current.scrollTop =
        this._scroll_view.current.scrollHeight + 20;
    } else if (
      device == "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      this._scroll_view.current.scrollToEnd
    ) {
      this._scroll_view.current.scrollToEnd({ animated: true });
    }
  }

  render() {
    const {
      prompts,
      popoverPlacement = "top",
      renderComponent = () => {}
    } = this.props;
    const { search, show } = this.state;

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={400}
        popover_height={450}
        popoverSheetTop={"85%"}
        popoverPlacement={popoverPlacement}
        popover_title={"Try out these prompts."}
        hideWithOutsideClick={true}
        includeCloseButton={true}
        renderComponent={options => {
          return (
            <Wrapper wrapper_ref={this._popover}>
              {renderComponent({ ...options, pressedIn: show })}
            </Wrapper>
          );
        }}
        renderMenu={() => {
          return (
            <>
              <Wrapper style={{ alignSelf: "stretch" }}>
                <SearchBar
                  style={{
                    margin: 10,
                    marginTop: 10
                  }}
                  title="Search prompts"
                  onChange={value => {
                    this.setState({
                      search: value
                    });
                  }}
                  value={search}
                />
              </Wrapper>

              <List
                style={{ alignSelf: "stretch" }}
                items={this.filterOptions(this.props.prompts)}
                itemStructure={({ item, index }) => {
                  return (
                    <SelectItem
                      disabled={this.props.disabled}
                      key={"option_" + index}
                      icon={"keyboard-arrow-right"}
                      select_type={"none"}
                      onPress={() => {
                        if (
                          !checkIfUserHasMetadata("paid_plan") &&
                          !checkIfUserHasMetadata("alma") &&
                          !this.props.onboarding
                        ) {
                          this.props.pushSidePanel({
                            slug: "purchase_plan_prompt",
                            overlay_modal: true,
                            locked: true,
                            data: {
                              title:
                                "Get Alma the AI Assistant With DealMachine Pro.",
                              dismiss_button:
                                "No thanks. Let me continue without Alma the AI Assistant.",
                              no_free: true,
                              event_slug: "in_app_alma"
                            },
                            locked: true
                          });
                        } else {
                          this.props.onSelectSuggestedPrompt({
                            content: item.content,
                            messages: item.messages,
                            display_content: item.display_content,
                            openai_model: item.openai_model,
                            prompt_slug: item.prompt_slug,
                            prompt_type: item.prompt_type,
                            assistant_response: item.assistant_response,
                            prompt_children: item.children,
                            answer_slug: item.answer_slug,
                            answer_group: item.answer_group,
                            follow_up_slug: item.follow_up_slug
                          });
                        }
                      }}
                    >
                      {item.display_content}
                    </SelectItem>
                  );
                }}
              />
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors } = settings;

  return {
    token,
    user,
    isMobile,
    device,
    colors
  };
};

export default connect(mapStateToProps, {})(AllPrompts);
