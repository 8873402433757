import React, { Component } from "react";
import { connect } from "react-redux";
import {
  InlineButton,
  IconButton,
  InlineTabs,
  InputBox,
  UpsellButton,
  SmallLabel,
  FontSelector,
  Select,
  ColorPicker,
  SelectItem,
  SelectBox,
  BottomNavBar,
  PopoverMenu,
  SearchBar,
  List
} from "app/NativeComponents/snippets";
import {
  MultiLineInput,
  Row,
  Wrapper,
  KeyboardView,
  Card,
  Copy,
  HTMLEditor
} from "app/NativeComponents/common";
import QuickPositions from "./QuickPositions";
import UploadButton from "./UploadButton";
import {
  uploadFile,
  pushSidePanel,
  checkIfUserHasMetadata
} from "app/NativeActions";
class SelectedLayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_brackets: false,
      show_padding: false,
      show_position: false,
      show_size: false,
      show_typography: false,
      show_background: false,
      show_border: false,
      show_quick_position: false,
      text_align_focus: false,
      border_style_focus: false,
      bracket_search: ""
    };

    this._layer_name = React.createRef();
    this._text_align = React.createRef();

    this.filterOptions = this.filterOptions.bind(this);
  }

  filterOptions(options) {
    let filtered_options = [];
    const { bracket_search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label
          ?.toLowerCase()
          .indexOf(bracket_search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  render() {
    const {
      show_brackets,
      show_quick_position,
      show_padding,
      show_position,
      show_size,
      show_typography,
      show_background,
      show_border,
      bracket_search
    } = this.state;

    const {
      colors,
      layer,
      updateLayer,
      bracket_variables,
      isMobile,
      card_width,
      card_height,
      advanced_editing_mode,
      source_of_truth,
      template_type
    } = this.props;
    return (
      <>
        <InputBox
          autoFocus={false}
          input_ref={this._layer_name}
          name="layer_name"
          returnKeyType="done"
          placeholder="Layer Name"
          onChange={value => {
            updateLayer({
              prop: "title",
              value: value
            });
          }}
          onSubmitEditing={() => {}}
          blurOnSubmit={true}
          value={layer?.title}
          type="text"
        />
        {source_of_truth?.subscription?.metadata?.mail_designer &&
        template_type !== "handwritten" ? (
          <InlineTabs
            tab_type="underline"
            selected_tab={layer.layer_type}
            tabs={[
              {
                onPress: () => {
                  updateLayer({
                    prop: "layer_type",
                    value: "text"
                  });
                },
                slug: "text",
                title: "Text"
              },
              {
                onPress: () => {
                  updateLayer({
                    prop: "layer_type",
                    value: "image"
                  });
                },
                slug: "image",
                title: "Image"
              }
            ]}
          />
        ) : null}

        <KeyboardView style={{ flex: 1 }}>
          {layer.layer_type == "text" ? (
            <Wrapper>
              {isMobile || template_type === "handwritten" ? (
                <MultiLineInput
                  ref="text"
                  name="text"
                  returnKeyType="default"
                  maxLength={
                    template_type === "handwritten" &&
                    layer.id === "content_body"
                      ? 275
                      : false
                  }
                  charCount={
                    template_type === "handwritten" &&
                    layer.id === "content_body"
                      ? layer.content.length
                      : false
                  }
                  autoCapitalize="sentences"
                  blurOnSubmit={false}
                  keyboardType="default"
                  placeholder="Enter your content here."
                  label="Content"
                  onChange={value => {
                    updateLayer({
                      prop: "content",
                      value: value
                    });
                  }}
                  value={layer.content}
                />
              ) : (
                <HTMLEditor
                  ref="text"
                  name="text"
                  returnKeyType="default"
                  maxLength={
                    template_type === "handwritten" &&
                    layer.id === "content_body"
                      ? 275
                      : false
                  }
                  charCount={
                    template_type === "handwritten" &&
                    layer.id === "content_body"
                      ? layer.content.length
                      : false
                  }
                  additionalInit={{
                    newline_behavior: "default",
                    forced_root_block: "div",
                    text_patterns: false
                  }}
                  autoCapitalize="sentences"
                  blurOnSubmit={false}
                  keyboardType="default"
                  placeholder="Enter your content here."
                  label="Content"
                  onChange={value => {
                    updateLayer({
                      prop: "content",
                      value: value
                    });
                  }}
                  toolbar={
                    "bold italic underline removeformat | bullist numlist | code"
                  }
                  data={layer.content}
                />
              )}

              <Row style={{ padding: 10 }}>
                {isMobile ? (
                  <>
                    <IconButton
                      icon={"format-bold"}
                      icon_color={colors.light_text_color}
                      style={{ margin: 0 }}
                      onPress={() => {
                        updateLayer({
                          prop: "content",
                          value: layer.content + "<b></b>"
                        });
                      }}
                    />
                    <IconButton
                      icon={"format-italic"}
                      icon_color={colors.light_text_color}
                      style={{ margin: 0 }}
                      onPress={() => {
                        updateLayer({
                          prop: "content",
                          value: layer.content + "<i></i>"
                        });
                      }}
                    />
                    <IconButton
                      icon={"format-underline"}
                      icon_color={colors.light_text_color}
                      style={{ margin: 0 }}
                      onPress={() => {
                        updateLayer({
                          prop: "content",
                          value: layer.content + "<u></u>"
                        });
                      }}
                    />
                    <IconButton
                      fa_icon={"paragraph"}
                      icon_color={colors.light_text_color}
                      style={{ margin: 0 }}
                      onPress={() => {
                        updateLayer({
                          prop: "content",
                          value: layer.content + "<br/>"
                        });
                      }}
                    />
                  </>
                ) : null}

                <PopoverMenu
                  show={show_brackets}
                  no_swipe={true}
                  no_cancel={true}
                  onShow={s => {
                    this.setState({
                      show_brackets: s
                    });
                  }}
                  popover_width={400}
                  popover_height={450}
                  popoverSheetTop={"75%"}
                  popoverPlacement={"right"}
                  popover_title={"Insert Variables"}
                  includeCloseButton={true}
                  renderComponent={options => {
                    return (
                      <InlineButton
                        icon={"code"}
                        text_color={colors.light_text_color}
                        style={{ margin: 0 }}
                        pressedIn={show_brackets || options.hovering}
                        noPress={true}
                      >
                        Insert Variables
                      </InlineButton>
                    );
                  }}
                  renderMenu={() => {
                    return (
                      <>
                        <Wrapper style={{ alignSelf: "stretch" }}>
                          <SearchBar
                            style={{
                              margin: 10,
                              marginTop: bracket_variables.length > 0 ? 5 : 10
                            }}
                            title="Search Variables"
                            onChange={value => {
                              this.setState({
                                bracket_search: value
                              });
                            }}
                            value={bracket_search}
                          />
                        </Wrapper>

                        <List
                          style={{ alignSelf: "stretch" }}
                          items={this.filterOptions(bracket_variables)}
                          itemStructure={({ item, index }) => {
                            if (!!item.label) {
                              return (
                                <SelectItem
                                  key={"option_" + index}
                                  selected={false}
                                  select_type={"none"}
                                  icon={"keyboard-arrow-right"}
                                  onPress={() => {
                                    updateLayer({
                                      prop: "content",
                                      value: layer.content + item.slug + " "
                                    });

                                    this.setState({
                                      show_brackets: !show_brackets
                                    });
                                  }}
                                  description={item.slug}
                                >
                                  {item.label}
                                </SelectItem>
                              );
                            }
                          }}
                        />
                      </>
                    );
                  }}
                  menu_items={null}
                />
              </Row>
            </Wrapper>
          ) : (
            <>
              <SelectBox
                items={[
                  {
                    label: "Upload New Image",
                    value: "upload_new_image"
                  },

                  {
                    label: "Use Signature Image",
                    value: "use_signature_image"
                  },

                  {
                    label: "Use Property Primary Image",
                    value: "use_property_primary_image"
                  }
                ]}
                disabled={
                  !source_of_truth?.subscription?.metadata?.mail_designer
                }
                value={layer.image_type || "upload_new_image"}
                placeholder="Image Type"
                show={this.state.image_type_focus}
                onFocus={() => {
                  this.setState({ image_type_focus: true });
                }}
                onBlur={() => {
                  this.setState({ image_type_focus: false });
                }}
                onSelect={item => {
                  switch (item) {
                    case "use_signature_image":
                      updateLayer({
                        layer: {
                          ...layer,
                          image_type: item,
                          image_url: "{{signature_image}}"
                        }
                      });
                      break;

                    case "use_property_primary_image":
                      updateLayer({
                        layer: {
                          ...layer,
                          image_type: item,
                          image_url: "{{property_primary_image}}"
                        }
                      });
                      break;

                    default:
                      updateLayer({ prop: "image_type", value: item });

                      break;
                  }
                }}
                onSubmitEditing={() => {}}
                icon={
                  !source_of_truth?.subscription?.metadata?.mail_designer
                    ? "lock"
                    : null
                }
              />

              {layer.image_type == "upload_new_image" ? (
                <SelectItem
                  select_type={"none"}
                  noPress={true}
                  renderRight={
                    source_of_truth?.subscription?.metadata?.mail_designer
                      ? () => {
                          return <UploadButton layer={layer} {...this.props} />;
                        }
                      : null
                  }
                  innerStyle={{ lineBreak: "anywhere" }}
                  label={"Uploaded file"}
                >
                  {layer.image_url ||
                    "Upload an image to view it on your layer."}
                </SelectItem>
              ) : null}

              <SelectBox
                items={[
                  {
                    label: "Cover",
                    value: "cover"
                  },
                  {
                    label: "Contain",
                    value: "contain"
                  }
                ]}
                disabled={
                  !source_of_truth?.subscription?.metadata?.mail_designer
                }
                value={layer.image_fit || "cover"}
                placeholder="Image Fit"
                show={this.state.image_fit_focus}
                onFocus={() => {
                  this.setState({ image_fit_focus: true });
                }}
                onBlur={() => {
                  this.setState({ image_fit_focus: false });
                }}
                onSelect={item => {
                  updateLayer({ prop: "image_fit", value: item });
                }}
                onSubmitEditing={() => {}}
                icon={
                  !source_of_truth?.subscription?.metadata?.mail_designer
                    ? "lock"
                    : null
                }
              />
            </>
          )}

          {advanced_editing_mode && template_type !== "handwritten" ? (
            <>
              <SmallLabel>Advanced Styling:</SmallLabel>

              <>
                <Wrapper
                  style={{
                    backgroundColor: show_typography
                      ? colors.active_color_muted
                      : null,
                    borderRadius: show_typography ? 5 : 0,
                    margin: show_typography ? 5 : 0
                  }}
                >
                  {
                    <SelectItem
                      select_type={"none"}
                      onPress={() =>
                        this.setState({
                          show_typography: !show_typography,
                          show_padding: false,
                          show_position: false,
                          show_size: false,
                          show_background: false,
                          show_border: false,
                          show_quick_position: false
                        })
                      }
                      selected={show_typography}
                      icon={
                        show_typography
                          ? "keyboard-arrow-up"
                          : "keyboard-arrow-down"
                      }
                    >
                      Typography
                    </SelectItem>
                  }

                  {show_typography ? (
                    <>
                      <FontSelector
                        font_family={layer.font_family}
                        onChange={font => {
                          updateLayer({
                            layer: {
                              ...layer,
                              font_family: font.family,
                              font_family_variants: font.variants,
                              font_family_files: font.files
                            }
                          });
                        }}
                      />

                      <SelectBox
                        select_ref={this._text_align}
                        items={[
                          {
                            label: "Center",
                            value: "center"
                          },
                          {
                            label: "Left",
                            value: "left"
                          },
                          {
                            label: "Right",
                            value: "right"
                          }
                        ]}
                        value={layer.text_align || "none"}
                        placeholder="Text Align"
                        show={this.state.text_align_focus}
                        onFocus={() => {
                          this.setState({ text_align_focus: true });
                        }}
                        onBlur={() => {
                          this.setState({ text_align_focus: false });
                        }}
                        onSelect={item => {
                          updateLayer({ prop: "text_align", value: item });
                        }}
                        onSubmitEditing={() => {}}
                      />

                      <ColorPicker
                        ref_name={"text_color"}
                        placeholder={"Text Color"}
                        onChange={value => {
                          if (value && value.hex) {
                            updateLayer({
                              prop: "text_color",
                              value: value.hex
                            });
                          } else {
                            updateLayer({ prop: "text_color", value: value });
                          }
                        }}
                        value={layer.text_color || "#ffffff"}
                        onColorSelect={() => {}}
                        popoverPlacement={isMobile ? "top" : "right"}
                      />

                      <InputBox
                        autoFocus={false}
                        input_ref={this._font_size}
                        name="font_size"
                        returnKeyType="done"
                        placeholder="Font Size"
                        onChange={value => {
                          updateLayer({ prop: "font_size", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.font_size.toString()}
                        type="number"
                      />

                      <InputBox
                        autoFocus={false}
                        input_ref={this._line_height}
                        name="line_height"
                        returnKeyType="done"
                        placeholder="Line Height"
                        onChange={value => {
                          updateLayer({ prop: "line_height", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.line_height.toString()}
                        type="text"
                      />
                    </>
                  ) : null}
                </Wrapper>
                <Wrapper
                  style={{
                    backgroundColor: show_background
                      ? colors.active_color_muted
                      : null,
                    borderRadius: show_background ? 5 : 0,
                    margin: show_background ? 5 : 0
                  }}
                >
                  <SelectItem
                    select_type={"none"}
                    onPress={() =>
                      this.setState({
                        show_typography: false,
                        show_padding: false,
                        show_position: false,
                        show_size: false,
                        show_background: !show_background,
                        show_border: false,
                        show_quick_position: false
                      })
                    }
                    selected={show_background}
                    icon={
                      show_background
                        ? "keyboard-arrow-up"
                        : "keyboard-arrow-down"
                    }
                  >
                    Background
                  </SelectItem>

                  {show_background ? (
                    <ColorPicker
                      ref_name={"background_color"}
                      placeholder={"Background Color"}
                      onChange={value => {
                        if (value && value.hex) {
                          updateLayer({ prop: "background", value: value.hex });
                        } else {
                          updateLayer({ prop: "background", value: value });
                        }
                      }}
                      value={layer.background || "#ffffff"}
                      onColorSelect={() => {}}
                      popoverPlacement={isMobile ? "top" : "right"}
                    />
                  ) : null}
                </Wrapper>

                <Wrapper
                  style={{
                    backgroundColor: show_border
                      ? colors.active_color_muted
                      : null,
                    borderRadius: show_border ? 5 : 0,
                    margin: show_border ? 5 : 0
                  }}
                >
                  <SelectItem
                    select_type={"none"}
                    onPress={() =>
                      this.setState({
                        show_border: !show_border,
                        show_padding: false,
                        show_position: false,
                        show_size: false,
                        show_typography: false,
                        show_background: false,
                        show_quick_position: false
                      })
                    }
                    selected={show_border}
                    icon={
                      show_border ? "keyboard-arrow-up" : "keyboard-arrow-down"
                    }
                  >
                    Border
                  </SelectItem>

                  {show_border ? (
                    <>
                      <SelectBox
                        items={[
                          {
                            label: "None",
                            value: "none"
                          },
                          {
                            label: "Solid",
                            value: "solid"
                          },
                          {
                            label: "Dashed",
                            value: "dashed"
                          }
                        ]}
                        value={layer.border_style || "none"}
                        placeholder="Border Style"
                        show={this.state.border_style_focus}
                        onFocus={() => {
                          this.setState({ border_style_focus: true });
                        }}
                        onBlur={() => {
                          this.setState({ border_style_focus: false });
                        }}
                        onSelect={item => {
                          updateLayer({ prop: "border_style", value: item });
                        }}
                        onSubmitEditing={() => {}}
                      />

                      {layer.border_style !== "none" ? (
                        <Wrapper>
                          <ColorPicker
                            ref_name={"border_color"}
                            placeholder={"Border Color"}
                            onChange={value => {
                              if (value && value.hex) {
                                updateLayer({
                                  prop: "border_color",
                                  value: value.hex
                                });
                              } else {
                                updateLayer({
                                  prop: "border_color",
                                  value: value
                                });
                              }
                            }}
                            value={layer.border_color || "#ffffff"}
                            onColorSelect={() => {}}
                            popoverPlacement={isMobile ? "top" : "right"}
                          />

                          <InputBox
                            autoFocus={false}
                            input_ref={this._border_width}
                            name="border_width"
                            returnKeyType="done"
                            placeholder="Border Width"
                            onChange={value => {
                              updateLayer({
                                prop: "border_width",
                                value: value
                              });
                            }}
                            onSubmitEditing={() => {}}
                            blurOnSubmit={true}
                            value={layer?.border_width.toString()}
                            type="number"
                          />
                        </Wrapper>
                      ) : null}

                      <InputBox
                        autoFocus={false}
                        input_ref={this._border_radius}
                        name="border_radius"
                        returnKeyType="done"
                        placeholder="Border Radius"
                        onChange={value => {
                          updateLayer({ prop: "border_radius", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.border_radius.toString()}
                        type="number"
                      />
                    </>
                  ) : null}
                </Wrapper>

                <Wrapper
                  style={{
                    backgroundColor: show_size
                      ? colors.active_color_muted
                      : null,
                    borderRadius: show_size ? 5 : 0,
                    margin: show_size ? 5 : 0
                  }}
                >
                  <SelectItem
                    select_type={"none"}
                    onPress={() =>
                      this.setState({
                        show_border: false,
                        show_padding: false,
                        show_position: false,
                        show_size: !show_size,
                        show_typography: false,
                        show_background: false,
                        show_quick_position: false
                      })
                    }
                    selected={show_size}
                    icon={
                      show_size ? "keyboard-arrow-up" : "keyboard-arrow-down"
                    }
                  >
                    Size
                  </SelectItem>

                  {show_size ? (
                    <>
                      <InputBox
                        autoFocus={false}
                        input_ref={this._width}
                        name="width"
                        returnKeyType="done"
                        placeholder="Width"
                        onChange={value => {
                          updateLayer({ prop: "width", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.width.toString()}
                        type="number"
                      />

                      <InputBox
                        autoFocus={false}
                        input_ref={this._height}
                        name="height"
                        returnKeyType="done"
                        placeholder="Height"
                        onChange={value => {
                          updateLayer({ prop: "height", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.height.toString()}
                        type="number"
                      />
                    </>
                  ) : null}
                </Wrapper>
                <Wrapper
                  style={{
                    backgroundColor: show_padding
                      ? colors.active_color_muted
                      : null,
                    borderRadius: show_padding ? 5 : 0,
                    margin: show_padding ? 5 : 0
                  }}
                >
                  <SelectItem
                    select_type={"none"}
                    onPress={() =>
                      this.setState({
                        show_border: false,
                        show_padding: !show_padding,
                        show_position: false,
                        show_size: false,
                        show_typography: false,
                        show_background: false,
                        show_quick_position: false
                      })
                    }
                    selected={show_padding}
                    icon={
                      show_padding ? "keyboard-arrow-up" : "keyboard-arrow-down"
                    }
                  >
                    Padding
                  </SelectItem>

                  {show_padding ? (
                    <>
                      <InputBox
                        autoFocus={false}
                        input_ref={this._padding_top}
                        name="padding_top"
                        returnKeyType="done"
                        placeholder="Padding Top"
                        onChange={value => {
                          updateLayer({ prop: "padding_top", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.padding_top.toString()}
                        type="number"
                      />
                      <InputBox
                        autoFocus={false}
                        input_ref={this._padding_left}
                        name="padding_left"
                        returnKeyType="done"
                        placeholder="Padding Left"
                        onChange={value => {
                          updateLayer({ prop: "padding_left", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.padding_left.toString()}
                        type="number"
                      />
                      <InputBox
                        autoFocus={false}
                        input_ref={this._padding_bottom}
                        name="padding_bottom"
                        returnKeyType="done"
                        placeholder="Padding Bottom"
                        onChange={value => {
                          updateLayer({ prop: "padding_bottom", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.padding_bottom.toString()}
                        type="number"
                      />
                      <InputBox
                        autoFocus={false}
                        input_ref={this._padding_right}
                        name="padding_right"
                        returnKeyType="done"
                        placeholder="Padding Right"
                        onChange={value => {
                          updateLayer({ prop: "padding_right", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.padding_right.toString()}
                        type="number"
                      />
                    </>
                  ) : null}
                </Wrapper>

                <Wrapper
                  style={{
                    backgroundColor: show_position
                      ? colors.active_color_muted
                      : null,
                    borderRadius: show_position ? 5 : 0,
                    margin: show_position ? 5 : 0
                  }}
                >
                  <SelectItem
                    select_type={"none"}
                    onPress={() =>
                      this.setState({
                        show_border: false,
                        show_padding: false,
                        show_position: !show_position,
                        show_size: false,
                        show_typography: false,
                        show_background: false,
                        show_quick_position: false
                      })
                    }
                    selected={show_position}
                    icon={
                      show_position
                        ? "keyboard-arrow-up"
                        : "keyboard-arrow-down"
                    }
                  >
                    Position
                  </SelectItem>

                  {show_position ? (
                    <>
                      <InputBox
                        autoFocus={false}
                        input_ref={this._x}
                        name="x"
                        returnKeyType="done"
                        placeholder="X"
                        onChange={value => {
                          updateLayer({ prop: "x", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.x.toString()}
                        type="number"
                      />

                      <InputBox
                        autoFocus={false}
                        input_ref={this._y}
                        name="y"
                        returnKeyType="done"
                        placeholder="Y"
                        onChange={value => {
                          updateLayer({ prop: "y", value: value });
                        }}
                        onSubmitEditing={() => {}}
                        blurOnSubmit={true}
                        value={layer?.y.toString()}
                        type="number"
                      />
                    </>
                  ) : null}
                </Wrapper>

                <Wrapper
                  style={{
                    backgroundColor: show_quick_position
                      ? colors.active_color_muted
                      : null,
                    borderRadius: show_quick_position ? 5 : 0,
                    margin: show_quick_position ? 5 : 0
                  }}
                >
                  <SelectItem
                    select_type={"none"}
                    onPress={() =>
                      this.setState({
                        show_border: false,
                        show_padding: false,
                        show_position: false,
                        show_size: false,
                        show_typography: false,
                        show_background: false,
                        show_quick_position: !show_quick_position
                      })
                    }
                    selected={show_quick_position}
                    icon={
                      show_quick_position
                        ? "keyboard-arrow-up"
                        : "keyboard-arrow-down"
                    }
                  >
                    Quick Positions
                  </SelectItem>

                  {show_quick_position ? (
                    <QuickPositions
                      colors={colors}
                      layer={layer}
                      updateLayer={updateLayer}
                      card_width={card_width}
                      card_height={card_height}
                    />
                  ) : null}
                </Wrapper>
              </>
            </>
          ) : null}
        </KeyboardView>
        {template_type !== "handwritten" ? (
          <BottomNavBar>
            {!checkIfUserHasMetadata("paid_plan") &&
            !checkIfUserHasMetadata("mail_designer") ? (
              <InlineButton
                button_type={"full"}
                onPress={() => {
                  if (
                    !checkIfUserHasMetadata("paid_plan") &&
                    !checkIfUserHasMetadata("mail_designer")
                  ) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      locked: true,
                      data: {
                        title: "Create Your Own Designs With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without Pro.",
                        no_free: true,
                        event_slug: "in_app_mail_designer"
                      },
                      locked: true
                    });
                  } else {
                    this.props.toggleAdvancedEditingMode(
                      !this.props.advanced_editing_mode
                    );
                  }
                }}
                icon={
                  !checkIfUserHasMetadata("paid_plan") &&
                  !checkIfUserHasMetadata("mail_designer")
                    ? "lock"
                    : advanced_editing_mode
                    ? "toggle-on"
                    : "toggle-off"
                }
              >
                Advanced Editing Mode
              </InlineButton>
            ) : (
              <UpsellButton
                meta_slug="mail_designer"
                onPress={() => {
                  this.props.toggleAdvancedEditingMode(
                    !this.props.advanced_editing_mode
                  );
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      button_type={"full"}
                      noPress={true}
                      pressedIn={hovering}
                      icon={"lock"}
                    >
                      Advanced Editing Mode
                    </InlineButton>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      button_type={"full"}
                      noPress={true}
                      pressedIn={hovering}
                      icon={advanced_editing_mode ? "toggle-on" : "toggle-off"}
                      icon_color={
                        advanced_editing_mode ? colors.success_color : null
                      }
                    >
                      Advanced Editing Mode
                    </InlineButton>
                  );
                }}
              />
            )}
          </BottomNavBar>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { isMobile, device, platform, isIphoneX } = native;
  const { colors } = settings;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    isMobile,
    device,
    platform,
    isIphoneX,
    colors,
    source_of_truth
  };
};

export default connect(mapStateToProps, { uploadFile, pushSidePanel })(
  SelectedLayer
);
